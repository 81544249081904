import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppSessionService } from './app-session.service';
import { NavigationService } from './navigation.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private app: AppSessionService,
    private nav: NavigationService
  ) { }

  canActivate(): boolean {
    if (!this.app.user._id) {
      this.nav.setRoot('auth')
      return false;
    }
    return true;
  }
}
