import { Injectable } from '@angular/core';
import { FormService } from './form.service';
import { AppSessionService } from '../app-session.service';
import { ApiService } from '../api.service';
import { StorageService } from '../storage.service';
import { PdfService } from '../pdf.service';
import { PageService } from '../listing/page.service';
import * as uuid from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(public page: PageService, public pdf: PdfService, public storage: StorageService, public api: ApiService, public app: AppSessionService, public form: FormService) { }

  save = async () => {

    return Promise.resolve({ error: null, response: true })
  }

  load = (documents) => {
    this.form.subscriptions()

    if (documents) { this.form.detail.patchValue(documents, { emitEvent: false }) }
    if (documents.s32) { this.form.s32.patchValue(documents.s32, { emitEvent: false }) }
    if (documents.contract) { this.form.contract.patchValue(documents.contract, { emitEvent: false }) }
  }

  remove = async (listingId) => {
    return new Promise(async resolve => {
      await this.api.post('contract/cancel', { _id: listingId })

      // Reset the front end
      this.form.detail.reset({ emitEvent: false })
      this.form.s32.reset({ emitEvent: false })
      this.form.contract.reset({ emitEvent: false })

      // Reset UI
      this.page.document.state = 'blank'
      this.form.detail.get('status').patchValue('blank')
      resolve({ error: null, response: true })
    })
  }

  document = {
    email: async (listingId, vendorId) => {
      await this.api.get('listing/signature/remoteSign', { listingId: listingId, type: 'vendor', document: 's32', id: vendorId })
      return Promise.resolve({ error: null, response: true })
    },
    emailAny: async (listingId, agent, name, email) => {
      return new Promise(async resolve => {
        let res = await this.api.post('listing/s32/send/other', { id: listingId, sender: agent, name, email })
        resolve({ error: null, response: true })
      })
    },
    emailSoldContract: async (listingId, offerId, agent, name, email) => {
      return new Promise(async resolve => {
        let res = await this.api.post('listing/contract/send/other/sold', { listingId, offerId, sender: agent, name, email: email })
        resolve({ error: null, response: true })
      })
    },
    newState: async (listingId, status) => {
      return new Promise(async resolve => {
        await this.api.post('s32/state', { _id: listingId, status: status })
        resolve({ error: null, response: true })
      })
    },
    newContractState: async (listingId, status) => {
      return new Promise(async resolve => {
        await this.api.post('contract/state', { _id: listingId, status: status })
        resolve({ error: null, response: true })
      })
    },
    pushPdfName: async (listingId, pdfname) => {
      return new Promise(async resolve => {
        await this.api.post('contract/pdfname/push', { listingId: listingId, pdfName: pdfname })
        resolve()
      })
    },
    popPdfName: async (listingId) => {
      return new Promise(async resolve => {
        await this.api.post('contract/pdfname/pop', { listingId: listingId })
        resolve()
      })
    },

    generate: {
      blank: async (listingId, s32File) => {
        // this.form.contract.get('uploaded').patchValue(new Date())
        // this.form.contract.get('uploadedBy').patchValue(this.app.user.name)
        console.log('Generate Blank')
        let res: any = await this.api.get('contract/blank', { id: listingId })
        return res.fileId
        // Assign ID after Mongo Save
        // this.form.contract.get('_id').patchValue(res.fileId)
        // this.form.contract.get('pdfUrl').patchValue(`${res.fileId}.pdf`)
      },
      detailed: async (listingId, offerId, s32FileName?) => {
        let res: any = await this.api.get('contract/build', { id: listingId, offerId: offerId, s32PdfId: s32FileName })
        console.log(res)
        return res.fileId
      }
    },


    uploadMongo: (listingId, detailForm) => {
      return new Promise(async resolve => {
        console.log('Mongo Upload Main Detail Save')
        console.log(detailForm)
        await this.api.post('contract/final', { _id: listingId, final: detailForm })
        this.form.detail.get('uploaded').patchValue(true)
        resolve()
      })
    },
    /*
        Upload a document to S3 and Mongo
        PARAMS: 
          event - Upload event
          type - 'contract' || 's32'
      */
    upload: async (listingId, event, type) => {
      return new Promise(async resolve => {
        Array.from(event.target.files).forEach((file: any) => {
          let reader = new FileReader()
          if (file.name.indexOf('.pdf') == -1) { }
          else {

            if (type == 's32') {
              // Files Loaded Event Listener
              reader.onloadend = async () => {
                let s32type = this.form.s32.get('type').value
                let contractType = this.form.contract.get('type').value
                let uploaded = this.form.detail.get('uploaded').value

                this.form.s32.reset({ emitEvent: false })
                this.form.detail.reset({ emitEvent: false })

                this.form.s32.get('type').patchValue(s32type, { emitEvent: false })
                this.form.contract.get('type').patchValue(contractType, { emitEvent: false })
                this.form.detail.get('uploaded').patchValue(uploaded)
                if (uploaded) {
                  this.form.detail.get('reuploaded').patchValue(true)
                } else {
                  this.form.detail.get('reuploaded').patchValue(false)
                }

                // Set For Data
                // this.form.s32.get('pdfName').patchValue(uuid.v4())
                // this.form.s32.get('pdfUrl').patchValue(`${this.form.s32.controls.pdfName.value}.pdf`)
                this.form.s32.get('uploaded').patchValue(new Date())
                this.form.s32.get('uploadedBy').patchValue(this.app.user.name)
                // this.form.s32.get('status').patchValue('builder')


                // Assign ID after Mongo Save
                let _id = uuid.v4()
                let pdfUrl = `${_id}.pdf`
                this.form.s32.get('_id').patchValue(_id)
                this.form.s32.get('pdfUrl').patchValue(pdfUrl)

                // Save to S3
                await this.storage.save({ name: this.form.s32.get('pdfUrl').value, data: reader.result, type: 'application/pdf' })

                // Save to Mongo
                // let res: any = await this.api.post('s32/upload', { id: listingId, data: this.form.s32.value })

                // Declare whether merged
                // if (this.form.s32.get('type').value == 'singleDocument' && this.form.contract.get('type').value == 'singleDocument') {
                //   this.form.detail.get('combined').patchValue(true)
                // }
                resolve()

                console.log(this.form.detail.value)
                console.log(this.form.s32.value)
                console.log(this.form.contract.value)
              }
            } else if (type == 'contract') {
              // Files Loaded Event Listener
              reader.onloadend = async () => {
                let type = this.form.contract.get('type').value
                let uploaded = this.form.detail.get('uploaded').value

                this.form.contract.reset({ emitEvent: false })
                this.form.detail.reset({ emitEvent: false })

                this.form.contract.get('type').patchValue(type, { emitEvent: false })

                this.form.detail.get('uploaded').patchValue(uploaded, { emitEvent: false })
                if (uploaded) {
                  this.form.detail.get('reuploaded').patchValue(true, { emitEvent: false })
                } else {
                  this.form.detail.get('reuploaded').patchValue(false, { emitEvent: false })
                }

                // Set Form Data
                this.form.contract.get('uploaded').patchValue(new Date(), { emitEvent: false })
                this.form.contract.get('uploadedBy').patchValue(this.app.user.name)
                // this.form.contract.get('status').patchValue('builder')

                let _id = uuid.v4()
                let pdfUrl = `${_id}.pdf`

                // Save to Mongo
                // let { _id, pdfUrl }: any = await this.api.post('contract/upload', { id: listingId, data: this.form.contract.value }) // TODO : MOVE THIS TO SUBMIT

                // Assign ID after Mongo Save
                this.form.contract.get('_id').patchValue(_id)
                this.form.contract.get('pdfUrl').patchValue(pdfUrl)

                // Save to S3
                await this.storage.save({ name: this.form.contract.get('pdfUrl').value, data: reader.result, type: 'application/pdf' })

                // Declare whether merged
                // if (this.form.s32.get('type').value == 'singleDocument' && this.form.contract.get('type').value == 'singleDocument') {
                //   this.form.detail.get('combined').patchValue(true)
                // }
                resolve()

                console.log(this.form.detail.value)
                console.log(this.form.s32.value)
                console.log(this.form.contract.value)
              }
            } else if (type == 'both') {
              // Files Loaded Event Listener
              reader.onloadend = async () => {

                // Reset Contract and S32 Variables
                let typeCon = this.form.contract.get('type').value
                let typeS32 = this.form.s32.get('type').value
                let uploaded = this.form.detail.get('uploaded').value
                this.form.detail.reset({ emitEvent: false })
                this.form.contract.reset({ emitEvent: false })
                this.form.s32.reset({ emitEvent: false })
                this.form.detail.get('uploaded').patchValue(uploaded)
                if (uploaded) {
                  this.form.detail.get('reuploaded').patchValue(true, { emitEvent: false })
                } else {
                  this.form.detail.get('reuploaded').patchValue(false, { emitEvent: false })
                }
                this.form.s32.get('type').patchValue(typeS32, { emitEvent: false })
                this.form.contract.get('type').patchValue(typeCon, { emitEvent: false })

                // CONTRACT
                this.form.contract.get('uploaded').patchValue(new Date())
                this.form.contract.get('uploadedBy').patchValue(this.app.user.name)
                this.form.detail.get('locked').value ? this.form.s32.get('status').patchValue('signed') : ''

                // S32
                this.form.s32.get('uploaded').patchValue(new Date())
                this.form.s32.get('uploadedBy').patchValue(this.app.user.name)
                this.form.detail.get('locked').value ? this.form.s32.get('status').patchValue('signed') : this.form.s32.get('status').patchValue('builder')

                // DETAILS FORM
                this.form.detail.get('combined').patchValue(true)
                this.form.detail.get('locked').value ? this.form.detail.get('status').patchValue('signed') : this.form.detail.get('status').patchValue('builder')

                // Save to Mongo
                // let final: any = await this.api.post('contract/final', { _id: listingId, s32: this.form.s32.value, contract: this.form.contract.value, final: this.form.detail.value })

                let pdfId = uuid.v4()
                // Update Service
                let pdfNames = [pdfId]
                this.form.detail.get('PDFNames').patchValue(pdfNames)

                // Save to S3
                await this.storage.save({ name: `${pdfId}.pdf`, data: reader.result, type: 'application/pdf' })

                resolve()
                console.group('Forms')
                console.log(this.form.detail.value)
                console.log(this.form.s32.value)
                console.log(this.form.contract.value)
                console.groupEnd()
              }
            }

            // Read the document as the buffer
            reader.readAsArrayBuffer(file)
          }
        })
      })
    },
    combine: async (listingId, status, locked) => {
      return new Promise(async resolve => {
        let params = {
          _id: listingId,
          status: status,
          locked: locked,
        }
        let res: any = await this.api.post('contract/combine', params)
        resolve({ error: null, response: res })
      })
    },
  }

  s32 = {
    email: {
      complete: (listingId, pdfName) : Promise<{ error:any, response:any }> => {
        return new Promise(async resolve => {
          await this.api.post('contract/signatures/complete/email', { listingId, pdfName })
          resolve({ error: null, response: true })
        })
      }
    },
    upload: (listingId, s32Form) => {
      return new Promise(async resolve => {
        console.log('Mongo Upload S32 Save')
        console.log(s32Form)
        await this.api.post('s32/upload', { id: listingId, data: s32Form })
        resolve()
      })
    },
    annotation: {
      export: async (listingId, annotationLog, signatureCount) => {
        return new Promise(async resolve => {
          let response = await this.api.post(`s32/annotation/export`, { listingId: listingId, annotationLog: annotationLog, signatureCount })
          resolve({ error: null, response: response })
        })
      },
      import: async (listingId, pdfId) => {
        return new Promise(async resolve => {
          let response: any = await this.api.post(`s32/annotation/import`, { listingId: listingId, pdfId: pdfId })
          resolve({ error: null, response: response ? response[response.length - 1] : null })
        })
      }
    }
  }

  contract = {
    upload: (listingId, contractForm) => {
      return new Promise(async resolve => {
        console.log('Mongo Upload Contract Save')
        console.log(contractForm)
        await this.api.post('contract/upload', { id: listingId, data: contractForm })
        resolve()
      })
    },
    annotation: {
      export: (listingId, annotationLog) => {
        return new Promise(async resolve => {
          let response = await this.api.post(`contract/annotation/export/2`, { listingId: listingId, annotationLog: annotationLog })
          resolve({ error: null, response: response })
        })
      },
      import: async (listingId, pdfId) => {
        return new Promise(async resolve => {
          let response: any = await this.api.post(`contract/annotation/import/2`, { listingId: listingId, pdfId: pdfId })
          resolve({ error: null, response: response ? response[response.length - 1] : null })
        })
      },
    }
  }

  signature = {
    count: async (listingId, entityId, PDFNames): Promise<{ error: any, response: any }> => {
      return new Promise(async resolve => {
        let annots = await this.api.get(`s32/signature/annots`, { id: listingId })
        let count = 0

        if (annots) {
          let annotList = annots[PDFNames[PDFNames.length - 1]]
          if (entityId) { 
            annotList = annotList.filter(a => a.userId === entityId)
          }
          if (annotList) { 
            count = annotList.length
          }
        }
        resolve({ error: null, response: count })
      })
    },
    total: async (instance): Promise<any> => {
      return new Promise(async resolve => {
        // Get List
        let Annotations = instance.Annotations
        let annotationList = await instance.annotManager.getAnnotationsList()
        let count = 0
        // Find how many widgets are for signatures
        for (let annot of annotationList) {
          if (annot instanceof Annotations.SignatureWidgetAnnotation && !annot.Hidden) {
            count++
          }
        }
        resolve({ error: null, response: count })
      })
    },
    save: async (instance, listingId, PDFNames, state, annotation, useExportAnnotCommand = true) : Promise<{ error:any, response:any }> => {
      return new Promise(async resolve => {
        let version = PDFNames[PDFNames.length - 1]
        let res = await this.pdf.s32.annotations.save(instance, listingId, version, state, annotation, useExportAnnotCommand)
        resolve({ error: null, response: res })
      })
    },
  }


}
