import 'rxjs/Rx'
import { Injectable } from '@angular/core'
import * as numeral from 'numeral'
import { HelperService } from '../helper.service'

@Injectable()
export class CommissionTextService {

  constructor(public helper: HelperService) {

  }

  build = (commision) => {
    let commissionText
    let gstText = 'inclusive of GST'
    if (commision.performance == 'none') {
      switch (commision.type) {
        case 'fixed':
          commissionText = ` Fixed commision ${gstText} of ${numeral(commision.fixed).format('$0,0')}`
          break
        case 'percentage':
          commissionText = `An amount of ${commision.percentage}% of the sale price ${gstText}`
          break
      }
    }
    else {
      const commisionType = commision.type + '-' + commision.performance + '-' + commision.performanceType
      const fixed = this.helper.moneyRounded(commision.fixed) + ' ' + gstText
      const performanceFixed = this.helper.moneyRounded(commision.performanceFixed) + ' ' + gstText
      const percentage = this.helper.number(commision.percentage) + '% ' + gstText
      const performancePercentage = this.helper.number(commision.performancePercentage) + '% ' + gstText
      switch (commisionType) {

        case 'fixed-replace-fixed':
          commissionText = `An amount of ${fixed} fixed commission if the sale price is less than the trigger amount otherwise ${performanceFixed} fixed commission.`
          break
        case 'fixed-replace-percentage':
          commissionText = `An amount of ${fixed} fixed commission if the sale price is less than the trigger amount otherwise ${performancePercentage} of the sale price.`
          break
        case 'percentage-replace-fixed':
          commissionText = `An amount of ${percentage} of the sale price if the sale price is less than the trigger amount otherwise ${performanceFixed} fixed commission.`
          break
        case 'percentage-replace-percentage':
          commissionText = `An amount of ${percentage} of the sale price if the sale price is less than the trigger amount otherwise ${performancePercentage} of the sale price.`
          break

        case 'fixed-excess-fixed':
          commissionText = `An amount of ${fixed} fixed commission PLUS, if the sale price is equal to or greater than the trigger amount, ${performanceFixed} fixed commission.`
          break
        case 'fixed-excess-percentage':
          commissionText = `An amount of ${fixed} fixed commission PLUS, if the sale price is equal to or greater than the trigger amount, ${performancePercentage} of the difference between the sale price  and the trigger amount.`
          break
        case 'percentage-excess-fixed':
          commissionText = `An amount of ${percentage} of the lower amount of either the sale price or the trigger amount PLUS, if the sale price is equal to or greater than the trigger amount, ${performanceFixed} fixed commission.`
          break
        case 'percentage-excess-percentage':
          commissionText = `An amount of ${percentage} of the lower amount of either the sale price or the trigger amount PLUS, if the sale price is equal to or greater than the trigger amount, ${performancePercentage} of the difference between the sale price and the trigger amount.`
          break

        case 'fixed-full-fixed':
          commissionText = `An amount of ${fixed} fixed commission PLUS, if the sale price is equal to or greater than the trigger amount, ${performanceFixed} fixed commision.`
          break
        case 'fixed-full-percentage':
          commissionText = `An amount ${fixed} fixed commission PLUS, if the sale price is equal to or greater than the trigger amount, ${performancePercentage} of the sale price.`
          break
        case 'percentage-full-fixed':
          commissionText = `An amount of ${percentage} of the sale price PLUS, if the sale price is equal to or greater than the trigger amount, ${performanceFixed} fixed commision.`
          break
        case 'percentage-full-percentage':
          commissionText = `An amount of ${percentage} of the sale price PLUS, if the sale price is equal to or greater than the trigger amount, ${performancePercentage} of the sale price.`
          break

        case 'fixed-doubledip-fixed':
          commissionText = `An amount of ${fixed} fixed commission PLUS, if the sale price is equal to or greater than the trigger amount, ${performanceFixed} fixed commission.`
          break
        case 'fixed-doubledip-percentage':
          commissionText = `An amount of ${fixed} fixed commission PLUS, if the sale price is equal to or greater than the trigger amount, ${performancePercentage} of the difference between the sale price and the trigger amount.`
          break
        case 'percentage-doubledip-fixed':
          commissionText = `An amount of ${percentage} of the sale price PLUS, if the sale price is equal to or greater than the trigger amount, ${performanceFixed} fixed commission.`
          break
        case 'percentage-doubledip-percentage':
          commissionText = `An amount of ${percentage} of the sale price PLUS, if the sale price is equal to or greater than the trigger amount, ${performancePercentage} of the difference between the sale price and the trigger amount.`
          break
      }
    }
    return commissionText
  }

}
