import { Injectable } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public detail: FormGroup
  public contract: FormGroup
  public s32: FormGroup

  constructor(public builder: FormBuilder) {
    this.detail = this.builder.group({
      status: [''],
      combined: [false],
      PDFNames: [''],
      annotation: new FormControl([]),
      versions: new FormControl({}),
      uploaded: new FormControl(false),
      reuploaded: new FormControl(false),
      locked: new FormControl(false),
      emailSent: new FormControl(false),
      masterComplete: new FormControl(false),
    })

    this.contract = this.builder.group({
      _id: new FormControl(''),
      status: [''],
      type: ['s32andContract'],
      uploaded: new FormControl(''),
      uploadedBy: new FormControl(''),
      pdfUrl: new FormControl(''),
      apContract: new FormControl(''),
    })

    this.s32 = this.builder.group({
      _id: new FormControl(''),
      status: [''],
      type: ['s32andContract'],
      uploaded: new FormControl(''),
      uploadedBy: new FormControl(''),
      pdfUrl: new FormControl(''),
    })
  }

  cleanup = () => {
    this.detail.reset({ emitEvent: false })
    this.contract.reset({ emitEvent: false })
    this.s32.reset({ emitEvent: false })
  }

  subscriptions = () => {
    this.contract.get('type').valueChanges.subscribe(value => {
      console.log('CONTRACT TYPE CHANGE')
      switch (value) {
        case 's32andContract':
          this.s32.get('type').patchValue(value, { emitEvent: false })
          break
        case 'contract':
        case 'none':
          this.s32.get('type').patchValue('s32', { emitEvent: false })
          break
      }
    })

    this.s32.get('type').valueChanges.subscribe(value => {
      console.log('S32 TYPE CHANGE!!')
      switch (value) {
        case 's32andContract':
          this.contract.get('type').patchValue(value, { emitEvent: false })
          break
        case 's32':
          this.contract.get('type').patchValue('contract', { emitEvent: false })
          break
      }
    })
  }
}
