import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

  /*
    Orders Items, Defaults to ascending order.

    items = Array items to loop through
    propertyName = Property name in item
    sortOrder = 'asc' || 'desc
  */
  transform(items: any[], propertyName: string, sortOrder?: string): any[] {
    let sortMethod = this.ascending
    if (!items) return;
    if (sortOrder === 'desc') sortMethod = this.descending
    return items.sort((a, b) => sortMethod(a, b, propertyName))
  }

  ascending = (a, b, propertyName) => { return a[propertyName] - b[propertyName] }
  descending = (a, b, propertyName) => { return b[propertyName] - a[propertyName] }

}
