import 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { Pro } from '@ionic/pro'
import { ICurrentConfig, ISnapshotInfo } from 'cordova-plugin-ionic/dist/IonicCordova';

@Injectable()
export class UpdateService {
  public totalProgress: number

  constructor() {
    this.init()
  }

  init(): void {
    this.totalProgress = 0
  }

  async getCurrentConfiguration(): Promise<ICurrentConfig> {
    return await Pro.deploy.getConfiguration()
  }

  hasUpdate(): Promise<boolean> {
    return new Promise(async resolve => {
      let response = await Pro.deploy.checkForUpdate()
      if (response && response.available) {
        return resolve(true)
      } else {
        return resolve(false)
      }
    })
  }

  setChannel(channelName): Promise<void> {
    return new Promise(async resolve => {
      await Pro.deploy.configure({ channel: channelName })
      resolve()
    })
  }

  async manualUpdate(): Promise<string> {
    return new Promise(async resolve => {
      const update = await Pro.deploy.checkForUpdate()
      if (update && update.available) {
        let downloadProgress = 0
        let installProgress = 0
        await Pro.deploy.downloadUpdate((progress) => {
          downloadProgress = progress;
          this.totalProgress = progress / 2
          console.log(`Download: ${this.totalProgress}%`);
        })
        await Pro.deploy.extractUpdate((progress) => {
          installProgress = progress;
          this.totalProgress = (downloadProgress / 2) + (progress / 2)
          console.log(`Extract: ${this.totalProgress}%`);
        })
        await Pro.deploy.reloadApp();
        return resolve('')
      } else {
        return resolve('No Update Available')
      }
    })
  }

  async autoUpdate(): Promise<ISnapshotInfo> {
    return await Pro.deploy.sync({ updateMethod: 'auto' })
  }

  async backgroundUpdate(): Promise<ISnapshotInfo> {
    return await Pro.deploy.sync({ updateMethod: 'background' })
  }

}
