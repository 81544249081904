import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ComingSoonService {

  constructor(public toast: ToastController) { }

  present = async () => {
    (await this.toast.create({ message: 'Feature coming soon!', duration: 2000 })).present();
  }
}
