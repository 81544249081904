import 'rxjs/Rx'
import { Injectable, NgZone } from '@angular/core'
import { Observable, BehaviorSubject } from 'rxjs'

@Injectable()
export class PermissionService {
  private VIEW = 'View All'
  private EDIT = 'View & Edit'
  private VIEW_PROPERTY = 'View Property Only'
  private _user: any
  private _listing: any
  private _offer: any
  manageUsers = false
  manageReports = false
  menu = { listings: false, agencies: false, switchAccount: false }
  administrator = false
  god = false
  agency = {
    updateDetails: false,
    updateCommissionSharing: false,
  }
  listing = {
    create: false,
    signAuthorities: false,
    selected: { viewMenu: false, view: false, edit: false, editField: false, editBaseField: false, viewProperty: false, signAuthorities: false },
    prelisting: { view: false, edit: false, viewProperty: false },
    active: { view: false, edit: false, viewProperty: false },
    inactive: { view: false, edit: false, viewProperty: false }
  }
  offer = {
    selected: { edit: false }
  }
  private listingBehaviour = new BehaviorSubject(this.listing)
  $listing = this.listingBehaviour.asObservable()
  private offerBehaviour = new BehaviorSubject(this.offer)
  $offer = this.offerBehaviour.asObservable()

  constructor(
    private zone: NgZone,
  ) { }

  initialise = {
    user: (user: any) => {
      this._user = user
      let p = user.permissions
      console.log(p)

      this.god = p.god ? true : false
      this.administrator = p.agency.admin

      this.menu.listings = p.agency.admin || p.listing.viewAllPreListing || p.listing.viewAllListing || p.listing.viewAllInactiveListing
      this.menu.agencies = p.agency.admin || p.agency.manageUsers || p.agency.updateCommShare || p.agency.updateDetail

      this.manageReports = p.agency.manageReports // Does not exist yet
      this.manageUsers = p.agency.manageUsers

      this.agency.updateCommissionSharing = p.agency.updateCommShare
      this.agency.updateDetails = p.agency.updateDetail

      this.listing.create = p.listing.create
      this.listing.signAuthorities = p.listing.signingAuth

      this.listing.active.view = p.listing.viewAllListing && p.listing.viewAllListingType == this.VIEW
      this.listing.active.edit = p.listing.viewAllListing && p.listing.viewAllListingType == this.EDIT
      this.listing.active.viewProperty = p.listing.viewAllListing && p.listing.viewAllListingType == this.VIEW_PROPERTY

      this.listing.prelisting.view = p.listing.viewAllPreListing && p.listing.viewAllPreListingType == this.VIEW
      this.listing.prelisting.edit = p.listing.viewAllPreListing && p.listing.viewAllPreListingType == this.EDIT
      this.listing.prelisting.viewProperty = p.listing.viewAllPreListing && p.listing.viewAllPreListingType == this.VIEW_PROPERTY

      this.listing.inactive.view = p.listing.viewAllInactiveListing && p.listing.viewAllInactiveListingType == this.VIEW
      this.listing.inactive.edit = p.listing.viewAllInactiveListing && p.listing.viewAllInactiveListingType == this.EDIT
      this.listing.inactive.viewProperty = p.listing.viewAllInactiveListing && p.listing.viewAllInactiveListingType == this.VIEW_PROPERTY

      console.group('Permission')
      console.log(this.listing)
      console.log(this.agency)
      console.log(`God: ${this.god}`)
      console.groupEnd()
    },

    listing: (listing: any) => {
      this._listing = listing
      this.listing.selected.view = this.listingPermission(listing, 'view')
      this.listing.selected.viewProperty = this.listingPermission(listing, 'viewProperty')
      this.listing.selected.signAuthorities = listing.listing.agentId == this._user._id || this.administrator || this.listing.signAuthorities
      this.listing.selected.edit = this.listingPermission(listing, 'edit')
      this.listing.selected.viewMenu = this.listing.selected.view || this.listing.selected.editField
      let authorityPreparedHardLock = listing.authority && listing.authority.prepared != null
      let authorityPrepared = authorityPreparedHardLock && listing.authority.status != 'amending'
      // property tab only excluded amending clause above??? reason???
      this.listing.selected.editBaseField = this.listing.selected.edit && !authorityPreparedHardLock
      this.listing.selected.editField = this.listing.selected.edit && !authorityPrepared
      this.listingBehaviour.next(this.listing)

      console.group('Listing Permissions')
      console.log(this.listing)
      console.log(listing)
      console.groupEnd()
    },

    offer: (offer: any) => {
      this._offer = offer
      console.log('Offer Permissions/ Field Lock Update')
      console.log(offer)
      let isOfferLocked = offer.status != 'Prospective'
      let listingEdit = this.listingPermission(this._listing, 'edit')
      this.zone.run(() => {
        this.offer.selected.edit = listingEdit && !isOfferLocked
        this.offerBehaviour.next(this.offer)
      })
    }
  }

  private listingPermission = (listing: any, permissionType: string) => {
    let permitted = false
    // owner
    if (listing.listing.agentId == this._user._id || this.administrator)
      permitted = true
    else {
      // given permission in their user settings 
      let listingType = 'active'
      if (!listing.authority || !listing.authority.locked) listingType = 'prelisting'
      else if (listing.listing.status == 'Withdrawn') listingType = 'inactive'
      permitted = this.listing[listingType][permissionType] //prelisting.view
      // given permission in another users my settings
      if (this._user.allowedAccessTo && this._user.allowedAccessTo.indexOf(listing.listing.agentId) != -1)
        permitted = true
      // given permission in another users listing 
      if (listing.listing.allowedUsers && listing.listing.allowedUsers.indexOf(this._user._id) != -1)
        permitted = true
    }
    console.log(`listingPermission.${permissionType}=${permitted}`)
    return permitted
  }

}
