import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HelperService } from '../helper.service';
import { AppSessionService } from '../app-session.service';
import { ConveyancerService } from '../conveyancer/conveyancer.service'
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  _id: FormGroup
  detail: FormGroup
  property: FormGroup
  listingFee: FormGroup

  constructor(private api: ApiService, private conveyancer: ConveyancerService, private app: AppSessionService, private builder: FormBuilder, private helper: HelperService) {

    this._id = this.builder.group({
      _id: [''],
    })

    this.detail = this.builder.group({
      status: [''],
      listingAgent: [''],
      allowedUsers: [[]],
      signAmend: [false],
      acceptContractTerms: [false],
      accpetS32Terms: [false],
      acknowledgeContractViewing: [false],
      acknowledgeS32Viewing: [false],
      agencyId: [''],
      agency: [''],
      agentId: [''],
      vendorConveyancer: conveyancer.build.detail(),
      purchaserConveyancer: conveyancer.build.detail(),
      representingVendor: [''],
      vendors: this.builder.array([]),
      agents: this.builder.array([]),
      attachments: this.builder.array([])
    })

    this.property = this.builder.group({
      address: ['', Validators.compose([Validators.required])],
      street: ['', Validators.compose([Validators.required])],
      suburb: ['', Validators.compose([Validators.required])],
      state: ['VIC', Validators.compose([Validators.required])],
      postcode: ['', Validators.compose([Validators.required])],
      bedrooms: [''],
      bathrooms: [''],
      carspots: [''],
      goodsSold: [''],
      features: [''],
      reaLink: [''],
      corelogicLink: [''],
      goodsSoldOption: [''],
      propertyType: [''],
      propertyVacancy: [''],
      propertyNotes: [''],
      floorplan: this.builder.array([]),
      images: this.builder.array([]),
      thumbnailIndex: [0],
      titles: this.builder.array([])
    })
    this.subscriptions()
  }

  build = {
    title: () => {
      return this.builder.group({
        lot: [''],
        plan: [''],
        volume: [''],
        folio: ['']
      })
    }
  }

  cleanup = () => {
    this._id.reset()
    this.detail.reset()
    this.property.reset()
    let formArray = this.property.controls.images as FormArray
    formArray.clear()

    formArray = this.property.controls.titles as FormArray
    formArray.clear()

    formArray = this.detail.controls.agents as FormArray
    formArray.clear()

    formArray = this.detail.controls.vendors as FormArray
    formArray.clear()

    formArray = this.detail.controls.attachments as FormArray
    formArray.clear()

    formArray = this.property.controls.floorplan as FormArray
    formArray.clear()

    this._id.clearValidators()
    this.detail.clearValidators()
    this.property.clearValidators()
  }

  addressSubs = async () => {
    this.property.get('street').valueChanges.subscribe((street: string) => {
      this.property.get('address').patchValue(`${street}, ${this.property.get('suburb').value}`)
    })

    this.property.get('suburb').valueChanges.subscribe((suburb: string) => {
      this.property.get('address').patchValue(`${this.property.get('street').value}, ${suburb}`)
    })
  }

  subscriptions = () => {
    this.property.get('address').valueChanges.subscribe((address: string) => {
      this.property.get('address').patchValue(this.helper.titleCase(address), { emitEvent: false })
    })
    this.property.get('suburb').valueChanges.subscribe((suburb: string) => {
      this.property.get('suburb').patchValue(this.helper.titleCase(suburb), { emitEvent: false })
    })
    // this.property.get('goodsSoldOption').valueChanges.subscribe((goodsSoldOption: string) => {
    //   if (goodsSoldOption != 'Other') {
    //     this.property.get('goodsSold').patchValue(goodsSoldOption)
    //   } else {
    //     this.property.get('goodsSold').patchValue(this.helper.titleCase(this.property.get('goodsSold').value), { emitEvent: false })
    //   }
    // })
    this.property.get('propertyNotes').valueChanges.subscribe((propertyNotes: string) => {
      this.property.get('propertyNotes').patchValue(this.helper.titleCase(propertyNotes), { emitEvent: false })
    })
    // this.property.get('propertyType').valueChanges.subscribe((propertyType: string) => {
    //   console.log(propertyType)
    //   if (propertyType) {
    //     let goodsSold = 'All fixed floor coverings, window furnishings and electrical light fittings'
    //     if (propertyType == 'Land Only') goodsSold = 'Nil Vacant Land'
    //     this.property.get('goodsSold').patchValue(goodsSold, { emitEvent: false })
    //     // this.property.get('goodsSold').updateValueAndValidity()
    //   }
    // })
  }

  disableForms = () => {
    console.log('Disable ')
    this.detail.disable()
    this.property.disable()
  }
  enableForms = () => {
    this.detail.enable()
    this.property.enable()
  }

  agentsList = {
    import: (agents) => {
      if (agents) {
        this.detail.get('agents').patchValue([])
        let formArray = this.detail.get('agents') as FormArray;

        formArray.clear()
        agents.forEach(agent => {
          formArray.push(
            this.builder.group({
              _id: agent._id,
              name: agent.name,
              email: agent.email,
              mobile: agent.mobile,
              image: agent.image
            }))
        });
      }
    },

    addSingle: (agent) => {
      let agents = this.detail.get('agents') as FormArray
      agents.push(
        this.builder.group({
          _id: agent._id,
          name: agent.name,
          email: agent.email,
          mobile: agent.mobile,
          image: agent.image
        }))
    },

    remove: (index) => {
      if (!this.app.permission.listing.selected.editField) return;
      let agents = this.detail.get('agents') as FormArray
      agents.removeAt(index)
    }
  }

  titles = {
    import: async titles => {
      console.log('form service -> ', titles)
      if (!titles) return

      const formArray = this.property.get('titles') as FormArray;
      formArray.clear()

      for (const title of titles) {
        const form = this.build.title();
        form.patchValue(title);
        formArray.push(form);
      }
    }
  }

  imagesList = {
    import: async (images) => {
      if (images) {
        this.property.get('images').patchValue([])
        let formArray = this.property.get('images') as FormArray;
        formArray.clear()

        for (let image of images) {
          image.url = await this.app.url(image.name)

          formArray.push(
            this.builder.group(image))
        }
      }
    },

    addSingle: async (image) => {
      let images = this.property.get('images') as FormArray
      images.push(
        this.builder.group({
          name: image.name,
          url: image.url
        }))
    },

    remove: async (index) : Promise<void> => {
      return new Promise(async resolve => {
        if (!this.app.permission.listing.selected.editField) return resolve();
        console.log('removing image')
        let images = this.property.get('images') as FormArray
        let img = images.at(index)
        console.log(img)
        await this.api.post('listing/photo/remove', { id: this._id.get('_id').value, image: img.value })
        images.removeAt(index)
        resolve()
      })

      // const thumbnailIndexControl = this.property.get('thumbnailIndex');
      // console.log(thumbnailIndexControl)
      // if (index <= thumbnailIndexControl.value && thumbnailIndexControl.value > 0) {
      //   console.log('old: ', thumbnailIndexControl.value);
      //   console.log('new: ', thumbnailIndexControl.value - 1);
      //   thumbnailIndexControl.setValue( thumbnailIndexControl.value - 1 );
      // }
    },

    setThumbnailIndex: index => {
      if (!this.app.permission.listing.selected.editField) return;

      let images: any = this.property.controls.images
      let thumbnail = images.at(index)
      images.removeAt(index)
      images.insert(0, thumbnail)
      // this.property.get('thumbnailIndex').setValue(index);
    }
  }

  floorplanList = {
    import: async (images) => {
      if (images) {
        // this.property.get('images').patchValue([])
        let formArray = this.property.get('floorplan') as FormArray;
        formArray.clear()

        for (let image of images) {
          image.url = await this.app.url(image.name)

          formArray.push(
            this.builder.group(image))
        }
      }
    },

    addSingle: async (image) => {
      let images = this.property.get('floorplan') as FormArray
      images.push(
        this.builder.group({
          name: image.name,
          url: image.url
        }))
    },

    remove: async (index) : Promise<void> => {
      return new Promise(async resolve => {
        if (!this.app.permission.listing.selected.editField) return resolve()
        console.log('removing image')
        let images = this.property.get('floorplan') as FormArray
        let img = images.at(index)
        console.log(img)
        await this.api.post('listing/floorplan/remove', { id: this._id.get('_id').value, image: img.value })
        images.removeAt(index)
        return resolve()
      })
    },
  }

  attachmentsList = {
    import: async (images) => {
      if (images) {
        // this.property.get('images').patchValue([])
        let formArray = this.detail.get('attachments') as FormArray;
        formArray.clear()

        for (let image of images) {
          image.url = await this.app.url(image.id)

          formArray.push(
            this.builder.group(image))
        }
      }
    },

    addSingle: async (image) => {
      let images = this.detail.get('attachments') as FormArray
      images.push(
        this.builder.group({
          id: image.id,
          name: image.name,
          url: image.url
        }))
    },

    remove: async (index) : Promise<void> => {
      return new Promise(async resolve => {
        if (!this.app.permission.listing.selected.editField) return resolve()
        console.log('removing image')
        let images = this.detail.get('attachments') as FormArray
        let img = images.at(index)
        console.log(img)
        // await this.api.post('listing/floorplan/remove', { id: this._id.get('_id').value, image: img.value })
        images.removeAt(index)
        return resolve()
      })
    },
  }

}
