import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppSessionService } from '../app-session.service';
import { CompanyRepresentativeService } from '../company-representative/company-representative.service';
import { AttachmentService } from '../attachment/attachment.service'
import { HelperService } from '../helper.service';
import { ApiService } from '../api.service';
import * as uuid from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class PurchaserService {

  constructor(public attachment: AttachmentService, public helper: HelperService, public companyRepresentative: CompanyRepresentativeService, private app: AppSessionService, private api: ApiService, private builder: FormBuilder) {

  }

  form = {
    detail: null,
    build: {
      detail: () => {
        let form = this.builder.group({
          id: [uuid.v4()],
          type: ['Person'],
          companyName: [''],
          companyRepresent: [''],
          companyRepresentatives: this.builder.array([], Validators.compose([Validators.required])),
          acn: [''],
          abn: [''],
          capacity: ['Themselves', Validators.compose([Validators.required])],
          givenNames: ['', Validators.compose([Validators.required])],
          secondaryGivenNames: [''],
          middleName: [''],
          surname: ['', Validators.compose([Validators.required])],
          birthDate: [null],
          secondarySurname: [''],
          capacityWording: [''],
          mobile: ['', Validators.compose([Validators.required])],
          phone: [''],
          email: ['', Validators.compose([Validators.required, Validators.email])],
          street: [''],
          address: [''],
          suburb: [''],
          state: ['VIC'],
          postcode: [''],
          notes: [''],
          representativeGoodman: [false],
          representativeCompanyName: [''],
          representativeAddress: [''],
          representativeSuburb: [''],
          representativeState: ['VIC'],
          representativePostcode: [''],
          representativeContactName: [''],
          representativeMobile: [''],
          representativeEmail: [''],
          price: [''],
          pending: [false],
          attachments: this.builder.array([])
        })
        this.form.subscriptions(form)
        return form
      }
    },
    import: (formArray, purchasers) => {
      while (formArray.length !== 0) formArray.removeAt(0)
      if (purchasers) {
        for (let purchaser of purchasers) {
          let purchaserForm = this.form.build.detail()
          this.companyRepresentative.form.import(purchaserForm.controls.companyRepresentatives, purchaser.companyRepresentatives)
          this.attachment.form.import(purchaserForm.controls.attachments, purchaser.attachments)
          purchaserForm.patchValue(purchaser)
          formArray.push(purchaserForm)
        }
      }
    },
    add: (formArray) => {
      this.form.detail = this.form.build.detail()
      this.form.detail.controls.pending.patchValue(true)
      formArray.push(this.form.detail)
    },
    remove: (formArray, index) => {
      formArray.removeAt(index)
    },
    subscriptions: (form) => {
      form.controls.mobile.valueChanges.subscribe((mobile: string) => {
        if (form.controls.mobile.valid && form.controls.mobile.value !== '' && form.controls.phone.invalid) {
          form.controls.phone.clearValidators()
          form.controls.phone.updateValueAndValidity()
        }
        else if (form.controls.mobile.invalid && form.controls.phone.invalid) {
          form.controls.phone.setValidators([Validators.required])
          form.controls.mobile.updateValueAndValidity()
        }
      })
      form.controls.phone.valueChanges.subscribe((phone: string) => {
        if (form.controls.phone.valid && form.controls.phone.value !== '' && form.controls.mobile.invalid) {
          form.controls.mobile.clearValidators()
          form.controls.mobile.updateValueAndValidity()
        }
        else if (form.controls.phone.invalid && form.controls.mobile.invalid) {
          form.controls.mobile.setValidators([Validators.required])
          form.controls.mobile.updateValueAndValidity()
        }
      })
      form.get('type').valueChanges.subscribe((type: string) => {
        form.get('companyName').clearValidators()
        form.get('acn').clearValidators()
        form.get('givenNames').clearValidators()
        form.get('surname').clearValidators()
        form.get('birthDate').clearValidators()
        form.get('companyRepresentatives').clearValidators()
        if (type == 'Person') {
          form.get('givenNames').setValidators([Validators.required])
          form.get('surname').setValidators([Validators.required])
          // form.get('capacity').patchValue('Themselves')
        }
        else {
          form.get('companyName').setValidators([Validators.required])
          form.get('acn').setValidators([Validators.required])
          form.get('companyRepresentatives').setValidators([Validators.required])
        }
        form.get('companyName').updateValueAndValidity()
        form.get('acn').updateValueAndValidity()
        form.get('givenNames').updateValueAndValidity()
        form.get('surname').updateValueAndValidity()
        form.get('birthDate').updateValueAndValidity()
        form.get('companyRepresentatives').updateValueAndValidity()
        // form.get('capacityWording').patchValue('')
        // form.get('secondaryGivenNames').patchValue('')
        // form.get('secondarySurname').patchValue('')
      })
      form.get('capacity').valueChanges.subscribe((capacity) => {
        this.form.changeCapacityWording(form, capacity)
      })
      form.get('givenNames').valueChanges.subscribe(() => {
        form.get('givenNames').patchValue(this.helper.titleCase(form.get('givenNames').value), { emitEvent: false })
        this.form.changeCapacityWording(form, form.get('capacity').value)
      })
      form.get('middleName').valueChanges.subscribe(() => {
        form.get('middleName').patchValue(this.helper.titleCase(form.get('middleName').value), { emitEvent: false })
        // this.form.changeCapacityWording(form, form.get('capacity').value)
      })
      form.get('surname').valueChanges.subscribe(() => {
        form.get('surname').patchValue(this.helper.titleCase(form.get('surname').value), { emitEvent: false })
        this.form.changeCapacityWording(form, form.get('capacity').value)
      })
      form.get('secondaryGivenNames').valueChanges.subscribe(() => {
        form.get('secondaryGivenNames').patchValue(this.helper.titleCase(form.get('secondaryGivenNames').value), { emitEvent: false })
        this.form.changeCapacityWording(form, form.get('capacity').value)
      })
      form.get('secondarySurname').valueChanges.subscribe(() => {
        form.get('secondarySurname').patchValue(this.helper.titleCase(form.get('secondarySurname').value), { emitEvent: false })
        this.form.changeCapacityWording(form, form.get('capacity').value)
      })
      form.get('companyName').valueChanges.subscribe(() => {
        form.get('companyName').patchValue(this.helper.titleCase(form.get('companyName').value), { emitEvent: false })
        this.form.changeCapacityWording(form, form.get('capacity').value)
      })
      form.get('companyRepresent').valueChanges.subscribe((change) => {
        form.get('companyRepresent').patchValue(this.helper.titleCase(form.get('companyRepresent').value), { emitEvent: false })
        this.form.changeCapacityWording(form, form.get('capacity').value)
      })
      // form.get('companyRepresent').get('capacity').valueChanges.subscribe(_ =>{
      //   this.form.changeCapacityWording(form, form.get('capacity').value)
      // })

      form.get('address').valueChanges.subscribe(() => {
        form.get('address').patchValue(this.helper.titleCase(form.get('address').value), { emitEvent: false })
      })
      form.get('suburb').valueChanges.subscribe(() => {
        form.get('suburb').patchValue(this.helper.titleCase(form.get('suburb').value), { emitEvent: false })
      })

      form.get('capacityWording').valueChanges.subscribe(_ => {

      })
    },

    getCompanyRepresentativeWording: (rep) => {
      if (!rep) { return '' }
      return `${rep.firstName} ${rep.surname} as ${rep.capacity}`
    },
    changeCapacityWording: (form, capacity) => {
      if (form.get('givenNames').value == null) { form.get('givenNames').patchValue('') }
      if (form.get('surname').value == null) { form.get('surname').patchValue('') }
      if (form.get('secondaryGivenNames').value == null) { form.get('secondaryGivenNames').patchValue('') }
      if (form.get('secondarySurname').value == null) { form.get('secondarySurname').patchValue('') }
      if (form.get('companyName').value == null) { form.get('companyName').patchValue('') }
      if (form.get('companyRepresent').value == null) { form.get('companyRepresent').patchValue('') }


      // PERSON CAPACITY
      if (form.get('type').value == 'Person') {
        switch (capacity) {
          case 'Themselves': {
            form.get('capacityWording').patchValue(
              form.get('givenNames').value + ' ' + form.get('surname').value + ' as Purchaser'
            )
            break;
          }
          case 'Power of Attorney': {
            form.get('capacityWording').patchValue(
              form.get('secondaryGivenNames').value + ' ' + form.get('secondarySurname').value + ' as Attorney for ' + form.get('givenNames').value + ' ' + form.get('surname').value
            )
            break;
          }
          case 'Deceased Estate': {
            form.get('capacityWording').patchValue(
              form.get('givenNames').value + ' ' + form.get('surname').value + ' as Legal Personal Representative of the Estate of ' + form.get('secondaryGivenNames').value + ' ' + form.get('secondarySurname').value
            )
            break;
          }
          case 'Trustee': {
            form.get('capacityWording').patchValue(
              form.get('givenNames').value + ' ' + form.get('surname').value + ' as Trustee for ' + form.get('secondaryGivenNames').value
            )
            break;
          }
          case 'Other': {
            form.get('capacityWording').patchValue(form.get('secondaryGivenNames').value)
            break;
          }
        }
      }

      // COMPANY CAPACITY
      if (form.get('type').value == 'Company') {
        let companyRep = form.get('companyRepresentatives').value[0]
        switch (capacity) {
          case 'Themselves': {
            form.get('capacityWording').patchValue(
              `${this.form.getCompanyRepresentativeWording(companyRep)} of ${form.get('companyName').value}`
            )
            break;
          }
          case 'Trustee': {
            form.get('capacityWording').patchValue(
              `${this.form.getCompanyRepresentativeWording(companyRep)} of ${form.get('companyName').value} as Trustee for ${form.get('secondaryGivenNames').value}`
            )
            break;
          }
          case 'Other': {
            form.get('capacityWording').patchValue(form.get('secondaryGivenNames').value)
            break;
          }
        }
      }

      // switch (capacity) {
      //   case 'Themselves': {
      //     if (form.controls.type.value == 'Person') {
      //       form.get('capacityWording').patchValue(
      //         form.get('givenNames').value + ' ' + form.get('surname').value + ' as Purchaser'
      //       )
      //     }
      //     else {
      //       form.get('capacityWording').patchValue(
      //         form.get('companyName').value + ' as Purchaser'
      //       )
      //     }
      //     break
      //   }
      //   case 'Power of Attorney': {
      //     form.get('capacityWording').patchValue(
      //       form.get('secondaryGivenNames').value + ' ' + form.get('secondarySurname').value + ' as Attorney for ' + form.get('givenNames').value + ' ' + form.get('surname').value
      //     )
      //     break
      //   }
      //   case 'Deceased Estate': {
      //     form.get('capacityWording').patchValue(
      //       form.get('givenNames').value + ' ' + form.get('surname').value + ' as Legal Personal Representative of the Estate of ' + form.get('secondaryGivenNames').value + ' ' + form.get('secondarySurname').value
      //     )
      //     break
      //   }
      //   case 'Trustee': {
      //     if (form.controls.type.value == 'Person') {
      //       form.get('capacityWording').patchValue(
      //         form.get('givenNames').value + ' ' + form.get('surname').value + ' as Trustee for ' + form.get('secondaryGivenNames').value
      //       )
      //     }
      //     else {
      //       if (form.get('companyRepresentatives').value.length > 0) {
      //         let companyRepresent = form.get('companyRepresentatives').value[0]
      //         console.log('companyRepresent')
      //         console.log(companyRepresent)
      //         form.get('capacityWording').patchValue(`${companyRepresent.firstName} ${companyRepresent.surname} as ${companyRepresent.capacity} of ${form.get('companyName').value} as Trustee for ${form.get('secondaryGivenNames').value}`)
      //       }
      //       else {
      //         form.get('capacityWording').patchValue(form.get('companyName').value + ' as Trustee for ' + form.get('secondaryGivenNames').value)
      //       }
      //     }
      //     break
      //   }
      //   case 'Other': {
      //     form.get('capacityWording').patchValue(form.get('secondaryGivenNames').value)
      //     break
      //   }
      //   case 'Director': {
      //     form.get('capacityWording').patchValue(
      //       (form.get('companyRepresent').value ? `${form.get('companyRepresent').value.firstName} ${form.get('companyRepresent').value.surname}` : '') + ' as Director of ' + form.get('companyName').value
      //     )
      //     break
      //   }
      //   case 'Director / Secretary': {
      //     form.get('capacityWording').patchValue(
      //       (form.get('companyRepresent').value ? `${form.get('companyRepresent').value.firstName} ${form.get('companyRepresent').value.surname}` : '') + ' as Director/Secretary of ' + form.get('companyName').value
      //     )
      //     break
      //   }
      //   case 'Trustee ': {
      //     form.get('capacityWording').patchValue(
      //       (form.get('companyRepresent').value ? `${form.get('companyRepresent').value.firstName} ${form.get('companyRepresent').value.surname}` : '') + ' as Director of ' + form.get('companyName').value + ' as Trustee for ' + form.get('secondaryGivenNames').value
      //     )
      //     break
      //   }
      //   default: {
      //     form.get('capacityWording').patchValue('')
      //     break
      //   }
      // }
    }
  }

}
