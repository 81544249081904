/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:904759ef-5fa4-4c79-8a53-389a65da0eb6",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_gkplXK4y8",
    "aws_user_pools_web_client_id": "i35q8qm0748nfn8bqbf4cm5l6",
    "oauth": {},
    "aws_user_files_s3_bucket": "agentpower4165219-production",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
