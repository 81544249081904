import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppSessionService } from '../app-session.service';
import { ApiService } from '../api.service';


@Injectable({
  providedIn: 'root'
})
export class AttachmentService {

  constructor(private app: AppSessionService, private api: ApiService, private builder: FormBuilder) {
    this.form.detail = this.build.detail()
  }

  build = {
    detail: () => {
      return this.builder.group({
        id: [''],
        name: [''],
        url: ['']
      })
    }
  }

  form = {
    detail: null,
    import: (formArray, attachments) => {
      while (formArray.length !== 0) formArray.removeAt(0)
      if (attachments) {
        for (let attachment of attachments) {
          let form = this.build.detail()
          form.patchValue(attachment)
          formArray.push(form)
        }
      }
    },
    add: (formArray) => {
      this.form.detail = this.build.detail()
      formArray.push(this.form.detail)
    },
    remove: (formArray, index) => {
      formArray.removeAt(index)
    }

  }

}
