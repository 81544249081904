import 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Auth } from 'aws-amplify'
import { AppSessionService } from './app-session.service'

@Injectable()
export class ApiService {
  url: string
  wssUrl: string

  constructor(
    public app: AppSessionService, 
    public modal: ModalController, 
    public http: HttpClient
  ) {
    if (app.env.localApi) { this.url = 'http://127.0.0.1:8080'; this.wssUrl = 'ws://127.0.0.1:8080' }
    else { this.url = `https://agentpower4-api-${app.env.name}.bento.solutions`; this.wssUrl = `ws://agentpower4-api-${app.env.name}.bento.solutions` }
  }


  post(method, parameters, authorize = true) {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        let headers = new HttpHeaders();
        if (authorize)
          headers = headers.set('Authorization', session.getIdToken().getJwtToken());
        this.http.post(this.url + '/api/' + method, parameters, { headers: headers }).toPromise().then((data) => {
          resolve(data)
        }).catch(async (error) => {
          console.log(error)
          resolve()
        })
      })
    })
  }

  get(method, parameters, authorize = true) {
    return new Promise((resolve, reject) => {
      Auth.currentSession().then((session) => {
        let headers = new HttpHeaders();
        if (authorize) { headers = headers.set('Authorization', session.getIdToken().getJwtToken()); }
        this.http.get(this.url + '/api/' + method, { headers: headers, params: parameters }).toPromise().then((data) => {
          resolve(data)
        }).catch(async (error) => {
          console.log(error)
          resolve()
        })
      })
    })
  }

}
