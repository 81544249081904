import { Injectable } from '@angular/core';
import { FormService } from "./form.service";
import { AppSessionService } from '../app-session.service';
import { ApiService } from '../api.service';
import { AmplifyService } from 'aws-amplify-angular';
import { ListingService } from '../listing/listing.service';
import * as moment from 'moment'
import { NavigationService } from '../navigation.service';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  list: any[]

  constructor(
    public listing: ListingService,
    private amplify: AmplifyService,
    private app: AppSessionService,
    private api: ApiService,
    public form: FormService,
    private nav: NavigationService,
    private helper: HelperService,
  ) {
    this.list = []
  }

  notification = {
    list: async (from, userId): Promise<any[]> => {
      return new Promise(async resolve => {
        if (!from) from = moment().add(-7, 'days').format('DD/MM/YYYY')
        let notifications: any = await this.api.get('notification/list', { from, userId })
        notifications.forEach(n => {
          n.date = new Date(n.date)
        })

        return resolve(notifications)
      })
    },
    clear: async (userId) => {
      return new Promise(async resolve => {
        let notifications = await this.api.get('notification/clear', { userId })
        this.app.user.notificationDate = new Date()
        return resolve(notifications)
      })
    }
  }

  add = async (agencyId) => {
    return new Promise(async resolve => {
      // Add new user with agencyId
      // let userId = await this.api.post('user/add', { agencyId: agencyId })
      this.form.detail.patchValue({ _id: null, agencyId })

      // Push to user list.
      this.list.push(this.form.detail.value)

      return resolve({ error: null, response: this.form.detail.get('_id').value })
    })
  }

  save = async () => {
    return new Promise(async resolve => {
      let user: any = {}

      user = this.form.detail.value
      console.log(user)
      // if (user._id) {
      user.name = `${user.firstName} ${user.surname}`
      // Clean Data
      if (user.defaults) {
        let d = user.defaults
        user.defaults = {
          exclusivePeriodStandard: d.exclusivePeriodStandard,
          exclusivePeriodOther: d.exclusivePeriodStandard === 'other' ? d.exclusivePeriodOther : 0,
          continuingPeriodStandard: d.continuingPeriodStandard,
          continuingPeriodOther: d.continuingPeriodStandard === 'other' ? d.continuingPeriodOther : 0,
          agentEstimate: {
            type: d.agentEstimate.type
          },
          commision: {
            type: d.commision.type,
            percentage: d.commision.percentage,
            fixed: d.commision.fixed,
            performanceType: d.commision.performanceType,
            performance: d.commision.performance
          },
          marketing: {
            other: d.marketing.other,
            payable: d.marketing.payable
          }
        }
      }

      if (user.permissions) {
        user.permissions.god = user.permissions.god ? user.permissions.god : false
      }

      // Save User
      let response = await this.api.post('user/save', user)
      console.log(response)

      // Load this user list again.
      await this.load(this.form.detail.get('agencyId').value)

      if (!response) {
        return resolve({ error: 'Error: User failed to save.', response: null })
      }
      // } else {
      //   return resolve({ error: 'Error: No user to be saved.', response: null })
      // }

      return resolve({ error: null, response: user })
    })
  }

  createCognito = async () => {
    return new Promise(async resolve => {
      let user: any = {}
      user = this.form.detail.value
      if (user._id && !user.cognitoSignupComplete) { // && !user.cognito
        const response = await this.api.post('user/create/cognito', user)
        await this.load(this.form.detail.get('agencyId').value)
        if (user._id === this.app.user._id) {
          this.app.user = await this.find({ _id: this.app.user._id })
        }
        return resolve({ error: null, response: response })
      }
      else {
        return resolve({ error: 'Invalid User.', response: null })
      }
    })
  }

  resetPassword = async () => {
    return new Promise(async resolve => {
      let user: any = {}
      user = this.form.detail.value
      const response = await this.api.post('user/resetPassword', user) as any
      if (response.ok) {
        return resolve({ error: null, response })
      }
      else return resolve({ error: 'Error: Failed to reset user password.', response: null })
    })
  }

  load = async (agencyId?: string) => {
    return new Promise(async resolve => {
      let o = agencyId ? { agencyId } : {}
      let response: any = await this.api.get('user/list', o)
      if (response) {
        console.log(response)
        await Promise.all([
          response.forEach(async element => {
            delete element.imageUrl
            if (element.image) element.imageUrl = await this.app.url(element.image)
          })
        ])

        this.list = response
      } else {
        this.list = []
      }

      return resolve({ error: null, response: true })
    })
  }

  find = async (options?) => {
    return new Promise(async resolve => {
      // Options exist when you wish to find a user either by _id or email.
      // Pass these options to api
      let o: any = {}
      if (options) {
        options._id ? o._id = options._id : null
        options.email ? o.email = options.email : null
      }

      let { maintenance, data }: any = await this.api.get('user/find', o)

      // Call Service
      let adminEmails = ['scott.goodman@goodmangroup.com.au', 'amy.hantler@goodmangroup.com.au', 'bento@bento.solutions', 'luke@bento.solutions']
      if (adminEmails.indexOf(options.email) == -1) {
        if (this.helper.isMaintenanceMode(maintenance)) { // Call UI and component to cover the full screen.
          this.nav.push('maintenance', { maintenance })
        }
      }

      let users = data
      console.log(users)
      if (users.length === 1) {
        let user = users[0]
        await this.getImages(user)

        // Patch User details to form
        this.form.detail.patchValue(user)

        let notifications: any = await this.notification.list(user.notificationDate, user._id)
        this.app.notificationCount = notifications.length

        return resolve(user)
      } else {
        for (let user of users) {
          await this.getImages(user)
        }
        return resolve(users)
      }
    })
  }

  getImages = async (user) => {
    // Get image Url
    if (user.image) {
      user.imageUrl = await this.app.url(user.image)
    }
    // user.agency.photo.url = null
    if (user.agency && user.agency.photo) {
      user.agency.photo.url = await this.app.url(user.agency.photo.name)
    }
  }

  // WARNING: BE CAREFUL USING THIS METHOD AS IT DELETES THS USER FROM DB ENTIRELY
  //   --- FIRST LOOK AT CHANGING THE USER STATUS TO INACTIVE.
  remove = async (userId) => {
    return new Promise(async resolve => {
      let user = this.list.find(u => u._id == userId)

      let response = await this.api.post('user/remove', { _id: userId, email: user.email, agencyId: user.agencyId })

      // Remove user from total list
      this.list = this.list.filter(a => {
        return a._id != userId
      })

      // reset form if currently selected purchaser removed
      if (this.form.detail.controls._id.value == userId) {
        this.form.cleanup()
      }

      return resolve({ error: null, response: userId })
    })
  }


}
