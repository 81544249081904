import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSessionService } from '../app-session.service';
import { NavigationService } from '../navigation.service';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  state = ''
  document = {
    state: '',
  }
  authority = {
    state: '',
  }

  // Subjects!
  stateChange : Subject<string> = new Subject<string>()
  authorityStateChange: Subject<string> = new Subject<string>()

  constructor(
    private app: AppSessionService,
    private nav: NavigationService
  ) {

    this.stateChange.subscribe(value => {
      console.log(`Page State Change :: ${value}`)
      if (!(this.app.user && this.app.user._id)) { this.nav.setRoot('auth') }
      this.state = value
    })

    this.authorityStateChange.subscribe(value => {
      console.log(`Authority State Change :: ${value}`)
      this.authority.state = value
    })

  }

  setState = (state) => {
    this.stateChange.next(state)
  }

  setAuthorityState = (state) => {
    this.authorityStateChange.next(state)
  }

  footer = [];
  
  setFooter(footer) {
    console.log('setting footer', footer);
    this.footer = footer;
  }
}
