import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AmplifyService } from 'aws-amplify-angular'
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from '@angular/forms' 
import { EnvironmentService } from './services/environment.service'
import { ApiService } from './services/api.service'
import { AuthService } from './services/auth.service'
import { AppSessionService } from './services/app-session.service'
import { GoogleService } from './services/google.service'
import { StorageService } from './services/storage.service'
import { CommissionTextService } from './services/authority/commission-text.service'
import { LoadingService } from "./services/loading.service";
import { PermissionService } from "./services/permission.service";
import { NotificationsComponent } from './modals/notifications/notifications.component';
import { PdfService } from "./services/pdf.service";
import { ContractService } from "./services/contract/contract.service";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { ConfirmationComponent } from './modals/confirmation/confirmation.component';
import { NgxMaskModule } from 'ngx-mask'
import { EmailComponent } from './modals/email/email.component';
import { CropComponent } from './modals/crop/crop.component';
import { AngularCropperjsModule } from 'angular-cropperjs'
import { WithdrawAmendComponent } from './pages/listing/components/offers/modals/withdraw-amend/withdraw-amend.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { AuthGuardService } from './services/auth-guard.service';
import { environment } from '../environments/environment';
import { UpdateService } from "./services/update.service";

// Elmah Error Logging ---
const Elmahio = require('elmah.io.javascript')
class ElmahIoErrorHandler implements ErrorHandler {
  logger: any;
  constructor() {
    this.logger = new Elmahio({
      apiKey: environment.elmah.key,
      logId: environment.elmah.id,
    });
  }
  handleError(error) {
    // console.error(error)
    if (error && error.message) {
      this.logger.error(error.message, error);
    } else {
      this.logger.error('Error in application', error);
    }
  }
}
// End Elmah Error logging ---

@NgModule({
    declarations: [
        AppComponent, 
        NotificationsComponent, 
        ConfirmationComponent, 
        EmailComponent, 
        CropComponent, 
        WithdrawAmendComponent,
        OrderByPipe,
    ],
    entryComponents: [
        NotificationsComponent, 
        ConfirmationComponent, 
        EmailComponent, 
        CropComponent, 
        WithdrawAmendComponent
    ],
    imports: [
        BrowserModule, 
        IonicModule.forRoot({ mode: 'md'}), 
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        FontAwesomeModule,
        NgxMaskModule,
        AngularCropperjsModule
    ],
    providers: [
        StatusBar,
        SplashScreen,
        EnvironmentService,
        ApiService,
        AuthService,
        AmplifyService,
        AppSessionService,
        CommissionTextService,
        LoadingService,
        PermissionService,
        GoogleService,
        StorageService,
        PdfService,
        ContractService,
        AuthGuardService,
        UpdateService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        // { provide: ErrorHandler, useClass: ElmahIoErrorHandler },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
