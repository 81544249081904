import { Component, ElementRef, ViewChild } from '@angular/core';
import { IonGrid, ModalController, NavParams } from '@ionic/angular';
import { CropperComponent } from 'angular-cropperjs'
import Cropper from 'cropperjs';

@Component({
  selector: 'app-crop',
  templateUrl: './crop.component.html',
  styleUrls: ['./crop.component.scss'],
})
export class CropComponent {

  constructor(public modal: ModalController, public params: NavParams) {
  }

  @ViewChild('cropper', { static: false }) cropperElement : CropperComponent;
  @ViewChild('cropContentPane', { static: false }) contentPane;
  fileUrl: string = null;

  cropperOptions: Cropper.Options = {
    aspectRatio: 1,
    rotatable: false,
    scalable: false,
    zoomable: true,
    zoomOnTouch: true,
    zoomOnWheel: true,
    viewMode: 0,
    minContainerWidth: 400,
  };

  initFix = false;
  ngAfterViewInit() {
    
    // The Cropper would not resize to the correct width/ height ratio at times
    // We recreate the Cropper Tool with the minContainerWidth being the width of the <div> 
    // its in.  We need a short timeout to help ensure everythings loaded before creation.
    this.cropperElement.ready.subscribe(ready => {
      setTimeout(() => {
        this.createNewCropper(ready);
      }, 250)
    })

  }

  createNewCropper(ready: boolean) {
    if (ready && !this.initFix) {
      this.initFix = true;
      this.cropperElement.cropper.destroy();
      this.cropperOptions.minContainerWidth = this.contentPane.nativeElement.offsetWidth
      this.cropperElement.cropper = new Cropper(this.cropperElement.imageElement, this.cropperOptions);
    }
  }

  ngOnDestroy() {
    console.log('OnDestroy')
    this.cropperElement.cropper.destroy();
  }

  ui = {
    tempFileUrl: null,

    onFileInput: (event) => {
      return new Promise((resolve, reject) => {
        Array.from(event.target.files).forEach((file: any) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const src = reader.result;
            this.ui.tempFileUrl = src;
          };
          reader.readAsDataURL(file);
        });
      });
    },
  }

  confirm = () => {
    const canv = this.cropperElement.cropper.getCroppedCanvas();

    canv.toBlob(result => {
      this.modal.dismiss({
        complete: true,
        result
      });
    });
  }

  dismiss = () => {
    this.modal.dismiss({
      complete: false,
      result: null
    });
  }
}
