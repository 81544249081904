import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppSessionService } from '../app-session.service';
import { CompanyRepresentativeService } from '../company-representative/company-representative.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ConveyancerService {

  constructor(public companyRepresentative: CompanyRepresentativeService, private app: AppSessionService, private api: ApiService, private builder: FormBuilder) {
    this.form.detail = this.build.detail()
  }

  build = {
    detail: () => {
      return this.builder.group({
        companyName: ['', Validators.compose([Validators.required])],
        acn: [''],
        abn: [''],
        mobile: ['', Validators.compose([Validators.required])],
        phone: [''],
        email: ['', Validators.compose([Validators.required])],
        street: ['', Validators.compose([Validators.required])],
        address: [''],
        suburb: ['', Validators.compose([Validators.required])],
        state: ['VIC', Validators.compose([Validators.required])],
        postcode: ['', Validators.compose([Validators.required])],
        notes: [''],
        companyRepresentatives: this.builder.array([]),
        attachments: this.builder.array([])
      })
    }
  }

  form = {
    detail: null
  }

}
