import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(public helper: HelperService) { }

  massage = {
    authority: {
      load: (listing) => {
        let m = listing
        console.log('Massage Auth Load')
        // console.log(m)
        if (m.auctionDate) m.auctionDate = { date: this.helper.datePicker.fromDate(m.auctionDate) }
        if (m.saleTypeDate) m.saleTypeDate = { date: this.helper.datePicker.fromDate(m.saleTypeDate) }
        
        console.log(m)
      },

      save: (listing) => {
        let m = listing
        console.log('Massage Auth Save')
        console.log(m)

        if (m.auctionDate)
          if (m.auctionDate.date) m.auctionDate = this.helper.datePicker.toDate(m.auctionDate.date)

        
        console.log(m)
      }
    },

    offer: {
      load: (offer) => {
        let c = offer.contract
        if (c.depositDate) c.depositDate = { date: this.helper.datePicker.fromDate(c.depositDate) }
        if (c.settlementDate) c.settlementDate = { date: this.helper.datePicker.fromDate(c.settlementDate) }
        if (c.approvalDate) c.approvalDate = { date: this.helper.datePicker.fromDate(c.approvalDate) }
        if (c.buildingInspectionWrittenReportDate) c.buildingInspectionWrittenReportDate = c.buildingInspectionWrittenReportDate = { date: this.helper.datePicker.fromDate(c.buildingInspectionWrittenReportDate) }
        if (c.buildingInspectionReportDate) c.buildingInspectionReportDate = { date: this.helper.datePicker.fromDate(c.buildingInspectionReportDate) }
        if (c.pestInspectionWrittenReportDate) c.pestInspectionWrittenReportDate = { date: this.helper.datePicker.fromDate(c.pestInspectionWrittenReportDate) }
        if (c.pestInspectionReportDate) c.pestInspectionReportDate = { date: this.helper.datePicker.fromDate(c.pestInspectionReportDate) }
        if (c.subjectToSaleContractDate) c.subjectToSaleContractDate = { date: this.helper.datePicker.fromDate(c.subjectToSaleContractDate) }
        if (c.subjectToSaleUnconditionalDate) c.subjectToSaleUnconditionalDate = { date: this.helper.datePicker.fromDate(c.subjectToSaleUnconditionalDate) }
        if (c.subjectToSaleWithdrawDate) c.subjectToSaleWithdrawDate = { date: this.helper.datePicker.fromDate(c.subjectToSaleWithdrawDate) }
        for (let p of offer.purchasers) {
          if (p.birthDate && p.birthDate.date) p.birthDate = { date: this.helper.datePicker.fromDate(p.birthDate) }
        }
      },

      save: (offer) => {
        let c = offer.contract
        if (c.depositDate)
          if (c.depositDate.date) c.depositDate = this.helper.datePicker.toDate(c.depositDate.date)
        if (c.settlementDate)
          if (c.settlementDate.date) c.settlementDate = this.helper.datePicker.toDate(c.settlementDate.date)
        if (c.approvalDate)
          if (c.approvalDate.date) c.approvalDate = this.helper.datePicker.toDate(c.approvalDate.date)
        if (c.buildingInspectionWrittenReportDate)
          if (c.buildingInspectionWrittenReportDate.date) c.buildingInspectionWrittenReportDate = this.helper.datePicker.toDate(c.buildingInspectionWrittenReportDate.date)
        if (c.buildingInspectionReportDate)
          if (c.buildingInspectionReportDate.date) c.buildingInspectionReportDate = this.helper.datePicker.toDate(c.buildingInspectionReportDate.date)
        if (c.pestInspectionWrittenReportDate)
          if (c.pestInspectionWrittenReportDate.date) c.pestInspectionWrittenReportDate = this.helper.datePicker.toDate(c.pestInspectionWrittenReportDate.date)
        if (c.pestInspectionReportDate)
          if (c.pestInspectionReportDate.date) c.pestInspectionReportDate = this.helper.datePicker.toDate(c.pestInspectionReportDate.date)
        if (c.subjectToSaleContractDate)
          if (c.subjectToSaleContractDate.date) c.subjectToSaleContractDate = this.helper.datePicker.toDate(c.subjectToSaleContractDate.date)
        if (c.subjectToSaleUnconditionalDate)
          if (c.subjectToSaleUnconditionalDate.date) c.subjectToSaleUnconditionalDate = this.helper.datePicker.toDate(c.subjectToSaleUnconditionalDate.date)
        if (c.subjectToSaleWithdrawDate)
          if (c.subjectToSaleWithdrawDate.date) c.subjectToSaleWithdrawDate = this.helper.datePicker.toDate(c.subjectToSaleWithdrawDate.date)
        for (let p of offer.purchasers) {
          if (p.birthDate && p.birthDate.date) p.birthDate = this.helper.datePicker.toDate(p.birthDate.date)
        }
        return offer
      }
    }

  }

}
