
import 'rxjs/Rx';
import { Injectable, NgZone } from '@angular/core';
import { LoadingController, ModalController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { AppSessionService } from './app-session.service'
import { StorageService } from './storage.service'
import { ApiService } from './api.service'
import { Annotations, WebViewerInstance } from '@pdftron/webviewer';

@Injectable()
export class PdfService {
  document = <any>null
  url = null
  instance = null
  element = null
  annotationManager = null
  signeeId = null
  initialised = false
  signatureNo = 0
  firstClick = true
  documentLoaded = false
  annotationsLoaded = false

  signatureAnnotations = []
  visibleSignatureAnnotations = []

  constructor(public platform: Platform, public api: ApiService, public zone: NgZone, public storage: StorageService, public app: AppSessionService, public modal: ModalController, public http: HttpClient, public loading: LoadingController) {
  }

  saveDocument = async (instance, filename, flatten) => {
    return new Promise(async resolve => {
      let doc = instance.docViewer.getDocument()
      let xfdfString = await instance.annotManager.exportAnnotations()
      let data = await doc.getFileData({ xfdfString: xfdfString, flatten: flatten, flags: instance.CoreControls.SaveOptions.LINEARIZED })
      await this.storage.save({ name: filename, data: data, type: 'application/pdf' })
      return resolve({ error: null, response: true })
    })
  }

  saveAnnotations = async (instance, listingId, annotation, version) => {
    return new Promise(async resolve => {
      // let newAnnots = await instance.annotManager.exportAnnotCommand()
      // // console.log(newAnnots)
      // annotation.xfdfString = newAnnots

      let res: any = await this.api.post('listing/authority/save/annot', { id: listingId, annotation: annotation, version: version })
      return resolve(res.signed)
    })
  }

  saveContractAnnotations = async (instance, listingId, version, state, totalSignatureCount, annotation) => {
    return new Promise(async resolve => {
      let newAnnots = await instance.annotManager.exportAnnotCommand()
      console.log(newAnnots)
      annotation.xfdfString = newAnnots

      // Split String and add default appearance to remove the border.
      let firstHalf = annotation.xfdfString.split('<defaultappearance>')
      let secondHalf = firstHalf[1].split('</defaultappearance>')
      annotation.xfdfString = firstHalf[0] + '<defaultappearance>1 1 1 RG 1 1 1 rg  /Helv 9 Tf </defaultappearance>' + secondHalf[1]
      console.log(`contract/${state}/save/annot`)
      let res: any = await this.api.post(`contract/${state}/save/annot`, { id: listingId, annotation: annotation, version: version, total: totalSignatureCount })
      console.log(res)
      return resolve(res.signed)
    })
  }

  loadAnnotations = async (instance, listingId, PDFNames) => {
    return new Promise(async resolve => {
      let annots: any = await this.api.get('listing/authority/annots', { id: listingId })
      if (annots) {
        console.log('Annots Import')
        let mongoAnnots = annots[PDFNames[PDFNames.length - 1]]
        console.log(mongoAnnots)
        if (mongoAnnots) {
          for (let annot of mongoAnnots) {
            console.log(annot)
            const annotations = await instance.annotManager.importAnnotations(annot.xfdfString)
            console.log('Annot Imported')
            await instance.annotManager.drawAnnotationsFromList(annotations)
            console.log('Annot Drawn')
          }
        }
      }
      return resolve()
    })
  }


  s32 = {
    annotations: {
      load: (instance: WebViewerInstance, listingId, PDFNames) => {
        return new Promise(async resolve => {
          let { annotManager } = instance
          let annots: any = await this.api.get(`s32/signature/annots`, { id: listingId })
          if (annots) {
            let mongoAnnots = annots[PDFNames[PDFNames.length - 1]]
            if (mongoAnnots) {
              for (let annot of mongoAnnots) {
                const annotations = await annotManager.importAnnotations(annot.xfdfString)
                await annotManager.drawAnnotationsFromList(annotations)
              }
            }
          }
          console.groupEnd()
          resolve()
        })
      },
      save: (instance, listingId, version, state, annotation, useExportAnnotCommand = true) => {
        return new Promise(async resolve => {
          // if (useExportAnnotCommand) {
          //   let newAnnots = await instance.annotManager.exportAnnotCommand()
          //   console.log(newAnnots)
          //   annotation.xfdfString = newAnnots
          // }
          console.log(annotation)
          let res: any = await this.api.post(`s32/signature/save/annot`, { id: listingId, annotation, version })
          console.log(res)
          resolve(res.signed)
        })
      }
    }
  }

  offer = {
    annotations: {
      load: (instance: WebViewerInstance, offerId, PDFNames) => {
        return new Promise(async resolve => {
          let { annotManager } = instance
          let annots: any = await this.api.get(`offer/signature/annots`, { id: offerId })
          if (annots) {
            let mongoAnnots = annots[PDFNames[PDFNames.length - 1]]
            if (mongoAnnots) {
              for (let annot of mongoAnnots) {
                const annotations = await annotManager.importAnnotations(annot.xfdfString)
                await annotManager.drawAnnotationsFromList(annotations)
              }
            }
          }
          resolve()
        })
      },
      save: (instance, offerId, version, annotation) => {
        return new Promise(async resolve => {
          // let newAnnots = await instance.annotManager.exportAnnotCommand()
          // console.log(newAnnots)
          // console.log(annotation)
          // annotation.xfdfString = newAnnots

          let res: any = await this.api.post(`offer/signature/save/annot`, { id: offerId, annotation, version })
          console.log(res)
          resolve(res.signed)
        })
      }
    }
  }

  setSignatureModalButtonColor = (instance) => {
    // Changes the Inner of a PDFTron Element.
    const iframe = instance.iframeWindow.document
    const signatureCreateList = iframe.querySelectorAll('.signature-create')
    signatureCreateList.forEach(createButton => {
      createButton.innerHTML = "Sign Above & Click Here"
      createButton.setAttribute('style', 'color: red; background: yellow; font-weight: bold;');
    });
  }

  setSignatureWidgetColor = (instance) => {
    let { Annotations } = instance
    // Set the SignHere Styles.
    const createSignHereElement = Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement;
    Annotations.SignatureWidgetAnnotation.prototype.createSignHereElement = function () {
      // signHereElement is the default one with dark blue background
      const signHereElement = createSignHereElement.apply(this, arguments);
      signHereElement.style.background = 'yellow';
      signHereElement.style.color = 'red'
      signHereElement.style['font-weight'] = 'bold'
      return signHereElement;
    }

    // Set the Widget Full styles.
    // Annotations.WidgetAnnotation.getContainerCustomStyles  = widget => {
    //   if (widget instanceof Annotations.SignatureWidgetAnnotation) {
    //     return {
    //       'background-color': 'white',
    //     }
    //   }
    // }
  }

  drawWidgets = async (instance, xfdfString) => {
    return new Promise(async resolve => {
      await instance.annotManager.importAnnotations(xfdfString)
      return resolve({ error: null, response: true })
    })
  }

  createDocument = async (instance, url) => {
    return new Promise(async resolve => {
      let PDFNet: any = instance.PDFNet
      // this.documentLoaded = false
      let document = await PDFNet.PDFDoc.createFromURL(url)
      return resolve({ error: null, response: document })
    })
  }

  saveDocumentBuffer = async (instance, document) => {
    return new Promise(async resolve => {
      let PDFNet: any = instance.PDFNet
      let res = await document.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized)
      resolve(res)
    })
  }

  loadDocument = async (instance, buffer, filename) => {
    return new Promise(async resolve => {
      let res = await instance.docViewer.loadDocument(buffer, { filename: filename })
      resolve(res)
    })
  }

  createSignatures = async (instance, document, signeeId, personText) => {
    return new Promise(async resolve => {
      this.instance = instance
      this.document = document
      this.instance.annotManager.setCurrentUser(signeeId)
      this.signeeId = signeeId
      console.log(signeeId)
      let PDFNet: any = this.instance.PDFNet
      let textSearch = await PDFNet.TextSearch.create()
      textSearch.begin(this.document, signeeId, PDFNet.TextSearch.Mode.e_whole_word | PDFNet.TextSearch.Mode.e_highlight)
      await this.search.run(textSearch, this.search, true, personText)
      return resolve()
    })
  }
  createAmendSignatures = async (instance, document, PDFNames, signeeId, personText) => {
    return new Promise(async resolve => {
      this.instance = instance
      this.document = document
      this.instance.annotManager.setCurrentUser(signeeId)
      this.signeeId = signeeId
      let PDFNet: any = this.instance.PDFNet
      let textSearch = await PDFNet.TextSearch.create()
      let searchString = `${signeeId}-${PDFNames[PDFNames.length - 1]}`// vendorid-documentid
      console.log(`User Search String: ${searchString}`)
      textSearch.begin(this.document, searchString, PDFNet.TextSearch.Mode.e_whole_word | PDFNet.TextSearch.Mode.e_highlight)
      await this.search.run(textSearch, this.search, false, personText)
      return resolve()
    })
  }

  initialiseSignature = () => {
    this.signatureNo = 0
    this.firstClick = true
  }

  /*
    Gets annotation list and loops through it
    checks if the page number is 2 for the annotation
    second loop on the annotation list
  */
  getVisibleSignatureAnnotations = async (instance: WebViewerInstance, commisionSharing, orderSignatures = false) => {
    return new Promise(async resolve => {
      this.visibleSignatureAnnotations = []

      let { annotManager, Annotations } = instance
      let annotationList = annotManager.getAnnotationsList()

      // Enters here if there is an Authority with Commission Sharing. We will check at the end whether there are any annotations.
      // If no annotations we then add any annotations on PDF
      if (commisionSharing && !orderSignatures) { // == Authority With Commission Sharing 
        for (const annotation of annotationList) {

          // Dont care if annotation is not a signature Widget
          if (!(annotation instanceof Annotations.SignatureWidgetAnnotation)) continue;
          // Dont add if page number is above 7
          if (annotation.getPageNumber() > 7) { console.log('PageNo > 7s'); continue; }

          for (const compareAnnot of annotationList) {

            // Dont care if annotation is not a signature Widget
            if (!(compareAnnot instanceof Annotations.SignatureWidgetAnnotation)) continue;

            // Add two associated Annotations.
            if ((annotation.fieldName === compareAnnot.fieldName) && (annotation.getPageNumber() !== compareAnnot.getPageNumber())) {
              if (annotation.isVisible()) { this.visibleSignatureAnnotations.push(annotation) }
              if (compareAnnot.isVisible()) { this.visibleSignatureAnnotations.push(compareAnnot) }
              continue;
            }

          }

        }

      }

      // If there are signatures from the first section, Return list.
      if (this.visibleSignatureAnnotations.length > 0) return resolve(this.visibleSignatureAnnotations)

      // Add all visible annotations to list.
      for (const annotation of annotationList) {

        // Dont care if annotation is not a signature Widget
        if (!(annotation instanceof Annotations.SignatureWidgetAnnotation)) continue;
        if (annotation.isVisible()) { this.visibleSignatureAnnotations.push(annotation) }

      }

      return resolve(this.visibleSignatureAnnotations)

    })
  }
  getSignatureWidgets = async (instance, commissionSharing) => {
    return new Promise(async resolve => {
      this.signatureAnnotations = []
      const commissionSharingFlag = commissionSharing

      for (const annotation of await instance.annotManager.getAnnotationsList()) {
        // check if page 2 because all vendors will have a signature on page 2
        if (await annotation.getPageNumber() >= 7 || await annotation.getPageNumber() === 3) {
          if (commissionSharingFlag) {
            for (const annot of await instance.annotManager.getAnnotationsList()) {
              if (annotation.fieldName === annot.fieldName && await annotation.getPageNumber() !== await annot.getPageNumber()) {
                if (!annotation.Author && annotation.Subject === "Widget") { this.signatureAnnotations.push(annotation) }
                if (!annot.Author && annotation.Subject === "Widget") { this.signatureAnnotations.push(annot) }
                break
              }
            }
          }
          else {
            if (!annotation.Author && annotation.Subject === "Widget") { this.signatureAnnotations.push(annotation) }
          }
        }
      }
      return resolve(this.signatureAnnotations)
    })
  }

  gotoNextSignature = async (instance, commissionSharing, authorityAmend) => {
    return new Promise(async resolve => {
      let annotList: any = await this.getVisibleSignatureAnnotations(instance, commissionSharing, authorityAmend)
      if (!this.firstClick) {
        if (this.signatureNo + 1 < annotList.length) {
          this.signatureNo++
        } else {
          this.signatureNo = 0
        }
      } else {
        this.firstClick = false;
        this.signatureNo = 0
      }
      let annot = annotList[this.signatureNo]
      await instance.annotManager.jumpToAnnotation(annot)
      return resolve(this.signatureNo)
    })
  }

  search = {
    run: async (textSearch, search, isSignature, personText) => {
      return new Promise(async resolve => {
        console.log('Run')
        let PDFNet = this.instance.PDFNet
        let searched = await textSearch.run()
        if (searched.code === PDFNet.TextSearch.ResultCode.e_found) {
          searched.highlights.begin(this.document)
          return search.parseHighlight(searched.highlights, searched, search, isSignature, personText)
            .then((resultQuads) => {
              return resolve(search.run(textSearch, search, isSignature, personText))
            });
        } else if (searched.code === PDFNet.TextSearch.ResultCode.e_page) {
        } else if (searched.code === PDFNet.TextSearch.ResultCode.e_done) {
          return resolve();
        }
        return resolve(search.run(textSearch, search, isSignature, personText))
      })
    },
    parseHighlight: (highlights, searched, search, isSignature, personText) => {
      return new Promise(async resolve => {
        return highlights.hasNext().then((exists) => {
          if (exists) {
            return highlights.getCurrentQuads().then(async (quad) => {
              let PDFNet = this.instance.PDFNet
              if (isSignature) {  // On Signature
                let signatureText = 'signature-'
                if (personText === 'offers') { signatureText = `offerssignature-` }
                let signatureField = await this.document.fieldCreate(signatureText + this.signeeId, PDFNet.Field.Type.e_signature)
                let signatureAnnotation = await PDFNet.WidgetAnnot.create(this.document, (await PDFNet.Rect.init(quad[0].p1x, quad[0].p1y - 50, quad[0].p1x + 200, quad[0].p1y)), signatureField)
                let page = await this.document.getPage(searched.page_num)
                await page.annotPushBack(signatureAnnotation)
              }
              else {    // On Current Amend Signature
                console.log('Push Widget')
                let signatureField = await this.document.fieldCreate('signature-' + this.signeeId, PDFNet.Field.Type.e_signature)
                let signatureAnnotation = await PDFNet.WidgetAnnot.create(this.document, (await PDFNet.Rect.init(quad[0].p1x, quad[0].p1y - 50, quad[0].p1x + 200, quad[0].p1y)), signatureField)
                let page = await this.document.getPage(searched.page_num)
                await page.annotPushBack(signatureAnnotation)
              }
              return highlights.next()
            }).then(() => {
              return resolve(search.parseHighlight(highlights, searched, search, isSignature, personText))
            })
          } else {
            return resolve();
          }
        })
      })
    }
  }
}
