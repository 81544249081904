import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HelperService } from '../helper.service';
import * as moment from 'moment'
import { Subject } from 'rxjs';

class AmendData {
  details: string;
  change: any;
  reason: string;
}

@Injectable({
  providedIn: 'root'
})
export class AmendmentChecksService {

  constructor(
    public helpers: HelperService, 
    public api: ApiService
  ) { 

  }

  checkChanges = async (listingId, authorityDetails) : Promise<AmendData[]> => {
    // Get the current listing in Mongo
    // We have the current listing with the changes
    // Check all values within the current listing and the Mongo listing
    // All changes push to an array with the field thats been changed, and the wording.
    // Save these in the listing to Mongo in amendments array under listing.


    return new Promise(async resolve => {
      // Update Form values to listing.selected
      let l = authorityDetails

      let { maintenance, data }: any = await this.api.get('listing/find', { id: listingId })
      let ol = data.listing

      console.group('Amendment Changes')
      console.log(l)
      console.log(ol)

      let changes = []

      // Authority Rebates Form
      /* 
          rebatesEntitled : Bool
          commisionSharing : Bool
          commisionSharingType : null <depricated>
          rebates : array
          commisions : array
      */
      if (l.rebatesEntitled !== ol.rebatesEntitled) {
        console.log('rebatesEntitled')
        console.log(l.rebatesEntitled)
        console.log(ol.rebatesEntitled)
        let vChange: AmendData = {
          details: 'Rebates Entitled',
          change: l.rebatesEntitled,
          reason: ""
        }
        changes.push(vChange)
      }
      if (l.commisionSharing !== ol.commisionSharing) {
        console.log('commisionSharing')
        console.log(l.commisionSharing)
        console.log(ol.commisionSharing)
        let vChange: AmendData = {
          details: 'Commision Sharing',
          change: l.commisionSharing,
          reason: ""
        }
        changes.push(vChange)
      }
      if (l.commisionSharingType !== ol.commisionSharingType) {
        console.log('commisionSharingType')
        console.log(l.commisionSharingType)
        console.log(ol.commisionSharingType)
        let vChange: AmendData = {
          details: 'Commision Sharing Type',
          change: l.commisionSharingType,
          reason: ""
        }
        changes.push(vChange)
      }
      if (JSON.stringify(l.rebates) != JSON.stringify(ol.rebates)) {
        console.log('rebates')
        console.log(l.rebates)
        console.log(ol.rebates)
        let vChange: AmendData = {
          details: 'Rebates',
          change: l.rebates,
          reason: ""
        }
        changes.push(vChange)
      }
      if (JSON.stringify(l.commisions) != JSON.stringify(ol.commisions)) {
        console.log('commisions')
        console.log(l.commisions)
        console.log(ol.commisions)
        let vChange: AmendData = {
          details: 'Commissions Recipients',
          change: l.commisions,
          reason: ""
        }
        changes.push(vChange)
      }

      // Authority Terms Form
      /*
      auction
      auctionTimeTBA
      auctionDate
      auctionTime
      exclusivePeriod
      exclusivePeriodStandard
      exclusivePeriodEnds
      continuingPeriod
      continuingPeriodEnds
      propertyVacancy
      paymentPrice
      paymentPriceTBA
      reservePrice
      paymentDays
      paymentsDaysTBA
      paymentDaysValues
      agentEstimate : Form
      commision : Form
      marketing : Form
      */
      // if (l.auction !== ol.auction) {
      //   console.log('auction')
      //   console.log(l.auction)
      //   console.log(ol.auction)
      //   let vChange: AmendData = {
      //     details: 'Agreement Type',
      //     change: l.auction ? 'Auction Sale Agreement' : 'Exclusive Sale Agreement',
      //     reason: ""
      //   }
      //   changes.push(vChange)
      // }

      // if (l.saleType !== ol.auction) {
      //   console.log('auction')
      //   console.log(l.auction)
      //   console.log(ol.auction)
      //   let vChange: AmendData = {
      //     details: 'Agreement Type',
      //     change: l.auction ? 'Auction Sale Agreement' : 'Exclusive Sale Agreement',
      //     reason: ""
      //   }
      //   changes.push(vChange)
      // }

      if (l.auctionTimeTBA !== ol.auctionTimeTBA) {
        console.log('auctionTimeTBA')
        console.log(l.auctionTimeTBA)
        console.log(ol.auctionTimeTBA)
        if (l.auctionTimeTBA) {
          let vChange: AmendData = {
            details: 'Auction Time TBA',
            change: 'Auction Time TBA',
            reason: ""
          }
          changes.push(vChange)
        }
      }


      console.log(l.auctionDate)
      console.log(ol.auctionDate)
      ol.auctionDate = new Date(ol.auctionDate)
      l.auctionDate = this.helpers.revertDate(l.auctionDate)
      // console.log('Auction Date')
      // // console.log(this.helpers.formatDate(l.auctionDate))
      if (l.auctionDate) {
        if ((l.auctionDate && l.auctionDate.getTime()) !== (ol.auctionDate && ol.auctionDate.getTime())) {
          console.log('auctionDate')
          console.log(l.auctionDate)
          console.log(ol.auctionDate)
          let vChange: AmendData = {
            details: 'Auction Date',
            change: this.helpers.formatDate(l.auctionDate.toString()),
            reason: ""
          }
          changes.push(vChange)
        }
      }
      // if (l.auctionTime !== ol.auctionTime) {
      //   console.log('auctionTime')
      //   console.log(l.auctionTime)
      //   console.log(ol.auctionTime)
      //   if (l.auctionTime) {
      //     let vChange: AmendData = {
      //       details: 'Auction Time',
      //       change: l.auctionTime,
      //       reason: ""
      //     }
      //     changes.push(vChange)
      //   }
      // }



      if (l.exclusivePeriod !== ol.exclusivePeriod) {
        console.log('exclusivePeriod')
        console.log(l.exclusivePeriod)
        console.log(ol.exclusivePeriod)
        // let date: Date = moment(new Date()).add(l.exclusivePeriod, 'days').toDate()
        // console.log(date.)
        let vChange: AmendData = {
          details: 'Exclusive Period Ends',
          change: this.helpers.formatDate(moment(new Date()).add(l.exclusivePeriod, 'days').toString()),
          reason: ""
        }
        changes.push(vChange)
      }
      // if (l.exclusivePeriodStandard !== ol.exclusivePeriodStandard) { 
      //     console.log('exclusivePeriodStandard')
      //     console.log(l.exclusivePeriodStandard)
      //     console.log(ol.exclusivePeriodStandard)
      //     let vChange : AmendData = {
      //         details: 'Exclusive Period Standard',
      //         change: l.exclusivePeriodStandard
      //     }
      //     changes.push(vChange)
      // }
      // if (l.exclusivePeriodEnds !== ol.exclusivePeriodEnds) { 
      //     console.log('exclusivePeriodEnds')
      //     console.log(l.exclusivePeriodEnds)
      //     console.log(ol.exclusivePeriodEnds)
      //     let vChange : AmendData = {
      //         details: 'Exclusive Period Ends',
      //         change: l.exclusivePeriodEnds,
      //         reason: ""
      //     }
      //     changes.push(vChange)
      // }

      if (l.exclusivePeriod !== ol.exclusivePeriod || l.continuingPeriod !== ol.continuingPeriod) {
        console.log('continuingPeriod')
        console.log(l.continuingPeriod)
        console.log(ol.continuingPeriod)
        let vChange: AmendData = {
          details: 'Continuing Period Ends',
          change: this.helpers.formatDate(moment(new Date()).add((l.exclusivePeriod + l.continuingPeriod), 'days').toString()),
          reason: ''
        }
        changes.push(vChange)
      }
      // if (l.continuingPeriodEnds !== ol.continuingPeriodEnds) { 
      //     console.log('continuingPeriodEnds')
      //     console.log(l.continuingPeriodEnds)
      //     console.log(ol.continuingPeriodEnds)
      //     let vChange : AmendData = {
      //         details: 'Continuing Period Ends',
      //         change: l.continuingPeriodEnds,
      //         reason: ""
      //     }
      //     changes.push(vChange)
      // }

      if (l.propertyVacancy !== ol.propertyVacancy) {
        console.log('propertyVacancy')
        console.log(l.propertyVacancy)
        console.log(ol.propertyVacancy)
        let vChange: AmendData = {
          details: 'Property Vacancy',
          change: l.propertyVacancy,
          reason: ""
        }
        changes.push(vChange)
      }

      if (l.paymentPrice) { l.paymentPrice = l.paymentPrice.toString().replace(/\,|\$/g, '') }
      if (l.paymentPrice != ol.paymentPrice) {
        console.log('paymentPrice')
        console.log(l.paymentPrice)
        console.log(ol.paymentPrice)
        if (!l.paypaymentPriceTBA) {
          let vChange: AmendData = {
            details: 'Vendor Payment Price',
            change: `${this.helpers.money(l.paymentPrice)}`,
            reason: ""
          }
          changes.push(vChange)
        }
      }
      if (l.paymentPriceTBA !== ol.paymentPriceTBA) {
        console.log('paymentPriceTBA')
        console.log(l.paymentPriceTBA)
        console.log(ol.paymentPriceTBA)
        if (l.paymentPriceTBA) {
          let vChange: AmendData = {
            details: 'Vendor Payment Price',
            change: 'Vendor Payment Price TBA',
            reason: ""
          }
          changes.push(vChange)
        }
      }

      if (l.reservePrice !== ol.reservePrice) {
        console.log('reservePrice')
        console.log(l.reservePrice)
        console.log(ol.reservePrice)
        let vChange: AmendData = {
          details: 'Vendor Reserve Price',
          change: `${this.helpers.money(l.reservePrice)}`,
          reason: ""
        }
        changes.push(vChange)
      }
      if (JSON.stringify(l.paymentDays) !== JSON.stringify(ol.paymentDays) || l.paymentDaysValues !== ol.paymentDaysValues) {
        console.log('paymentDays')
        console.log(l.paymentDays)
        console.log(ol.paymentDays)
        let change = ''
        if (l.paymentDaysTBA) { change = 'Payments Days TBA' }
        else {
          if (l.paymentDays.indexOf('other') !== -1) {
            let days = l.paymentDays.filter(d => d !== 'other')
            change = days.join(', ') + `, ${l.paymentDaysValues}`
          } else {
            change = l.paymentDays.join(', ')
          }
        }

        let vChange: AmendData = {
          details: 'Payment Days',
          change: change,
          reason: ""
        }
        changes.push(vChange)
      }
      // if (l.paymentsDaysTBA !== ol.paymentsDaysTBA) { 
      //     console.log('paymentsDaysTBA')
      //     console.log(l.paymentsDaysTBA)
      //     console.log(ol.paymentsDaysTBA)
      //     let vChange : AmendData = {
      //         details: 'Payments Days TBA',
      //         change: l.paymentDaysTBA ? 'Payment days yet to be set' : 'Payment days set'
      //     }
      //     changes.push(vChange)
      // }
      // if (JSON.stringify(l.paymentDaysValues) !== JSON.stringify(ol.paymentDaysValues)) { 
      //     console.log('paymentDaysValues')
      //     console.log(l.paymentDaysValues)
      //     console.log(ol.paymentDaysValues)
      //     let vChange : AmendData = {
      //         details: 'Payment Days',
      //         change: l.paymentDaysValues
      //     }
      //     changes.push(vChange)
      // }

      // Marketing Form
      /*
          advertising
          other
          total
          payable
      */

      if (l.marketing.advertising) { l.marketing.advertising = this.helpers.money(l.marketing.advertising) }
      if (ol.marketing.advertising) { ol.marketing.advertising = this.helpers.money(ol.marketing.advertising) }
      console.log(l)
      console.log()
      console.log(l.marketing.advertising)
      console.log(ol.marketing.advertising)
      if (l.marketing.advertising !== ol.marketing.advertising) {
        console.log('marketing.advertising')
        console.log(l.marketing.advertising)
        console.log(ol.marketing.advertising)
        let vChange: AmendData = {
          details: 'Marketing Advertising Costs',
          change: `${this.helpers.money(l.marketing.advertising)}`,
          reason: ""
        }
        changes.push(vChange)
      }

      if (l.marketing.other) { l.marketing.other = this.helpers.money(l.marketing.other) }
      if (ol.marketing.other) { ol.marketing.other = this.helpers.money(ol.marketing.other) }
      if (l.marketing.other !== ol.marketing.other) {
        console.log('marketing.other')
        console.log(l.marketing.other)
        console.log(ol.marketing.other)
        let vChange: AmendData = {
          details: 'Marketing Other Costs',
          change: `${this.helpers.money(l.marketing.other)}`,
          reason: ""
        }
        changes.push(vChange)
      }

      if (l.marketing.total) { l.marketing.total = this.helpers.money(l.marketing.total) }
      else { l.marketing.total = 0 }
      if (ol.marketing.total) { ol.marketing.total = this.helpers.money(ol.marketing.total) }
      else { ol.marketing.total = 0 }
      console.log(l.marketing.total)
      console.log(ol.marketing.total)
      if (l.marketing.total !== ol.marketing.total) {
        console.log('marketing.total')
        console.log(l.marketing.total)
        console.log(ol.marketing.total)
        let vChange: AmendData = {
          details: 'Marketing Total Cost',
          change: `${this.helpers.money(l.marketing.total)}`,
          reason: ""
        }
        changes.push(vChange)
      }
      if (l.marketing.payable !== ol.marketing.payable) {
        console.log('marketing.payable')
        console.log(l.marketing.payable)
        console.log(ol.marketing.payable)
        let vChange: AmendData = {
          details: 'Marketing Cost Payable',
          change: `${this.helpers.money(l.marketing.payable)}`,
          reason: ""
        }
        changes.push(vChange)
      }

      // Agent Estimate Form
      /*
      type
      price
      priceMinimum
      priceMaximum
      priceMaximumPercentage
      */
      // if (l.agentEstimate.type !== ol.agentEstimate.type) { 
      //     console.log('agentEstimate.type')
      //     console.log(l.agentEstimate.type)
      //     console.log(ol.agentEstimate.type)
      //     let vChange : AmendData = {
      //         details: 'Agent Estimate Type',
      //         change: l.agentEstimate.type,
      //         reason: ""
      //     }
      //     changes.push(vChange)
      // }
      if (l.agentEstimate.type == 'single') {
        if (l.agentEstimate.price) { l.agentEstimate.price = this.helpers.money(l.agentEstimate.price) }
        if (ol.agentEstimate.price) { ol.agentEstimate.price = this.helpers.money(ol.agentEstimate.price) }
        if (l.agentEstimate.price !== ol.agentEstimate.price) {
          console.log('agentEstimate.price')
          console.log(l.agentEstimate.price)
          console.log(ol.agentEstimate.price)
          let vChange: AmendData = {
            details: 'Agent Estimate Price',
            change: `${this.helpers.money(l.agentEstimate.price)}`,
            reason: l.agentEstimate.amendAgentEstimateReason == "Other" ? l.agentEstimate.amendAgentEstimateReasonOther : l.agentEstimate.amendAgentEstimateReason
          }
          changes.push(vChange)
        }
      } else {
        console.log(l.agentEstimate.priceMinimum)
        console.log(l.agentEstimate.priceMaximum)
        if (l.agentEstimate.priceMinimum) { l.agentEstimate.priceMinimum = l.agentEstimate.priceMinimum.toString().replace(/\,|\$/g, '') }
        if (l.agentEstimate.priceMaximum) { l.agentEstimate.priceMaximum = l.agentEstimate.priceMaximum.toString().replace(/\,|\$/g, '') }
        if ((l.agentEstimate.priceMinimum != ol.agentEstimate.priceMinimum) || (l.agentEstimate.priceMaximum != ol.agentEstimate.priceMaximum)) {
          console.log('agentEstimate.priceMinimum')
          console.log(l.agentEstimate.priceMinimum)
          console.log(ol.agentEstimate.priceMinimum)
          let vChange: AmendData = {
            details: 'Agent Estimate Price',
            change: `Range ${this.helpers.money(l.agentEstimate.priceMinimum)} and ${this.helpers.money(l.agentEstimate.priceMaximum)}`,
            reason: l.agentEstimate.amendAgentEstimateReason == "Other" ? l.agentEstimate.amendAgentEstimateReasonOther : l.agentEstimate.amendAgentEstimateReason
          }
          changes.push(vChange)
        }
      }

      // if (l.agentEstimate.priceMaximumPercentage !== ol.agentEstimate.priceMaximumPercentage) { 
      //     console.log('agentEstimate.priceMaximumPercentage')
      //     console.log(l.agentEstimate.priceMaximumPercentage)
      //     console.log(ol.agentEstimate.priceMaximumPercentage)
      //     let vChange : AmendData = {
      //         details: 'Agent Estimate Price Range Percentage',
      //         change: `${l.agentEstimate.priceMaximumPercentage}%`,
      //         reason: ""
      //     }
      //     changes.push(vChange)
      // }

      // Commision Form
      /*
          type
          fixed
          percentage
          gstType
          commisionText
          performance
          performanceType
          performanceTrigger
          performanceExample
          performanceFixed
          performancePercentage
          performanceAmount
          estimatedTrigger
          estimatedBaseTrigger
          estimatedVendor
          estimated
          estimatedMinimum
          estimatedMaximum
          estimatedGST
      */
      if ((l.commision.type !== ol.commision.type) ||
        (l.commision.fixed !== ol.commision.fixed) ||
        (l.commision.percentage !== ol.commision.percentage) ||
        (l.commision.gstType !== ol.commision.gstType) ||
        (l.commision.commisionText !== ol.commision.commisionText) ||
        (l.commision.performance !== ol.commision.performance) ||
        (l.commision.performanceType !== ol.commision.performanceType) ||
        (l.commision.performanceTrigger !== ol.commision.performanceTrigger) ||
        (l.commision.performanceExample !== ol.commision.performanceExample) ||
        (l.commision.performanceFixed !== ol.commision.performanceFixed) ||
        (l.commision.performancePercentage !== ol.commision.performancePercentage) ||
        (l.commision.performanceAmount !== ol.commision.performanceAmount) ||
        (l.commision.estimatedTrigger !== ol.commision.estimatedTrigger) ||
        (l.commision.estimatedBaseTrigger !== ol.commision.estimatedBaseTrigger) ||
        (l.commision.estimatedVendor !== ol.commision.estimatedVendor) ||
        (l.commision.estimated !== ol.commision.estimated) ||
        (l.commision.estimatedMinimum !== ol.commision.estimatedMinimum) ||
        (l.commision.estimatedMaximum !== ol.commision.estimatedMaximum) ||
        (l.commision.estimatedGST !== ol.commision.estimatedGST)) {
        console.log('commision')
        console.log(l.commision)
        console.log(ol.commision)
        let vChange: AmendData = {
          details: 'Commission',
          change: true,
          reason: ""
        }
        changes.push(vChange)
      }

      console.log('AUTHORITY CHANGES!!!')
      console.log(changes)

      console.groupEnd()
      return resolve(changes)
    })
  }
}
