import 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { version } from '../../../package.json'
import { environment } from '../../environments/environment';

@Injectable()
export class EnvironmentService {
  current = () => { return this[this.name] }
  name = 'dev' //dev, test, production
  version = version
  pdftronKey = 'Riven Pty Ltd(agentpower.com.au):OEM:AgentPower::B+:AMS(20220130):B1A581620477E80A7360B13AC9A2737860611FD5D950FD45E5149484CD2289D654BA31F5C7'
  leavingAlert = false
  localApi = true
  signin = { 
    enabled: true,
    user: 'luke@bento.solutions',
    password: 'Helloworld!1'
  }
  production = {
    yworld: 'https://agentpower.com.au'
  }
  test = {
    yworld: 'https://agentpowertest.yworld.com.au'
  }
  dev = {
    yworld: 'https://agentpowertest.yworld.com.au'
  }

  constructor() {
    if (environment.name != 'dev') {
      this.name = environment.name
      this.localApi = false
      this.signin.enabled = false
      this.leavingAlert = true
    }
  }

}
