import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService as AuthGuard  } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'auth',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'properties',
    loadChildren: () => import('./properties/properties.module').then(m => m.PropertiesPageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthPageModule)
  // },
  {
    path: 'whats-new',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/whats-new/whats-new.module').then(m => m.WhatsNewPageModule)
  },
  {
    path: 'feedback',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/feedback/feedback.module').then(m => m.FeedbackPageModule)
  },
  {
    path: 'contact',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
  },
  {
    path: 'property-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/property-list/property-list.module').then(m => m.PropertyListPageModule)
  },
  {
    path: 'property-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/property-edit/property-edit.module').then(m => m.PropertyEditPageModule)
  },
  {
    path: 'dates',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dates/dates.module').then(m => m.DatesPageModule)
  },
  {
    path: 'agency-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/agency-list/agency-list.module').then(m => m.AgencyListPageModule)
  },
  {
    path: 'agency-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/agency-edit/agency-edit.module').then(m => m.AgencyEditPageModule)
  },
  {
    path: 'generate-authority',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/generate-authority/generate-authority.module').then(m => m.GenerateAuthorityPageModule)
  },
  {
    path: 'listing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/listing/listing.module').then(m => m.ListingPageModule)
  },
  {
    path: 'sign/:type/:id',
    loadChildren: () => import('./pages/remote-sign/remote-sign.module').then(m => m.RemoteSignPageModule)
  },
  {
    path: 'sign/:type/:offerId/:id',
    loadChildren: () => import('./pages/remote-sign/remote-sign.module').then(m => m.RemoteSignPageModule)
  },
  {
    path: 'view-pdf',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/view-pdf/view-pdf.module').then(m => m.ViewPdfPageModule)
  },
  {
    path: 'dates',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dates/dates.module').then(m => m.DatesPageModule)
  },
  {
    path: 'agency-list',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/agency-list/agency-list.module').then(m => m.AgencyListPageModule)
  },
  {
    path: 'agency-edit',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/agency-edit/agency-edit.module').then(m => m.AgencyEditPageModule)
  },
  {
    path: 'sign-contract',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/sign-contract/sign-contract.module').then(m => m.SignContractPageModule)
  },
  {
    path: 'contract-builder',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contract-builder/contract-builder.module').then( m => m.ContractBuilderPageModule)
  },
  {
    path: 'pdftron-test',
    loadChildren: () => import('./pages/pdftron-test/pdftron-test.module').then( m => m.PdftronTestPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules
    }),
    FormsModule, ReactiveFormsModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
