import { Injectable, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppSessionService } from '../app-session.service';
import { ApiService } from '../api.service';


@Injectable({
  providedIn: 'root'
})
export class SpecialConditionService {

  constructor(
    private app: AppSessionService, 
    private api: ApiService, 
    private builder: FormBuilder,
    private zone: NgZone,
  ) {
    this.form.detail = this.build.detail()
  }

  build = {
    detail: () => {
      return this.builder.group({
        text: ['']
      })
    }
  }

  form = {
    detail: null,
    import: (formArray, specialConditions) => {
      while (formArray.length !== 0) formArray.removeAt(0)
      console.log('specialConditions')
      console.log(specialConditions)
      if (specialConditions) {
        for (let specialCondition of specialConditions) {
          console.log('specialCondition')
          console.log(specialCondition)
          let form = this.build.detail()
          form.patchValue(specialCondition)
          formArray.push(form)
        }
      }
    },
    add: (formArray) => {
      this.zone.run(() => {
        this.form.detail = this.build.detail()
        formArray.push(this.form.detail)
      })
    },
    remove: (formArray, index) => {
      formArray.removeAt(index)
    },
    reset: () => {
      this.form.detail = this.build.detail();
    }
  }
}
