import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AppSessionService } from '../app-session.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyRepresentativeService {

  constructor(
    private app: AppSessionService, 
    private api: ApiService, 
    private builder: FormBuilder
  ) {
  
  }

  build = {
    detail: () => {
      let form = this.builder.group({
        firstName: ['', Validators.compose([Validators.required])],
        surname: ['', Validators.compose([Validators.required])],
        capacity: ['Director'],
        email: [''],
        phone: [''],
        mobile: ['']
      })
      this.form.subscriptions(form)
      return form
    }
  }

  form = {
    parent: null,
    detail: null,
    import: (formArray, companyRepresentatives) => {
      while (formArray.length !== 0) formArray.removeAt(0)
      if (companyRepresentatives) {
        for (let companyRepresentative of companyRepresentatives) {
          let form = this.build.detail()
          form.patchValue(companyRepresentative)
          formArray.push(form)
        }
      }
    },
    add: (formArray) => {
      this.form.detail = this.build.detail()
      formArray.push(this.form.detail)
    },
    remove: (formArray, index) => {
      formArray.removeAt(index)
    },
    subscriptions: (form) => {
      form.controls.firstName.valueChanges.subscribe((firstName) => {
        console.log(firstName)
        if (this.form.parent) {
          this.form.parent.controls.companyRepresentatives.controls[0].controls.firstName.patchValue(firstName, { emitEvent: false })
          this.form.parent.controls.capacity.updateValueAndValidity()
        }
      })
      form.controls.surname.valueChanges.subscribe((surname) => {
        if (this.form.parent) {
          this.form.parent.controls.companyRepresentatives.controls[0].controls.surname.patchValue(surname, { emitEvent: false })
          this.form.parent.controls.capacity.updateValueAndValidity()
        }
      })
      form.controls.mobile.valueChanges.subscribe((mobile: string) => {
        if (form.controls.mobile.valid && form.controls.mobile.value !== '' && form.controls.phone.invalid) {
          form.controls.phone.clearValidators()
          form.controls.phone.updateValueAndValidity()
        }
        else if (form.controls.mobile.invalid && form.controls.phone.invalid) {
          form.controls.phone.setValidators([Validators.required])
        }
      })
      form.controls.phone.valueChanges.subscribe((phone: string) => {
        if (form.controls.phone.valid && form.controls.phone.value !== '' && form.controls.mobile.invalid) {
          form.controls.mobile.clearValidators()
          form.controls.mobile.updateValueAndValidity()
        }
        else if (form.controls.phone.invalid && form.controls.mobile.invalid) {
          form.controls.mobile.setValidators([Validators.required])
        }
      })
    }
  }

}
