import { Injectable } from '@angular/core';
import { FormService } from "./form.service";
import { PurchaserService } from '../purchaser/purchaser.service';
import { DataService } from '../offer/data.service';
import { HelperService } from '../helper.service';
import { AppSessionService } from '../app-session.service';
import { ApiService } from '../api.service';
import { PdfService } from '../pdf.service';
import { PermissionService } from '../permission.service'
@Injectable({
  providedIn: 'root'
})
export class OfferService {

  constructor(public permission:PermissionService, public data: DataService, public helper: HelperService, private app: AppSessionService, private api: ApiService, public form: FormService, private purchasers: PurchaserService, private pdf: PdfService) {
  }

  reset = () => {
    this.form.detail.reset({ emitEvent: false })
    this.form.contract.reset({ emitEvent: false })
  }

  add = async (listingId) => {
    // return new Promise(resolve => {
    this.form.add()
    this.form.detail.controls.pending.patchValue(true)
    this.form.detail.controls.listingId.patchValue(listingId)
    this.form.detail.controls.offerCreated.patchValue(new Date())
    this.form.detail.controls.offerCreatedBy.patchValue({ _id: this.app.user._id, name: this.app.user.name })
    this.form.detail.controls.PDFNames.patchValue([listingId])
    //   resolve()
    // })
  }

  save = async (): Promise<void> => {
    return new Promise(async resolve => {
      this.form.detail.controls.pending.patchValue(false)
      let offer = this.form.detail.value
      console.log('offer save')
      console.log(offer)
      offer.contract = this.form.contract.value
      console.log(offer)
      offer.purchasers = offer.purchasers.filter(p => !p.pending)
      this.data.massage.offer.save(offer)
      let offerId = await this.api.post('listing/offer/save', offer)
      if (!this.form.detail.value._id) {
        this.form.detail.controls._id.patchValue(offerId)
      }
      return resolve()
    })
  }

  // Permissions are not required for Remote Signing
  find = (offerId, permissions: boolean = true) => {
    this.form.detail = this.form.list.value.find(o => o._id === offerId)
    if (permissions) this.permission.initialise.offer(this.form.detail.value)
  }

  // Permissions are not required for Remote Signing
  findViaId = (offerId, permissions: boolean = true) => {
    return new Promise(async resolve => {
      let offer = await this.api.get('offer/find', { offerId })
      if (permissions) this.permission.initialise.offer(offer)
      resolve(offer)
    })
  }

  // Permissions are not required for Remote Signing
  findViaPurchaser = (purchaserId, permissions: boolean = true) => {
    return new Promise(async resolve => {
      let offer = await this.api.get('offer/find', { purchaserId: purchaserId })
      if (permissions) this.permission.initialise.offer(offer)
      resolve(offer)
    })
  }

  load = (listing) => {
    if (listing.offers) {
      this.form.import(listing.offers)
    } else {
      this.form.list = null
    }
  }

  withdraw = async () => {
    this.form.detail.controls.status.patchValue('Withdrawn')
    // await this.save()
    return Promise.resolve()
  }

  isLocked = () => { return this.form.detail.get('status').value !== 'Prospective' }

  contractEnded = async (offerId, listingId, reason) => {
    await this.api.post('offer/contract/ended', { offerId: offerId, listingId: listingId, reason: reason })
  }

  annotation = {
    export: async (offerId, annotationLog, signatureCount) => {
      return new Promise(async resolve => {
        let { error, response }: any = await this.api.post(`offer/annotation/export`, { offerId: offerId, annotationLog: annotationLog, signatureCount })
        resolve({ error, response })
      })
    },
    import: async (offerId, pdfId) => {
      return new Promise(async resolve => {
        let response: any = await this.api.post(`offer/annotation/import`, { offerId: offerId, pdfId: pdfId })
        resolve({ error: null, response: response ? response[response.length - 1] : null })
      })
    },
    reset: (offerId) => {
      return new Promise(async resolve => {
        let { error, ok }: any = await this.api.post('offer/annotation/reset', { offerId })
        resolve({ error, response: ok })
      })
    }
  }

  signature = {
    count: async (offerId, entityId, PDFNames): Promise<{ error, response }> => {
      return new Promise(async resolve => {
        let annots: any = await this.api.get(`offer/signature/annots`, { id: offerId })
        let count = 0

        console.group('Signature Count Offer')
        console.log(annots)
        console.log(PDFNames)

        if (annots && annots[PDFNames[PDFNames.length - 1]]) {
          let annotList = annots[PDFNames[PDFNames.length - 1]]
          console.log(annotList)
          console.log(entityId)
          if (entityId) {
            annotList = annotList.filter(a => a.userId === entityId)
          }
          if (annotList) {
            count = annotList.length
          }
        }
        console.groupEnd()
        resolve({ error: null, response: count })
      })
    },
    total: async (instance): Promise<{ error, response }> => {
      return new Promise(async resolve => {
        // Get List
        let Annotations = instance.Annotations
        let annotationList: any = await instance.annotManager.getAnnotationsList()
        let count = 0

        // Find how many widgets are for signatures
        for (let annot of annotationList) {
          if (annot instanceof Annotations.SignatureWidgetAnnotation && annot.fieldName.split('-')[0] == "offerssignature" && !annot.Hidden) {
            console.log("Add Count")
            count++
          }
        }

        resolve({ error: null, response: count })
      })
    },

    removeAll: async (offerId) => {
      await this.api.post('offer/remove/signatures', { offerId: offerId })
    },

    save: async (instance, offerId, PDFNames, annotation) : Promise<{ error:any, response:any }> => {
      return new Promise(async resolve => {
        let version = PDFNames[PDFNames.length - 1]
        let res = await this.pdf.offer.annotations.save(instance, offerId, version, annotation)
        resolve({ error: null, response: res })
      })
    }
  }

  document = {
    email: {
      remoteSign: async (listingId, offerId, id, type) => {
        await this.api.get('listing/signature/remoteSign', { listingId: listingId, offerId: offerId, type: type, document: 'offer', id: id })
      },
      complete: async (listingId, offerId, pdfName) => {
        await this.api.post('offer/signatures/complete/email', { listingId, offerId, pdfName })
      }
    },
    newState: (offerId, status) => {
      return new Promise(async resolve => {
        let response = await this.api.post('offer/status', { offerId: offerId, status: status })
        this.form.detail.get('status').patchValue(status)
        resolve({ error: null, response: response })
      })
    },
    pushPdfName: async (offerId, pdfname) => {
      await this.api.post('offer/pdfname/push', { offerId: offerId, pdfName: pdfname })
    },
    popPdfName: async (offerId) => {
      await this.api.post('offer/pdfname/pop', { offerId: offerId })
    },
    setBuilderState: async (offerId: string, builderState: boolean) => {
      await this.api.post('offer/builder', { offerId: offerId, state: builderState })
    },
  }


  pdftron = {

    /*
      options = {
        vendors: vendor List
        purchasers: purchaser list
      }
    */
    addSigningWidgets: async (instance, document, options) : Promise<void> => {
      return new Promise(async resolve => {
        console.log('Add offer Signing Widgets')
        for (let vendor of options.vendors) {
          await this.pdf.createSignatures(instance, document, vendor.id, 'offers')
        }
        for (let purchaser of options.purchasers) {
          await this.pdf.createSignatures(instance, document, purchaser.id, 'offers')
        }

        await this.pdf.document.refreshFieldAppearances()
        this.pdf.initialiseSignature()
        return resolve()
      })
    }
  }

}
