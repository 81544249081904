import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';
import { HelperService } from '../helper.service';
import { PurchaserService } from '../purchaser/purchaser.service';
import { DataService } from '../offer/data.service';
import { SpecialConditionService } from '../special-condition/special-condition'
import { ConveyancerService } from '../conveyancer/conveyancer.service'
import * as numeral from 'numeral'

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public list: FormArray
  public detail: FormGroup
  public contract: FormGroup
  public acceptedOffer: any

  constructor(public conveyancer: ConveyancerService, public specialCondition: SpecialConditionService, public data: DataService, public purchaser: PurchaserService, private builder: FormBuilder, private helper: HelperService) {
    this.contract = this.build.contract()
    this.detail = this.build.detail(this.contract)
  }

  build = {
    contract: () => {
      return this.builder.group({
        //old terms
        subjectToLease: [false],
        subjectToLeaseDetail: ['See attached Lease'],
        gst: [false],
        goodsSold: [],
        farmingBusiness: [false],
        goingConcern: [false],
        marginScheme: [false],
        buildingInspection: [false],
        buildingInspectionWrittenReportDate: [],
        buildingInspectionReportDate: [],
        pestInspection: [false],
        pestInspectionWrittenReportDate: [],
        pestInspectionReportDate: [],
        subjectToSale: [false],
        subjectToSaleAddress: [''],
        subjectToSaleContractDate: [],
        subjectToSaleUnconditionalDate: [],
        subjectToSaleWithdrawDate: [],
        conditions: this.builder.array([]),
        //old payment
        contractAgentId: [null],
        price: ['', Validators.compose([Validators.required])],
        payingDeposit: [false],
        phone: [''],
        initialDeposit: [''],
        deposit: ['', Validators.compose([Validators.required])],
        depositDate: [null, Validators.compose([Validators.required])],
        depositPaid: [''],
        balance: ['', Validators.compose([Validators.required])],
        settlementDate: [null, Validators.compose([Validators.required])],
        subjectToFinance: [false],
        lender: [''],
        loanAmount: [''],
        approvalDate: [null]
      })
    },
    detail: (contract) => {
      return this.builder.group({
        _id: [null],
        listingId: [''],
        status: ['Prospective'],
        url: [''],
        pending: [false],
        PDFNames: new FormControl([]),
        annotations: new FormControl({}),
        signatures: new FormControl({}),
        conveyancer: this.conveyancer.build.detail(),
        completeUrl: [''],
        offerCreated: [''],
        offerCreatedBy: [''],
        offerUpdated: [''],
        offerUpdatedBy: [''],
        offerAccepted: [''],
        offerAcceptedBy: [''],
        builder: [true],
        contract: contract,
        purchasers: this.builder.array([], Validators.compose([Validators.required, Validators.minLength(1)]))
      })
    },

    contractEnded: (): FormGroup => {
      return this.builder.group({
        reason: ['', Validators.compose([Validators.required])],
        reasonOther: ['']
      })
    },
  }

  import = (offers) => {
    if (offers) {
      this.subscriptions()
      this.acceptedOffer = null
      this.list = this.builder.array([])
      while (this.list.length !== 0) this.list.removeAt(0)
      for (let offer of offers) {
        this.data.massage.offer.load(offer)
        let contractForm = this.build.contract()
        contractForm.patchValue(offer.contract)
        this.specialCondition.form.import(contractForm.controls.conditions, offer.contract.conditions)
        console.log('contractForm')
        console.log(contractForm.value)
        let detailForm = this.build.detail(contractForm)
        detailForm.patchValue(offer)
        this.purchaser.form.import(detailForm.controls.purchasers, offer.purchasers)
        if (offer.conveyancer) detailForm.controls.conveyancer.patchValue(offer.conveyancer)

        if (offer.status === 'Accepted') {
          this.acceptedOffer = detailForm.value
        }
        this.list.push(detailForm)
      }
    }
  }
  add = () => {
    this.contract = this.build.contract()
    this.detail = this.build.detail(this.contract)
    this.list.push(this.detail)
  }
  remove = (index) => {
    this.list.removeAt(index)
  }

  public cleanup = () => {
    this.contract.reset({ emitEvent: false })
  }

  public subscriptions = () => {
    // this.detail.valueChanges.subscribe(_ => {
    //   console.log(this.detail)
    // })

    this.detail.controls.contract.get('farmingBusiness').valueChanges.subscribe((farmingBusiness: any) => {
      if (farmingBusiness == true)
        this.detail.controls.contract.get('goingConcern').patchValue(false)
    })
    this.detail.controls.contract.get('goingConcern').valueChanges.subscribe((goingConcern: any) => {
      if (goingConcern == true)
        this.detail.controls.contract.get('farmingBusiness').patchValue(false)
    })

    this.detail.controls.contract.get('subjectToFinance').valueChanges.subscribe((subjectToFinance: string) => {
      this.detail.controls.contract.get('lender').clearValidators()
      this.detail.controls.contract.get('loanAmount').clearValidators()
      this.detail.controls.contract.get('approvalDate').clearValidators()
      if (subjectToFinance) {
        this.detail.controls.contract.get('lender').setValidators([Validators.required])
        this.detail.controls.contract.get('loanAmount').setValidators([Validators.required])
        this.detail.controls.contract.get('approvalDate').setValidators([Validators.required])
      }
      this.detail.controls.contract.get('lender').updateValueAndValidity()
      this.detail.controls.contract.get('loanAmount').updateValueAndValidity()
      this.detail.controls.contract.get('approvalDate').updateValueAndValidity()
    })

    this.detail.controls.contract.get('price').valueChanges.subscribe(value => {
      this.detail.controls.contract.get('balance').patchValue(numeral(this.detail.controls.contract.get('price').value).value() - numeral(this.detail.controls.contract.get('deposit').value).value())
    })

    this.detail.controls.contract.get('deposit').valueChanges.subscribe((deposit: string) => {
      this.detail.controls.contract.get('balance').patchValue(numeral(this.detail.controls.contract.get('price').value).value() - numeral(this.detail.controls.contract.get('deposit').value).value())
    })

  }

}
