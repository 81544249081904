import { Injectable } from '@angular/core';
import { HelperService } from '../helper.service';
import { AppSessionService } from '../app-session.service'
import { AuthorityService } from '../authority/authority.service';
import { OfferService } from '../offer/offer.service';
import { VendorService } from '../vendor/vendor.service';
import { ApiService } from '../api.service';
import { FormService } from './form.service';
import { StorageService } from '../storage.service';
import { ContractService } from '../contract/contract.service';
import { PermissionService } from '../permission.service'
import * as uuid from 'uuid'
import { FormArray } from '@angular/forms';
import { PageService } from './page.service';
import { Subject } from 'rxjs';
import { NavigationService } from '../navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ListingService {
  public loaded = false
  public list: any[] = []

  public active = null
  activeListingChange: Subject<any> = new Subject<any>()

  public filter = {
    group: 'listings',
    prelistings: true,
    listings: true,
    underOffer: true,
    sold: true,
    settled: false,
    authorityExpired: false,
    withdrawn: false,
    skip: 0,
    limit: 15,
    search: null
  }
  public loadingComplete = false

  constructor(
    public permission: PermissionService,
    private authority: AuthorityService,
    public form: FormService,
    private storage: StorageService,
    private api: ApiService,
    private app: AppSessionService,
    private helper: HelperService,
    private offer: OfferService,
    private vendor: VendorService,
    private contract: ContractService,
    private page: PageService,
    private nav: NavigationService) {
    this.activeListingChange.subscribe(listing => {
      console.log(`Active listing Changing!`)
      this.active = listing
    })
  }

  private setActiveListing = (listing) => {
    this.activeListingChange.next(listing)
  }

  findDates = async (from, to, userId) => {
    let dates = await this.api.get('dates', { from, to, userId })
    return Promise.resolve(dates)
  }

  add = async () => {
    this.form.cleanup()
    this.authority.form.cleanup()
    this.contract.form.cleanup()
    this.form.detail.patchValue({
      agencyId: this.app.user.agencyId,
      agency: this.app.user.agency,
      agentId: this.app.user._id,
      status: 'Pre-Listing'
    });
    this.form.agentsList.addSingle(this.app.user)
    const defaults = { ...this.app.user.defaults };
    defaults.marketing.otherFees = [{ name: 'Admin Fee', fee: defaults.marketing.other }]
    if (!this.app.user.defaults.commision.performance) this.app.user.defaults.commision.performance = 'none'

    this.authority.load(this.app.user.defaults)

    let formArray = this.form.property.get('titles') as FormArray
    formArray.push(this.form.build.title())

    console.log('ADD LISTING BEFORE SAVE')
    console.log(this.form.detail.value)
    console.log(this.authority.form.detail.value)
    // await this.save() // Should this be here?
    // return =resolve() //this.form._id.controls._id.value
  }

  savePhotos = async () => {
    return await this.api.post('listing/photo/save/all', { id: this.form._id.controls._id.value, images: this.form.property.value.images })
  }

  setStatus = (listingId: string, status: string): Promise<void> => {
    return new Promise(async resolve => {
      await this.api.post('listing/status', { listingId, status })
      resolve()
    })
  }

  saveStatus = async (status, allowedUsers) => {
    return this.api.post('listing/save/status', { id: this.form._id.controls._id.value, status: status, allowedUsers: allowedUsers })
  }

  save = async () => {
    return new Promise(async resolve => {

      // Guard clause, we do not want the listing to save when in 'Amending' Mode
      if (this.page.authority.state === "amending") { return resolve({ error: 'Do not save while amending. ', response: null }) }

      let res: any = await this.authority.save()
      let l = res.response
      // console.log(this.form.detail.value)
      l = Object.assign(l, this.form.detail.value)
      l = Object.assign(l, this.form.property.value)

      for (let v of l.vendors) {
        if (v.birthDate) {
          if (v.birthDate.date) { v.birthDate = this.helper.revertDate(v.birthDate.date) }
        }
      }

      if (l.auctionDate) {
        l.auctionDate = this.helper.revertDate(l.auctionDate.date)
      }
      if (l.saleTypeDate) {
        l.saleTypeDate = this.helper.revertDate(l.saleTypeDate.date)
      }

      console.log('Listing Save')
      console.log(l)
      let response = await this.api.post('listing/save', { id: this.form._id.controls._id.value, listing: l, amend: this.authority.form.detail.get('authorityAmend').value, user: this.app.user.name })
      if (response === 'rejected') {
        return resolve({ error: "Property details are locked.", response: null })
      }
      else {
        this.form._id.controls._id.patchValue(response)
      }
      return resolve({ error: null, response: l })
    })
  }

  reset = () => {
    this.list = []
    this.filter.skip = 0
  }

  load = async () => {
    return new Promise(async resolve => {
      let listings: any = await this.api.post('listing/list', { userId: this.app.user._id, ...this.filter })
      this.loadingComplete = listings.length == 0
      await this.photo.load(listings)
      this.list.push(...listings)
      return resolve({ error: null, response: true })
    })
  }

  find = async (listingId, vendorId?, needPermissions = true) => {
    return new Promise(async resolve => {
      console.group('Listing Find')
      if (needPermissions) this.page.setState('menu')
      this.loaded = false
      // Remove existing data
      this.form.cleanup()
      this.offer.form.cleanup()
      this.authority.form.cleanup()
      this.contract.form.cleanup()

      // Options
      console.log('listingId: ' + listingId)
      let o = { id: undefined, vendorId: undefined }
      if (listingId) { o.id = listingId; delete o.vendorId }
      if (vendorId) { o.vendorId = vendorId; delete o.id }
      // console.log(o)

      // Find Listing
      let adminEmails = ['scott.goodman@goodmangroup.com.au', 'amy.hantler@goodmangroup.com.au', 'bento@bento.solutions', 'luke@bento.solutions']
      let { maintenance, data }: any = await this.api.get('listing/find', o)
      if (adminEmails.indexOf(this.app.user.email) == -1) {
        if (this.helper.isMaintenanceMode(maintenance)) { // Call UI and component to cover the full screen.
          this.nav.push('maintenance', { maintenance })
        }
      }
      let listing = data

      if (needPermissions) { this.permission.initialise.listing(listing) }
      this.setActiveListing(listing)
      if (listing) {
        // Add Subscriptions
        // await this.form.subscriptions()

        // Load services that are attached to listing
        this.offer.load(listing)

        this.form._id.patchValue(listing)
        console.log(listing.listing)

        if (listing.listing) {
          // Load services within the listing data
          this.authority.load(listing.listing)
          if (listing.authority) { this.authority.loadReadOnly(listing.authority) }
          else { this.authority.loadReadOnly({ status: 'blank' }) }

          this.form.detail.patchValue(listing.listing)
          if (listing.listing.vendorConveyancer) this.form.detail.controls.vendorConveyancer.patchValue(listing.listing.vendorConveyancer)
          if (listing.listing.purchaserConveyancer) this.form.detail.controls.purchaserConveyancer.patchValue(listing.listing.purchaserConveyancer)
          this.form.property.patchValue(listing.listing)
          this.form.property.get('goodsSold').patchValue(listing.listing.goodsSold)

          this.form.agentsList.import(listing.listing.agents)
          this.form.imagesList.import(listing.listing.images)
          this.form.floorplanList.import(listing.listing.floorplan)
          this.form.titles.import(listing.listing.titles);
          this.form.attachmentsList.import(listing.listing.attachments)
          await this.photo.load([listing])

        }

        if (listing.documents) {
          this.contract.load(listing.documents)
        } else {
          this.contract.form.subscriptions()
        }

        // console.log(listing.listing.vendors)
        // this.form.detail.controls.vendors = new FormArray([])
        if (listing.listing.vendors.length > 0) {
          // console.log(listing.listing.vendors)
          this.vendor.form.import(this.form.detail.controls.vendors, listing.listing.vendors)
        }
        console.log(this.form.detail.controls.vendors)

        // this.form.disableForms()
        // this.authority.form.disableForms()

        console.log('Load Listing')
        console.log(this.authority.form.terms.value)
        console.log(this.form.property.value)

        console.groupEnd()
        this.loaded = true
        resolve({ error: null, response: true })
      } else {
        this.loaded = true
        resolve({ error: null, response: false })
      }
    })
  }

  remove = async (listingId) => {
    // Find / Load listing
    let res = await this.find(listingId)

    // Change the status to withdrawn
    this.form.detail.controls.status.patchValue('Withdrawn')

    // Save Listing
    await this.save()

    // Reset listing form
    await this.form.cleanup()

    return Promise.resolve({ error: null, response: listingId })
  }

  /*
    Fully Deletes listing from database.
    Do not use, Use Remove instead....
    Params: 
      listingId
  */
  hardRemove = async (listingId) => {
    let res = await this.api.post('listing/delete', { listingId: listingId })
    return Promise.resolve({ error: null, response: res })
  }

  photo = {
    load: async (listings): Promise<void> => {
      return new Promise(async resolve => {
        for (let l of listings) {
          if (l.listing && l.listing.agent && l.listing.agent.image) {
            l.listing.agent.imageUrl = await this.app.url(l.listing.agent.image)
          }
          if (l.listing && l.listing.images) {
            for (let i of l.listing.images) {
              i.url = await this.app.url(i.name)
            }
          }
          if (l.listing && l.listing.agency && l.listing.agency.photo) {
            l.listing.agency.photo.url = await this.app.url(l.listing.agency.photo.name)
          }
          if (l.listing && l.listing.agents) {
            for (let a of l.listing.agents) {
              if (a.image && a.image.length > 0) a.imageUrl = await this.app.url(a.image)
            }
          }
        }
        console.log('Photo Load Finish')
        return resolve()
      })

    },
    upload: async (event) => {
      return new Promise((resolve, reject) => {
        Array.from(event.target.files).forEach((file: any) => {
          let reader = new FileReader()
          reader.onloadend = () => {
            let photo = {
              //id: UUID.UUID(),
              name: uuid.v4(),
              extension: '.jpg',
              uploadedBy: this.app.user.name,
              imageUrl: null
            }
            this.storage.save({ name: photo.name, data: reader.result, type: 'image/jpeg' })
              .then(async () => {
                let imgurl = await this.app.url(photo.name.toString())
                photo.imageUrl = imgurl
                await this.form.imagesList.addSingle({ name: photo.name, url: photo.imageUrl })

                resolve({ ok: true, photo })
              }).catch((error) => { console.log(error) })
          }
          reader.readAsArrayBuffer(file)
        })
      })
    },
    clear: async (imgname) => {

      await this.api.post('listing/photo/remove', { id: this.form._id.value, name: imgname })
      return Promise.resolve()
    }
  }


  /*
    Returns a boolean based on whether the 
    current listing has commission Sharing active.
  */
  hasCommissionSharing = (): boolean => this.authority.form.rebates.get('commisions').value.length > 0 || this.form.detail.get('agency').value.commisionSharing == 'true'

  document = {
    color: (type, listing) => {
      let color = 'button-blank'
      switch (type) {
        case 'authority':
          if (listing.authority) {
            if (['uploaded', 'amending', 'uploaded-amend'].indexOf(this.page.authority.state === 'amending' ? 'amending' : listing.authority.status) != -1) color = 'button-incomplete'
            else if (listing.authority.status == 'signed') color = 'button-complete'
          }
          break
        case 's32':
          if (listing.documents && listing.documents.s32) {
            if (['uploaded', 'builder'].indexOf(listing.documents.s32.status) != -1) color = 'button-incomplete'
            else if (listing.documents.s32.status == 'signed') color = 'button-complete'
          }
          break
        case 'contract':
          if (listing.documents && listing.documents.contract) {
            if (['uploaded', 'builder'].indexOf(listing.documents.contract.status) != -1) color = 'button-incomplete'
            else if (listing.documents.contract.status == 'signed') color = 'button-complete'
            else if (listing.offers.length > 0) color = 'button-incomplete'
          }
          break
        case 'other':
          if (listing.attachments && listing.attachments.length > 0) color = 'button-incomplete'
          break
      }
      return color;
    }
  }

}
