import 'rxjs/Rx'
import { Injectable } from '@angular/core'
import { AmplifyService } from 'aws-amplify-angular'
import { AppSessionService } from './app-session.service'

@Injectable()
export class StorageService {

  constructor(public amplify: AmplifyService, public app: AppSessionService) { }

  async url(name: string) {
    let storage = this.amplify.storage()
    return await storage.get(name, { level: 'public' })
  }

  getExtension(filename) {
    return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
  }

  save = (options: any) => {
    return new Promise((resolve, reject) => {
      let storage = this.amplify.storage()
      storage.put(options.name, options.data, { level: 'public', contentType: options.type })
        .then((result) => {
          console.warn(result)
          resolve({ ok: true, name: result })
        }).catch(error => {
          console.error(error)
          resolve({ ok: false, error: error })
        });
    })
  }
}
