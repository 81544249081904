import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { ApiService } from '../api.service';
import { AppSessionService } from '../app-session.service';
import { AmendmentChecksService } from './amendment-checks.service';

@Injectable({
  providedIn: 'root'
})
export class AmendmentService {

  constructor(
    public check: AmendmentChecksService,
    private builder: FormBuilder,
    private api: ApiService,
    private app: AppSessionService,
  ) { }

  fetchListingChanges = async (listingId, authorityDetails, amendmentsFormArray: FormArray) => {
    return new Promise(async resolve => {
      amendmentsFormArray.clear()

      let amendmentChanges = await this.check.checkChanges(listingId, authorityDetails)
      // let formArray = this.authority.form.detail.get('amendments') as FormArray
      amendmentChanges.forEach(change => { amendmentsFormArray.push(this.builder.group(change)) })
      console.log(amendmentsFormArray.value)

      // Find whether a price range has occured - Create Modal 
      let priceChange = amendmentsFormArray.value.find(a => a.details === 'Agent Estimate Price')
      resolve(priceChange)
    })
  }

  save = async (listing) => {
    return new Promise(async resolve => {
      console.log("Save AMENDMENTS")
      console.log(listing)
      await this.api.post('listing/authority/save', { data: listing })
      return resolve({ error: null, response: listing })
    })
  }

  merge = async (listing, rebatesCommissionSharing) => {
    return new Promise(async resolve => {
      console.log('PDFNames DATA')
      console.log(listing)
      await this.api.get('listing/authority/combine/amendments', { newFileName: listing.authority.PDFNames[listing.authority.PDFNames.length - 1], oldFileName: listing.authority.PDFNames[listing.authority.PDFNames.length - 2], id: listing._id, user: this.app.user.name, commisionSharingAuthority: rebatesCommissionSharing, commisionSharingAgency: listing.listing.agency.commisionSharing, commSharingLength: listing.listing.commisions.length })
      return resolve({ error: null, response: true })
    })
  }

  cancel = async (listingId, PDFNames, versions) => {
    // We need to remove the last Unsigned Amendment (or partially signed)
    // Get the last version, move the versions list from the current authority and add it to the previous version authority
    // Replace the current listing and authority with the old version that now contains all Versions.
    console.log('Cancel Amendment')
    console.log(PDFNames)
    console.log('Previous Version')
    console.log(PDFNames[PDFNames.length - 2])

    return new Promise(async resolve => {
      const prevVersionName = PDFNames[PDFNames.length - 2]
      console.log(prevVersionName)

      let previousVersion = versions[prevVersionName]
      console.log(previousVersion)
      previousVersion.authority['versions'] = versions

      // delete the version we are reverting to from the Authority Version Object
      if (Object.keys(previousVersion.authority.versions).length - 1 == 0) {
        console.warn('Delete authority Versions')
        delete previousVersion.authority.versions
      } else {
        console.warn('Delete authority previous Version')
        delete previousVersion.authority.versions[prevVersionName]
      }
      console.log(previousVersion)
      await this.api.post('listing/amendment/cancel', { id: listingId, listing: previousVersion, amend: true, user: this.app.user.name })
      console.log('There should be the old version as the main version now')

      return resolve({ error: null, response: true })
    })
  }

  email = async (listingId) => {
    return new Promise(async resolve => {
      console.log(listingId)
      await this.api.get('listing/authority/amendment/email', { id: listingId })
      return resolve()
    })
  }

  remoteSign = async (listingId, vendorId) => {
    return new Promise(async resolve => {
      await this.api.get('listing/signature/remoteSign', { listingId: listingId, type: 'vendor', document: 'amendAuthority', id: vendorId })
      return resolve()
    })
  }

}
