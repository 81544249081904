import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppSessionService } from './app-session.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  replaceUrl = true;

  constructor(
    private router: Router,
    private app: AppSessionService
  ) { 

  }

  private navStack: { page: string, params: any }[] = [];
  private componentParams: any = null;

  getCurrentPage = () => this.navStack[ this.navStack.length - 1 ].page as string
  
  getNavParams = () => {
    if (!this.navStack || this.navStack.length == 0) return {} as any
    return this.navStack[ this.navStack.length - 1 ].params as any;
  }

  setComponentParams = (params: any) => this.componentParams = params;

  getComponentParams = () => {
    const params = this.componentParams;
    this.componentParams = null;
    return params;
  }

  isEmpty = () => this.navStack.length === 1;

  push = (pages: string | string[], params?: any | any[] ) => {
    if (typeof pages === 'string') {
      if (!params) params = {};
      this.navStack.push({ page: pages, params });
    }
    else {
      if (!params) params = [];
      this.navStack = this.navStack.concat( pages.map( (page, idx) => ({ page, params: params[idx] || {} }) ) );
    }
    this.router.navigate( [ this.navStack[ this.navStack.length - 1 ].page ], { skipLocationChange: this.replaceUrl } );
  }

  pop = () => {
    this.navStack.pop();
    this.router.navigate( [ this.navStack[ this.navStack.length - 1 ].page ], { skipLocationChange: this.replaceUrl } );
  }

  setRoot = (pages: string | string[], params?: any | any[]) => {
    if (typeof pages === 'string') {
      if (!params) params = {};
      this.navStack = [{ page: pages, params}];
    }
    else {
      if (!params) params = [];
      this.navStack = pages.map( (page, idx) => ({ page, params: params[idx] || {} }) );
    }
    this.router.navigate( [ this.navStack[ this.navStack.length - 1 ].page ], { skipLocationChange: this.replaceUrl } );
  }
}
