import 'rxjs/Rx'
import { Injectable } from '@angular/core'
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable()
export class LoadingService {

  private load: any

  constructor(private loading: LoadingController, private toast: ToastController) { }

  show = async (content) => {
    if (this.load) await this.load.dismiss()
    this.load = await this.loading.create({ message: content })
    await this.load.present()
    return Promise.resolve()
  }

  hide = async (message?) => {
    if (this.load) await this.load.dismiss()
    this.load = null
    if (message) {
      // this.toast.create({ message: message, duration: 3000 }).present()
    }
    return Promise.resolve()
  }

}
