import { Injectable } from '@angular/core';
import * as moment from 'moment'
import * as numeral from 'numeral'
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { AppSessionService } from './app-session.service';
import { EnvironmentService } from './environment.service';
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(
    private env: EnvironmentService,
    private platform: Platform,
  ) {
    this.datePicker.minimumDate = new Date().toISOString()
    this.datePicker.maximumDate = moment().add('years', 5).toDate().toISOString()
    this.datePicker.minimumDateOptions = {
      dateFormat: 'd/m/yyyy',
      disableUntil: { year: moment().year(), month: moment().month() + 1, day: moment().date() - 1 },
      alignSelectorRight: true
    }
    this.datePicker.maximumDateOptions = {
      dateFormat: 'd/m/yyyy',
      disableSince: { year: moment().add('years', 5).year(), month: moment().add('years', 5).month() + 1, day: moment().add('years', 5).date() - 1 },
      alignSelectorRight: true
    }
    this.datePicker.minimumMaximumDateOptions = {
      dateFormat: 'd/m/yyyy',
      disableSince: this.datePicker.maximumDateOptions.disableSince,
      disableUntil: this.datePicker.minimumDateOptions.disableUntil,
      alignSelectorRight: true
    }
    this.datePicker.sunHighlight = false;
    this.datePicker.satHighlight = false;
  }

  moneyRounded = (value) => numeral(value ? value : 0).format('$0,0')
  number = (value) => numeral(value ? value : 0).value()

  form = {
    validate: (form) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field)
        control.markAsTouched({ onlySelf: true })
        if (!control.valid) console.log(field)
      })
    },
    update: (form) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field)
        control.updateValueAndValidity({ onlySelf: false, emitEvent: true })
      })
    }
  }

  datePicker = {
    fromDate: (date) => {
      let d = moment(date)
      return { year: d.year(), month: d.month() + 1, day: d.date() }
    },
    toDate: (date) => {
      if (date) {
        if (date.date) date = date.date
        return new Date(date.year, date.month - 1, date.day)
      }
      else return null
    },
    dateOptions: {
      dateFormat: 'd/m/yyyy',
      alignSelectorRight: true,
      clearable: 'false'
    },
    minimumDateOptions: null,
    maximumDateOptions: null,
    minimumMaximumDateOptions: null,
    minimumDate: null,
    maximumDate: null,
    sunHighlight: null,
    satHighlight: null,
  }
  titleCase = (s: String) => {
    if (s) {
      let str = s.toLowerCase().split(' ').map((str) => str.charAt(0).toUpperCase() + str.substring(1)).join(' ')
      return str
    } else {
      return ''
    }
  }

  stringify = (o) => {
    return JSON.stringify(o)
  }

  convertDate = (date) => {
    let d = moment(date)
    return { year: d.year(), month: d.month() + 1, day: d.date() }
  }

  revertDate = (date) => {
    if (date) {
      if (date.date) date = date.date
      return new Date(date.year, date.month - 1, date.day)
    }
    else { return null }
  }

  formatDate = (date) => {
    if (date) {
      let dateArray = date.split(' ')
      // console.log(dateArray)
      return `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]}`
    }
  }

  formatName = (o) => {
    if (o)
      if (o.type == 'Company') return o.companyName
      else return o.givenNames + ' ' + o.surname
  }

  format = {
    mobile: (s: string) => {
      let res = ''
      if (s) {
        let test = s.split(' ')
        if (test.length == 1) {
          let split = s.split('')
          res = `${split[0]}${split[1]}${split[2]}${split[3]} ${split[4]}${split[5]}${split[6]} ${split[7]}${split[8]}${split[9]}`
        } else {
          res = s
        }
      }
      return res
    },
    phone: (s: string) => {
      let res = ''
      if (s) {
        let test = s.split(' ')
        if (test.length == 1) {
          let split = s.split('')
          res = `(${split[0]}${split[1]}) ${split[2]}${split[3]}${split[4]}${split[5]}-${split[6]} ${split[7]}${split[8]}${split[9]}`
        } else {
          res = s
        }
      }
      return res
    },
  }

  money = (value) => {
    return numeral(value).format('$0,0.00')
  }

  cloneControl = (control) => {
    let newControl

    if (control instanceof FormGroup) {
      const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
      const controls = control.controls;

      Object.keys(controls).forEach(key => {
        formGroup.addControl(key, this.cloneControl(controls[key]));
      })

      newControl = formGroup as any;
    }
    else if (control instanceof FormArray) {
      const formArray = new FormArray([], control.validator, control.asyncValidator);

      control.controls.forEach(formControl => formArray.push(this.cloneControl(formControl)))

      newControl = formArray as any;
    }
    else if (control instanceof FormControl) {
      newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
    }
    else {
      throw new Error('Error: unexpected control value');
    }

    if (control.disabled) newControl.disable({ emitEvent: false });

    return newControl;
  }


  isDevice = () => {
    return this.platform.is('ios') || this.platform.is('android')
  }


  isMaintenanceMode = maintenance => !maintenance.ok || this.compareVersion(this.env.version, maintenance.minVersion)

  /*
    Is currentVersion (the UI version) is a smaller version than the minVersion

    test cases
    eg. current: 3.1.11 -> min: 3.1.12
    eg. current: 3.1.11 -> min: 3.2.0
    eg. current: 3.1.11 -> min: 4.0.0
  */
  compareVersion = (currentVersion: string, minVersion: string): boolean => {
    let currentSplit = currentVersion.split('.')
    let minSplit = minVersion.split('.')

    console.log(currentSplit)
    console.log(minSplit)
    // First number is less then the min version
    if (parseInt(currentSplit[0]) < parseInt(minSplit[0])) return true
    // Second number is less then the min version
    if (parseInt(currentSplit[1]) < parseInt(minSplit[1])) return true
    // Third number is less then the min version
    if (parseInt(currentSplit[2]) < parseInt(minSplit[2])) return true

    return false
  }

}
