import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormBuilder, Form, FormArray, FormControl } from '@angular/forms';
import { CommissionTextService } from './commission-text.service'
import { HelperService } from '../helper.service'
import * as moment from 'moment'
import * as numeral from 'numeral'

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public terms: FormGroup
  public commissions: FormGroup
  public rebates: FormGroup
  public agentEstimate: FormGroup
  public marketing: FormGroup
  public detail: FormGroup

  constructor(private builder: FormBuilder, private helper: HelperService, private commissionText: CommissionTextService) {
    this.detail = this.builder.group({
      status: [''],
      initialPrepare: [''],
      prepared: new FormControl(''),
      preparedBy: new FormControl(''),
      locked: new FormControl(''),
      PDFNames: new FormControl([]),
      annotation: new FormControl([]),
      versions: new FormControl({}),
      authorityAmend: new FormControl(false),
      amendments: new FormArray([]),
    })

    this.agentEstimate = this.builder.group({
      type: ['range'],
      price: [''],
      priceMinimum: ['', Validators.compose([Validators.required, Validators.min(1)])],
      priceMaximum: ['', Validators.compose([Validators.required, Validators.min(1)])],
      priceMaximumPercentage: ['0.00', Validators.compose([Validators.max(10)])],
      priceText: [''],
      amendAgentEstimateChange: [false],
      amendAgentEstimateReason: [''],
      amendAgentEstimateReasonOther: [''],
    })
    this.marketing = this.builder.group({
      notes: [''],
      payable: [false],
      // rea: [false],
      // reaPrio: [false],
      // reaValue: [0],
      // brochures: [false],
      // brochuresValue: [0],
      // localPaper: [false],
      // localPaperValue: [0],
      // domain: [false],
      // domainValue: [0],
      otherAdvertising: [false],
      otherAdvertisingValue: [0],
      // councilFee: [0],
      // processingFee: [0],
      advertisingFees: this.builder.array([]),
      otherFees: this.builder.array([]),
      advertising: new FormControl({ value: 0 }),
      other: new FormControl({ value: 0 }),
      total: new FormControl({ value: 0 }),
    })
    this.rebates = this.builder.group({
      rebatesEntitled: [false],
      commisionSharing: [false],
      commisionSharingType: [],
      rebates: this.builder.array([]),
      commisions: this.builder.array([])
    })

    this.commissions = this.builder.group({
      type: ['percentage'],
      fixed: [''],
      percentage: ['0', Validators.compose([Validators.required])],
      amount: [''],
      gstType: ['GSTInclusive'],
      commisionText: ['GSTInclusive'],
      performance: ['none'],
      performanceType: ['percentage'],
      performanceTrigger: [''],
      performanceExample: [''],
      performanceFixed: [''],
      performancePercentage: ['0'],
      performanceAmount: [''],
      estimatedTrigger: [''],
      estimatedBaseTrigger: [''],
      estimatedVendor: [''],
      estimated: [''],
      estimatedMinimum: [''],
      estimatedMaximum: [''],
      estimatedGST: ['']
    })

    this.terms = this.builder.group({
      auction: [false],
      auctionTimeTBA: [false],
      auctionDate: [null],
      saleType: ['', Validators.compose([Validators.required])],
      saleTypeDate: [''],
      auctionTime: [''],
      exclusivePeriod: [60, Validators.compose([Validators.required])],
      exclusivePeriodOther: [''],
      exclusivePeriodStandard: [60],
      exclusivePeriodEnds: [''],
      exclusivePeriodEndsDate: [''],
      continuingPeriod: ['', Validators.compose([Validators.required])],
      continuingPeriodOther: [''],
      continuingPeriodStandard: [0],
      continuingPeriodEnds: [''],
      continuingPeriodEndsDate: [''],
      propertyVacancy: ['Vacant'],
      paymentPrice: [''],
      paymentPriceTBA: [true],
      reservePrice: [''],
      paymentDays: [30],
      paymentDaysTBA: [true],
      paymentDaysValues: [''],
      agentEstimate: this.agentEstimate,
      marketing: this.marketing,
      commision: this.commissions,
    })
  }

  build = {
    terms: (): FormGroup => {
      return this.builder.group({
        auction: [false],
        auctionTimeTBA: [false],
        auctionDate: [null],
        saleType: ['', Validators.compose([Validators.required])],
        saleTypeDate: [''],
        auctionTime: [''],
        exclusivePeriod: [60, Validators.compose([Validators.required])],
        exclusivePeriodOther: [''],
        exclusivePeriodStandard: [60],
        exclusivePeriodEnds: [''],
        continuingPeriod: ['0', Validators.compose([Validators.required])],
        continuingPeriodOther: [''],
        continuingPeriodStandard: [0],
        continuingPeriodEnds: [''],
        propertyVacancy: ['Vacant'],
        paymentPrice: [''],
        paymentPriceTBA: [true, Validators.compose([Validators.requiredTrue])],
        reservePrice: [''],
        paymentDays: [30],
        paymentDaysTBA: [true, Validators.compose([Validators.requiredTrue])],
        paymentDaysValues: [''],
        agentEstimate: this.build.agentEstimate(),
        marketing: this.build.marketing(),
        commision: this.build.commissions(),
      })
    },
    agentEstimate: (): FormGroup => {
      return this.builder.group({
        type: ['range'],
        price: [''],
        priceMinimum: ['', Validators.compose([Validators.required, Validators.min(1)])],
        priceMaximum: ['', Validators.compose([Validators.required, Validators.min(1)])],
        priceMaximumPercentage: ['0.00', Validators.compose([Validators.max(10)])],
        priceText: [''],
        amendAgentEstimateChange: [false],
        amendAgentEstimateReason: [''],
        amendAgentEstimateReasonOther: [''],
      })
    },
    marketing: () => {
      return this.builder.group({
        notes: [''],
        payable: [false],
        // rea: [false],
        // reaPrio: [false],
        // reaValue: [0],
        // brochures: [false],
        // brochuresValue: [0],
        // localPaper: [false],
        // localPaperValue: [0],
        // domain: [false],
        // domainValue: [0],
        otherAdvertising: [false],
        otherAdvertisingValue: [0],
        // councilFee: [0],
        // processingFee: [0],
        advertisingFees: this.builder.array([]),
        otherFees: this.builder.array([]),
        advertising: new FormControl({ value: 0 }),
        other: new FormControl({ value: 0 }),
        total: new FormControl({ value: 0 }),
      })
    },
    commissions: () => {
      return this.builder.group({
        type: ['percentage'],
        fixed: [''],
        percentage: ['', Validators.compose([Validators.required])],
        amount: [''],
        gstType: ['GSTInclusive'],
        commisionText: ['GSTInclusive'],
        performance: ['none'],
        performanceType: ['percentage'],
        performanceTrigger: [''],
        performanceExample: [''],
        performanceFixed: [''],
        performancePercentage: ['0'],
        performanceAmount: [''],
        estimatedTrigger: [''],
        estimatedBaseTrigger: [''],
        estimatedVendor: [''],
        estimated: [''],
        estimatedMinimum: [''],
        estimatedMaximum: [''],
        estimatedGST: ['']
      })
    },
    fee: () => {
      return this.builder.group({
        fee: [0],
        name: ['']
      })
    }
  }

  commissionsList = {
    import: (commisions) => {
      if (commisions) {
        let formArray = this.rebates.get('commisions') as FormArray
        while (formArray.length !== 0) formArray.removeAt(0)
        commisions.forEach(commision => {
          formArray.push(
            this.builder.group({
              name: [commision.name],
              description: [commision.description]
            }))
        });
      }
    },
    add: () => {
      let commisions = this.rebates.get('commisions') as FormArray
      commisions.push(
        this.builder.group({
          name: [''],
          description: ['']
        }))
    },
    remove: (index) => {
      let commisions = this.rebates.get('commisions') as FormArray
      commisions.removeAt(index)
    }
  }

  rebatesList = {
    import: (rebates) => {
      if (rebates) {
        let formArray = this.rebates.get('rebates') as FormArray
        while (formArray.length !== 0) formArray.removeAt(0)
        rebates.forEach(rebate => {
          formArray.push(
            this.builder.group({
              goods: [rebate.goods],
              organisation: [rebate.organisation],
              amount: [rebate.amount]
            }))
        });
      }
    },
    add: () => {
      let rebates = this.rebates.get('rebates') as FormArray
      rebates.push(
        this.builder.group({
          goods: [''],
          organisation: [''],
          amount: ['']
        }))
    },
    remove: (index) => {
      let rebates = this.rebates.get('rebates') as FormArray
      rebates.removeAt(index)
    }
  }

  public cleanup = () => {
    this.terms.reset()
    this.rebates.reset()
    let formArray = this.rebates.get('commisions') as FormArray
    formArray.clear()
    this.detail.reset()
    this.marketing.reset();
    for (let formArrayName of ['advertisingFees', 'otherFees']) {
      const formArray = this.marketing.get(formArrayName) as FormArray;
      formArray.clear()
    }

    this.terms.clearValidators()
    this.detail.clearValidators()
  }

  // public setValidators = () => {
  //   // Terms.
  //   this.terms.get('exclusivePeriod').setValidators([Validators.required])
  //   this.terms.get('continuingPeriod').setValidators([Validators.required])

  //   // AgentEstiate
  //   this.terms.get('agentEstimate').get('priceMinimum').setValidators([Validators.required, Validators.min(1)])
  //   this.terms.get('agentEstimate').get('priceMaximum').setValidators([Validators.required, Validators.min(1)])

  //   // Commission
  //   this.terms.get('commision').get('percentage').setValidators([Validators.required])

  //   // Marketing
  //   this.terms.get('saleType').setValidators([Validators.required])
  //   // this.terms.get('saleTypeDate').setValidators([Validators.required])

  //   return Promise.resolve();
  // }

  public getInvalidControls = (vendors, propertyForm) => {
    let invalid = []
    let temp = { warning: '', link: '' }

   // Property Details
   let property = propertyForm.value
   let type = property.propertyType
   let vacancy = property.propertyVacancy
   let address = !(property.street && property.postcode && property.state && property.suburb)
   
   if (address) {
    invalid.push({ warning: `Property address is required`, link: 'property' })
   }
   if (!(type || vacancy)) {
     invalid.push({ warning: `Property Type and/ or Vacancy is required`, link: 'property' })
   }

    // VENDORS 
    // At least one vendor
    // At least one company rep if the vendor is a company.
    if (vendors.length == 0) {
      invalid.push({ warning: 'Please enter at least one vendor', link: 'vendor' })
    }

    for (let vendor of vendors) {
      console.log('Vendor', vendor)
      if (vendor.type == 'Company') {
        if (vendor.companyRepresentatives.length == 0) {
          invalid.push({ warning: `${vendor.companyName}: Company representative required`, link: 'vendor' })
        }
        // for(let rep of vendor.companyRepresentatives) {
        //   if (!rep.firstName || !rep.surname) {
        //     invalid.push({ warning: `${vendor.companyName}: Company representative name required`, link: 'vendor' })
        //   }
        // }
      }
      if (!(vendor.street && vendor.postcode && vendor.state && vendor.suburb)) {
        invalid.push({ warning: `${this.helper.formatName(vendor)}: Vendor address required`, link: 'vendor' })
      }
    }

    // TERMS
    // Payment Days
    // Payment Price
    // Sale Type
    const controls = this.terms.controls
    console.log(controls)
    for (const name in controls) {
      if (name == 'agentEstimate' || name == 'commision') {
        continue
      } else if (controls[name].invalid) {
        temp = { warning: '', link: '' }

        switch (name) {
          case 'saleType':
            temp.warning = 'Sale Type is required'
            break;
          case 'saleTypeDate':
            temp.warning = 'Auction Date is required'
            break;
          case 'paymentPrice':
          case 'paymentPriceTBA':
            temp.warning = 'Payment Price is required'
            break
          case 'paymentDays':
          case 'paymentDaysTBA':
          case 'paymentDaysValues':
            temp.warning = 'Payment Days is required'
            break
          case 'exclusivePeriodOther':
            temp.warning = 'Exclusive Period days is required'
            break;
          case 'continuingPeriodOther':
            temp.warning = 'Continuing Period days is required'
            break;
          default:
            temp.warning = name
        }

        if (temp.warning != '') {
          temp.link = 'authorityCommShare'
          invalid.push(temp)
        }
      }
    }

    // AGENT ESTIMATE
    // Price Estimate
    const agentEst = this.terms.get('agentEstimate') as FormGroup
    const controls2 = agentEst.controls
    for (const name in controls2) {
      if (controls2[name].invalid) {
        console.log('invalid: ' + name)
        let once = invalid.filter(t => t.warning == `Agent Estimate Price`)
        if (once.length == 0) {
          invalid.push({ warning: `Agent Estimate Price is invalid`, link: 'authorityCommShare' })
        }
      }
    }

    // COMMISSIONS
    // Commission Percentage 
    const commision = this.terms.get('commision') as FormGroup
    const controls3 = commision.controls
    console.log(controls3)
    for (const name in controls3) {
      if (controls3[name].invalid) {
        temp = { warning: '', link: '' }
        switch (name) {
          case "percentage":
            temp.warning = "Percentage"
            break;
          case 'performanceTrigger':
            temp.warning = "Performance Trigger"
            break;
          case 'performanceFixed':
            temp.warning = "Performance Fixed"
            break;
          case 'performancePercentage':
            temp.warning = "Performance Percentage"
            break;
          default:
            temp.warning = name
            break;
        }
        invalid.push({ warning: `Agent Commission ${this.helper.titleCase(temp.warning)}`, link: 'authorityCommShare' })
      }
    }

    // Marketing Cannot be 0
    let marketing = this.terms.controls.marketing.value
    if (marketing.total == 0) {
      invalid.push({ warning: `Marketing Budget cannot be $0`, link: 'advertising' })
    }

    return invalid;
  }

  public subscriptions = () => {
    this.terms.get('commision').get('percentage').value
    this.terms.get('commision').get('percentage').valueChanges.subscribe(_ => {
      console.log(_)
    })
    // this.commissions.get('type').valueChanges.subscribe((type: string) => {
    //   this.commissions.get('fixed').setValidators([])
    //   this.commissions.get('percentage').setValidators([])
    //   this.commissions.get('estimated').setValidators([])
    //   if (type == 'fixed') this.commissions.get('fixed').setValidators([Validators.required])
    //   else {
    //     this.commissions.get('percentage').setValidators([Validators.required])
    //     this.commissions.get('estimated').setValidators([Validators.required])
    //   }
    //   this.commissions.get('fixed').updateValueAndValidity()
    //   this.calculateCommssions()
    //   this.commissions.get('estimated').updateValueAndValidity()
    // })
    // this.agentEstimate.get('type').valueChanges.subscribe((type: string) => {
    //   this.agentEstimate.get('price').setValidators([])
    //   this.agentEstimate.get('priceMinimum').setValidators([])
    //   this.agentEstimate.get('priceMaximum').setValidators([])
    //   if (type == 'single') this.agentEstimate.get('price').setValidators([Validators.required])
    //   else {
    //     this.agentEstimate.get('priceMinimum').setValidators([Validators.required])
    //     this.agentEstimate.get('priceMaximum').setValidators([Validators.required])
    //   }
    //   this.agentEstimate.get('price').updateValueAndValidity()
    //   this.agentEstimate.get('priceMinimum').updateValueAndValidity()
    //   this.agentEstimate.get('priceMaximum').updateValueAndValidity()
    //   this.agentEstimate.get('priceMaximumPercentage').updateValueAndValidity()
    // })

    this.terms.get('saleType').valueChanges.subscribe(type => {
      if (type == 'Auction') {
        this.terms.get('auction').patchValue(true)
        this.terms.get('auctionDate').patchValue(this.terms.get('saleTypeDate').value, { emitEvent: false })
        this.terms.get('saleTypeDate').setValidators(Validators.compose([Validators.required]))
        this.terms.get('saleTypeDate').updateValueAndValidity({ emitEvent: false })
      } else {
        this.terms.get('auction').patchValue(false)
        this.terms.get('auctionDate').patchValue('')
        this.terms.get('saleTypeDate').clearValidators()
        this.terms.get('saleTypeDate').updateValueAndValidity({ emitEvent: false })
      }
    })

    this.terms.get('saleTypeDate').valueChanges.subscribe(date => {
      if (this.terms.get('saleType').value == 'Auction') {
        this.terms.get('auctionDate').patchValue(this.terms.get('saleTypeDate').value)
      } else {
        this.terms.get('auctionDate').patchValue('')
      }
    })

    // MARKETING SUBSCRIPTIONS
    // Price Changes
    // this.terms.controls.marketing.get('reaValue').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    // this.terms.controls.marketing.get('brochuresValue').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    // this.terms.controls.marketing.get('localPaperValue').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    // this.terms.controls.marketing.get('domainValue').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    this.terms.controls.marketing.get('otherAdvertisingValue').valueChanges.subscribe((value) => {
      this.calcuateAdvertising()
    })

    // Marketing Tick Changes
    // this.terms.controls.marketing.get('rea').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    // this.terms.controls.marketing.get('brochures').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    // this.terms.controls.marketing.get('localPaper').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    // this.terms.controls.marketing.get('domain').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    this.terms.controls.marketing.get('otherAdvertising').valueChanges.subscribe((value) => {
      this.calcuateAdvertising()
    })

    // OTHER
    // this.terms.controls.marketing.get('councilFee').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })
    // this.terms.controls.marketing.get('processingFee').valueChanges.subscribe((value) => {
    //   this.calcuateAdvertising()
    // })

    // this.terms.get('propertyVacancy').valueChanges.subscribe((propertyVacancy: string) => {
    //   if (propertyVacancy == 'tenancy') {
    //     this.contractTermsget('subjectToLease').patchValue(true)
    //   }
    //   else {
    //     this.contractTermsget('subjectToLease').patchValue(false)
    //   }
    // })
    this.terms.controls.exclusivePeriodStandard.valueChanges.subscribe((exclusivePeriodStandard: string) => {
      if (exclusivePeriodStandard != 'other') {
        this.terms.get('exclusivePeriod').patchValue(parseInt(exclusivePeriodStandard))
        this.terms.get('exclusivePeriodOther').clearValidators()
        this.terms.get('exclusivePeriodOther').updateValueAndValidity({ emitEvent: false })
      } else {
        this.terms.get('exclusivePeriodOther').setValidators(Validators.compose([Validators.required]))
        if (!this.terms.get('exclusivePeriodOther').value) { this.terms.get('exclusivePeriodOther').patchValue('0') }
        this.terms.get('exclusivePeriodOther').updateValueAndValidity({ emitEvent: false })
      }
    })
    this.terms.controls.exclusivePeriodOther.valueChanges.subscribe((exclusivePeriodOther: string) => {
      if (!isNaN(parseInt(exclusivePeriodOther)) && this.terms.controls.exclusivePeriodStandard.value === 'other')
        this.terms.get('exclusivePeriod').patchValue(parseInt(exclusivePeriodOther))
    })

    this.terms.get('continuingPeriodStandard').valueChanges.subscribe((continuingPeriodStandard: string) => {
      if (continuingPeriodStandard != 'other' && continuingPeriodStandard != 'no') {
        this.terms.get('continuingPeriod').patchValue(parseInt(continuingPeriodStandard))
        this.terms.get('continuingPeriodOther').clearValidators()
        this.terms.get('continuingPeriodOther').updateValueAndValidity({ emitEvent: false })
      } else {
        this.terms.get('continuingPeriodOther').setValidators(Validators.compose([Validators.required]))
        if (!this.terms.get('continuingPeriodOther').value) { this.terms.get('continuingPeriodOther').patchValue('0') }
        this.terms.get('continuingPeriodOther').updateValueAndValidity({ emitEvent: false })
      }
    })
    this.terms.controls.continuingPeriodOther.valueChanges.subscribe((continuingPeriodOther: string) => {
      if (!isNaN(parseInt(continuingPeriodOther)) && this.terms.controls.continuingPeriodStandard.value === 'other')
        this.terms.get('continuingPeriod').patchValue(parseInt(continuingPeriodOther))
    })

    this.terms.get('exclusivePeriod').valueChanges.subscribe((exclusivePeriod: string) => {
      this.terms.get('exclusivePeriodEnds').patchValue(moment().add(exclusivePeriod, 'days').format('ddd Do MMM YYYY'))
      this.terms.get('exclusivePeriodEndsDate').patchValue(moment().add(exclusivePeriod, 'days').toDate())
      let continuingPeriod = this.terms.get('continuingPeriod').value
      this.terms.get('continuingPeriodEnds').patchValue(moment().add(parseInt(exclusivePeriod) + parseInt(continuingPeriod), 'days').format('ddd Do MMM YYYY'))
      this.terms.get('continuingPeriodEndsDate').patchValue(moment().add(parseInt(exclusivePeriod) + parseInt(continuingPeriod), 'days').toDate())
    })
    this.terms.get('continuingPeriod').valueChanges.subscribe((continuingPeriod: string) => {
      let exclusivePeriod = this.terms.get('exclusivePeriod').value
      this.terms.get('continuingPeriodEnds').patchValue(moment().add(parseInt(exclusivePeriod) + parseInt(continuingPeriod), 'days').format('ddd Do MMM YYYY'))
      this.terms.get('continuingPeriodEndsDate').patchValue(moment().add(parseInt(exclusivePeriod) + parseInt(continuingPeriod), 'days').toDate())
    })



    this.terms.get('paymentPriceTBA').valueChanges.subscribe((paymentPriceTBA: boolean) => {

      if (paymentPriceTBA) {
        this.terms.get('paymentPrice').setValidators([])
        this.terms.get('paymentPriceTBA').setValidators([])

        this.terms.get('paymentPrice').patchValue(null, { emitEvent: false })
        this.terms.get('paymentPriceTBA').setValidators(Validators.compose([Validators.requiredTrue]))

        this.terms.get('paymentPrice').updateValueAndValidity({ emitEvent: false })
        this.terms.get('paymentPriceTBA').updateValueAndValidity({ emitEvent: false })
      }

    })

    this.terms.get('paymentPrice').valueChanges.subscribe((paymentPrice: any) => {

      if (numeral(paymentPrice).value() > 0) {
        this.terms.get('paymentPrice').setValidators([])
        this.terms.get('paymentPriceTBA').setValidators([])

        this.terms.get('paymentPriceTBA').patchValue(false, { emitEvent: false })
        this.terms.get('paymentPrice').setValidators(Validators.compose([Validators.required]))

        this.terms.get('paymentPrice').updateValueAndValidity({ emitEvent: false })
        this.terms.get('paymentPriceTBA').updateValueAndValidity({ emitEvent: false })
      }

      this.calculateCommssions()
    })




    this.terms.get('paymentDaysTBA').valueChanges.subscribe((paymentDaysTBA: boolean) => {

      if (paymentDaysTBA) {
        this.terms.get('paymentDays').clearValidators()
        this.terms.get('paymentDaysTBA').clearValidators()
        this.terms.get('paymentDaysValues').clearValidators()

        this.terms.get('paymentDays').patchValue([], { emitEvent: false })
        this.terms.get('paymentDaysValues').patchValue('', { emitEvent: false })
        this.terms.get('paymentDaysTBA').setValidators(Validators.compose([Validators.requiredTrue]))

        this.terms.get('paymentDays').updateValueAndValidity({ emitEvent: false })
        this.terms.get('paymentDaysTBA').updateValueAndValidity({ emitEvent: false })
        this.terms.get('paymentDaysValues').updateValueAndValidity({ emitEvent: false })
      }
    })

    this.terms.get('paymentDays').valueChanges.subscribe((paymentDays: string) => {

      if (paymentDays && paymentDays.length > 0) {
        this.terms.get('paymentDays').clearValidators()
        this.terms.get('paymentDaysTBA').clearValidators()
        this.terms.get('paymentDaysValues').clearValidators()

        this.terms.get('paymentDaysTBA').patchValue(false, { emitEvent: false })
        this.terms.get('paymentDays').setValidators(Validators.compose([Validators.required]))

        if (this.terms.get('paymentDays').value[0] === 'other') {
          this.terms.get('paymentDaysValues').setValidators([Validators.compose([Validators.required])])
        }

        this.terms.get('paymentDays').updateValueAndValidity({ emitEvent: false })
        this.terms.get('paymentDaysTBA').updateValueAndValidity({ emitEvent: false })
        this.terms.get('paymentDaysValues').updateValueAndValidity({ emitEvent: false })
      }
    })



    this.terms.get('auctionTimeTBA').valueChanges.subscribe((auction: string) => {
      this.terms.get('auction').updateValueAndValidity()
    })

    // this.terms.get('auction').valueChanges.subscribe((auction: string) => {
    //   // let auctionTimeTBA = this.terms.controls.auctionTimeTBA.value
    //   this.terms.get('auctionDate').setValidators([])
    //   if (auction) {
    //     this.terms.get('auctionDate').setValidators([Validators.required])
    //   }
    //   this.terms.get('auctionDate').updateValueAndValidity()
    // })

    this.terms.controls.marketing.get('advertising').valueChanges.subscribe((advertising: string) => {
      this.terms.controls.marketing.get('total').patchValue(numeral(advertising).value() + numeral(this.terms.controls.marketing.get('other').value).value())
    })
    this.terms.controls.marketing.get('other').valueChanges.subscribe((other: string) => {
      this.terms.controls.marketing.get('total').patchValue(numeral(other).value() + numeral(this.terms.controls.marketing.get('advertising').value).value())
    })


    this.terms.controls.commision.get('type').valueChanges.subscribe((type: string) => {
      this.terms.controls.commision.get('fixed').setValidators([])
      this.terms.controls.commision.get('percentage').setValidators([])
      this.terms.controls.commision.get('estimated').clearValidators()
      if (type == 'fixed') {
        this.terms.controls.commision.get('fixed').setValidators([Validators.required])
      } else {
        this.terms.controls.commision.get('percentage').setValidators([Validators.required])
        // this.terms.controls.commision.get('estimated').setValidators([Validators.required])
      }
      this.terms.controls.commision.get('fixed').updateValueAndValidity()
      this.calculateCommssions()
      this.terms.controls.commision.get('estimated').updateValueAndValidity()
    })

    this.terms.controls.commision.get('fixed').valueChanges.subscribe((type: string) => {
      this.calculateCommssions()
    })
    this.terms.controls.commision.get('gstType').valueChanges.subscribe((percentage: string) => {
      this.calculateCommssions()
    })

    this.terms.controls.commision.get('performanceType').valueChanges.subscribe((performanceType: string) => {
      this.calculateCommssions()
      this.terms.controls.commision.get('performancePercentage').clearValidators()
      this.terms.controls.commision.get('performanceFixed').clearValidators()

      if (this.terms.controls.commision.get('performance').value !== 'none') {
        if (performanceType === 'fixed') { this.terms.controls.commision.get('performanceFixed').setValidators([Validators.compose([Validators.required])]) } 
        else if (performanceType === 'percentage') { this.terms.controls.commision.get('performancePercentage').setValidators([Validators.compose([Validators.required])]) }
      }

      this.terms.controls.commision.get('performancePercentage').updateValueAndValidity({ emitEvent: false })
      this.terms.controls.commision.get('performanceFixed').updateValueAndValidity({ emitEvent: false })
    })
    this.terms.controls.commision.get('performance').valueChanges.subscribe((performance: string) => {
      this.calculateCommssions()

      if (performance === 'none') {
        this.terms.controls.commision.get('performancePercentage').clearValidators()
        this.terms.controls.commision.get('performanceTrigger').clearValidators()
        this.terms.controls.commision.get('performanceFixed').clearValidators()
      } else {
        this.terms.controls.commision.get('performanceTrigger').setValidators([Validators.compose([Validators.required])])
      }

      this.terms.controls.commision.get('performancePercentage').updateValueAndValidity({ emitEvent: false })
      this.terms.controls.commision.get('performanceTrigger').updateValueAndValidity({ emitEvent: false })
      this.terms.controls.commision.get('performanceFixed').updateValueAndValidity({ emitEvent: false })
    })

    this.terms.controls.commision.get('performancePercentage').valueChanges.subscribe((percentage: string) => {
      this.calculateCommssions()
    })
    this.terms.controls.commision.get('performanceTrigger').valueChanges.subscribe((percentage: string) => {
      this.calculateCommssions()
    })
    this.terms.controls.commision.get('performanceFixed').valueChanges.subscribe((percentage: string) => {
      this.calculateCommssions()
    })



    this.terms.controls.commision.get('percentage').valueChanges.subscribe((percentageText: string) => {
      this.calculateCommssions()
    })

    this.terms.controls.agentEstimate.get('price').valueChanges.subscribe((minimum: string) => {
      this.calculateCommssions()
      this.terms.controls.agentEstimate.get('priceMinimum').patchValue(this.terms.controls.agentEstimate.get('price').value)
      // this.terms.controls.agentEstimate.get('amendAgentEstimateChange').patchValue(true)
    })

    this.terms.controls.agentEstimate.get('priceMinimum').valueChanges.subscribe((priceMinimum: any) => {
      let priceMaximum = this.terms.controls.agentEstimate.get('priceMaximum').value
      if (!priceMaximum) priceMaximum = '0'
      this.terms.controls.agentEstimate.get('priceMaximumPercentage').patchValue(numeral(((numeral(priceMaximum).value() - numeral(priceMinimum).value()) / numeral(priceMinimum).value()) * 100).format('0.000000000'))
      this.calculateCommssions()
      if (priceMinimum) { // && priceMaximum === '0' TODO: When Unlock and reload, the max value is back to 10%, when it is saved as beyond that
        if (isNaN(priceMinimum)) priceMinimum = parseInt(priceMinimum.substr(1).replace(/\s/g, '').replace(/,/g, ''))
        if (isNaN(priceMaximum)) priceMaximum = parseInt(priceMaximum.substr(1).replace(/\s/g, '').replace(/,/g, ''))
        // console.log(priceMinimum)
        // console.log(numeral(priceMinimum * 1.1).value())
        this.terms.controls.agentEstimate.get('priceMaximum').patchValue(numeral(priceMinimum * 1.1).value())
      }
      // this.terms.controls.agentEstimate.get('amendAgentEstimateChange').patchValue(true)
      // console.log(this.terms.controls.agentEstimate.get('priceMaximum'))
    })

    this.terms.controls.agentEstimate.get('priceMaximum').valueChanges.subscribe((maximum: string) => {
      // console.log('Price Maximum change')
      let minimum = this.terms.controls.agentEstimate.get('priceMinimum').value
      this.terms.controls.agentEstimate.get('priceMaximumPercentage').patchValue(numeral(((numeral(maximum).value() - numeral(minimum).value()) / numeral(minimum).value()) * 100).format('0.000000000'))
      this.calculateCommssions()
      // this.terms.controls.agentEstimate.get('amendAgentEstimateChange').patchValue(true)
    })

  }

  calcuateAdvertising = () => {
    // let rea = '0'
    // let brochures = '0'
    // let local = '0'
    // let domain = '0'
    let otherAdvertising = '0'

    // Get Advertising Total and patch it
    // if (this.terms.controls.marketing.get('rea').value) {
    //   rea = this.terms.controls.marketing.get('reaValue').value ? this.terms.controls.marketing.get('reaValue').value : 0
    // }
    // if (this.terms.controls.marketing.get('brochures').value) {
    //   brochures = this.terms.controls.marketing.get('brochuresValue').value ? this.terms.controls.marketing.get('brochuresValue').value : 0
    // }
    // if (this.terms.controls.marketing.get('localPaper').value) {
    //   local = this.terms.controls.marketing.get('localPaperValue').value ? this.terms.controls.marketing.get('localPaperValue').value : 0
    // }
    // if (this.terms.controls.marketing.get('domain').value) {
    //   domain = this.terms.controls.marketing.get('domainValue').value ? this.terms.controls.marketing.get('domainValue').value : 0
    // }
    if (this.terms.controls.marketing.get('otherAdvertising').value) {
      otherAdvertising = this.terms.controls.marketing.get('otherAdvertisingValue').value ? this.terms.controls.marketing.get('otherAdvertisingValue').value : 0
    }
    let advTotal = /*parseInt(rea) + parseInt(brochures) + parseInt(local) + parseInt(domain) +*/ parseInt(otherAdvertising)
    let marketing: any = this.terms.controls.marketing
    for (let control of marketing.controls.advertisingFees.controls) {
      advTotal += numeral(control.controls.fee.value).value()
    }
    this.terms.controls.marketing.get('advertising').patchValue(advTotal)
    // Get other Total and Patch It
    // let council = this.terms.controls.marketing.get('councilFee').value ? this.terms.controls.marketing.get('councilFee').value : 0
    // let processingFee = this.terms.controls.marketing.get('processingFee').value ? this.terms.controls.marketing.get('processingFee').value : 0
    // let otherTotal = parseInt(council) + parseInt(processingFee)
    let otherTotal = 0
    for (let control of marketing.controls.otherFees.controls) {
      otherTotal += numeral(control.controls.fee.value).value()
    }
    this.terms.controls.marketing.get('other').patchValue(otherTotal)
    // Get Total total
    this.terms.controls.marketing.get('total').patchValue(advTotal + otherTotal)
  }

  calculateCommssions = () => {
    let percentageText = this.terms.controls.commision.get('percentage').value
    let percentage = numeral(percentageText).value() / 100
    let gstType = this.terms.controls.commision.get('gstType').value
    let performanceTrigger = numeral(this.terms.controls.commision.get('performanceTrigger').value).value()
    let performanceExample = performanceTrigger * 1.025
    let performanceFixed = numeral(this.terms.controls.commision.get('performanceFixed').value).value()
    let performancePercentage = numeral(this.terms.controls.commision.get('performancePercentage').value).value() / 100
    if (this.terms.controls.agentEstimate.get('type').value == 'single') {
      let price = numeral(this.terms.controls.agentEstimate.get('price').value).value()
      let vendorPrice = numeral(this.terms.get('paymentPrice').value).value()
      let commission = 0.0
      let triggerCommission = 0.0
      let triggerBaseCommission = 0.0
      let vendorCommission = 0.0
      if (this.terms.controls.commision.get('type').value == 'fixed') {
        commission = numeral(this.terms.controls.commision.get('fixed').value).value()
        vendorCommission = commission
      }
      else {
        commission = price * percentage
        vendorCommission = vendorPrice * percentage
      }
      switch (this.terms.controls.commision.get('performance').value) {
        case 'replace':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            triggerCommission = performanceFixed
            triggerBaseCommission = performanceFixed
            if (vendorPrice >= performanceTrigger) vendorCommission = performanceFixed
            if (price >= performanceTrigger) commission = performanceFixed
          }
          else {
            triggerCommission = performanceExample * performancePercentage
            triggerBaseCommission = performanceTrigger * performancePercentage
            if (vendorPrice >= performanceTrigger) vendorCommission = vendorPrice * performancePercentage
            if (price >= performanceTrigger) commission = price * performancePercentage
          }
          break;
        case 'excess':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = commission + performanceFixed
              triggerBaseCommission = commission + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission += performanceFixed
              if (price >= performanceTrigger) commission += performanceFixed
            }
            else {
              triggerCommission = (performanceTrigger * percentage) + performanceFixed
              triggerBaseCommission = (performanceTrigger * percentage) + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission = (performanceTrigger * percentage) + performanceFixed
              if (price >= performanceTrigger) commission = (performanceTrigger * percentage) + performanceFixed
            }
          }
          else {
            if (this.terms.controls.commision.get('type').value == 'fixed') triggerCommission = commission + ((performanceExample - performanceTrigger) * performancePercentage)
            else triggerCommission = (performanceTrigger * percentage) + ((performanceExample - performanceTrigger) * performancePercentage)
            if (this.terms.controls.commision.get('type').value == 'fixed') triggerBaseCommission = commission + ((performanceTrigger - performanceTrigger) * performancePercentage)
            else triggerBaseCommission = (performanceTrigger * percentage) + ((performanceTrigger - performanceTrigger) * performancePercentage)
            if (vendorPrice >= performanceTrigger) {
              if (this.terms.controls.commision.get('type').value == 'fixed') vendorCommission = commission + ((vendorPrice - performanceTrigger) * performancePercentage)
              else vendorCommission = (performanceTrigger * percentage) + ((vendorPrice - performanceTrigger) * performancePercentage)
            }
            else {
              if (this.terms.controls.commision.get('type').value == 'fixed') vendorCommission = commission
              else vendorCommission = ((vendorPrice < performanceTrigger ? vendorPrice : performanceTrigger) * percentage)
            }
            if (price >= performanceTrigger) {
              if (this.terms.controls.commision.get('type').value == 'fixed') commission = commission + ((price - performanceTrigger) * performancePercentage)
              else commission = (performanceTrigger * percentage) + ((price - performanceTrigger) * performancePercentage)
            }
            else {
              if (this.terms.controls.commision.get('type').value == 'fixed') commission = commission
              else commission = ((price < performanceTrigger ? price : performanceTrigger) * percentage)
            }
          }
          break;
        case 'full':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = commission + performanceFixed
              triggerBaseCommission = commission + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission += performanceFixed
              if (price >= performanceTrigger) commission += performanceFixed
            }
            else {
              triggerCommission = (performanceExample * percentage) + performanceFixed
              triggerBaseCommission = (performanceTrigger * percentage) + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission = vendorCommission + performanceFixed
              if (price >= performanceTrigger) commission = commission + performanceFixed
            }
          }
          else {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = commission + (performanceExample * performancePercentage)
              triggerBaseCommission = commission + (performanceTrigger * performancePercentage)
              if (vendorPrice >= performanceTrigger) vendorCommission = commission + (vendorPrice * performancePercentage)
              if (price >= performanceTrigger) commission = commission + (price * performancePercentage)
            }
            else {
              triggerCommission = (performanceExample * percentage) + (performanceExample * performancePercentage)
              triggerBaseCommission = (performanceTrigger * percentage) + (performanceTrigger * performancePercentage)
              if (vendorPrice >= performanceTrigger) vendorCommission = (vendorPrice * percentage) + (vendorPrice * performancePercentage)
              if (price >= performanceTrigger) commission = (price * percentage) + (price * performancePercentage)
            }
          }
          break;
        case 'doubledip':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = commission + performanceFixed
              triggerBaseCommission = commission + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission += performanceFixed
              if (price >= performanceTrigger) commission += performanceFixed
            }
            else {
              triggerCommission = (performanceExample * percentage) + performanceFixed
              triggerBaseCommission = (performanceTrigger * percentage) + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission = vendorCommission + performanceFixed
              if (price >= performanceTrigger) commission = commission + performanceFixed
            }
          }
          else {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = ((performanceExample - performanceTrigger) * performancePercentage) + commission
              triggerBaseCommission = ((performanceTrigger - performanceTrigger) * performancePercentage) + commission
              if (vendorPrice >= performanceTrigger) vendorCommission = ((vendorPrice - performanceTrigger) * performancePercentage) + commission
              if (price >= performanceTrigger) commission = ((price - performanceTrigger) * performancePercentage) + commission
            }
            else {
              triggerCommission = (performanceExample * percentage) + ((performanceExample - performanceTrigger) * performancePercentage)
              triggerBaseCommission = (performanceTrigger * percentage) + ((performanceTrigger - performanceTrigger) * performancePercentage)
              if (vendorPrice >= performanceTrigger) vendorCommission = (vendorPrice * percentage) + ((vendorPrice - performanceTrigger) * performancePercentage)
              if (price >= performanceTrigger) commission = (price * percentage) + ((price - performanceTrigger) * performancePercentage)
            }
          }
          break;
      }
      if (gstType == 'GSTExclusive') {
        commission *= 1.1
        triggerCommission *= 1.1
        triggerBaseCommission *= 1.1
      }
      this.terms.controls.commision.get('estimated').patchValue(commission)
      this.terms.controls.commision.get('estimatedTrigger').patchValue(triggerCommission)
      this.terms.controls.commision.get('estimatedBaseTrigger').patchValue(triggerBaseCommission)
      this.terms.controls.commision.get('estimatedVendor').patchValue(vendorCommission)
      this.terms.controls.commision.get('performanceExample').patchValue(performanceExample)
      let text = this.commissionText.build(this.terms.controls.commision.value)
      this.terms.controls.commision.get('commisionText').patchValue(text)
    }
    else {
      let vendorPrice = numeral(this.terms.get('paymentPrice').value).value()
      let minimumPrice = numeral(this.terms.controls.agentEstimate.get('priceMinimum').value).value()
      let maximumPrice = numeral(this.terms.controls.agentEstimate.get('priceMaximum').value).value()
      let minimumCommission = 0.0
      let maximumCommission = 0.0
      let triggerCommission = 0.0
      let triggerBaseCommission = 0.0
      let vendorCommission = 0.0
      if (this.terms.controls.commision.get('type').value == 'fixed') {
        minimumCommission = numeral(this.terms.controls.commision.get('fixed').value).value()
        maximumCommission = minimumCommission
        vendorCommission = minimumCommission
      }
      else {
        minimumCommission = minimumPrice * percentage
        maximumCommission = maximumPrice * percentage
        vendorCommission = vendorPrice * percentage
      }
      switch (this.terms.controls.commision.get('performance').value) {
        case 'replace':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            triggerCommission = performanceFixed
            triggerBaseCommission = performanceFixed
            if (vendorPrice >= performanceTrigger) vendorCommission = performanceFixed
            if (minimumPrice >= performanceTrigger) minimumCommission = performanceFixed
            if (maximumPrice >= performanceTrigger) maximumCommission = performanceFixed
          }
          else {
            triggerCommission = performanceExample * performancePercentage
            triggerBaseCommission = performanceTrigger * performancePercentage
            if (vendorPrice >= performanceTrigger) vendorCommission = vendorPrice * performancePercentage
            if (minimumPrice >= performanceTrigger) minimumCommission = minimumPrice * performancePercentage
            if (maximumPrice >= performanceTrigger) maximumCommission = maximumPrice * performancePercentage
          }
          break;
        case 'excess':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = minimumCommission + performanceFixed
              triggerBaseCommission = minimumCommission + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission += performanceFixed
              if (minimumPrice >= performanceTrigger) minimumCommission += performanceFixed
              if (maximumPrice >= performanceTrigger) maximumCommission += performanceFixed
            }
            else {
              triggerCommission = (performanceTrigger * percentage) + performanceFixed
              triggerBaseCommission = (performanceTrigger * percentage) + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission = (performanceTrigger * percentage) + performanceFixed
              if (minimumPrice >= performanceTrigger) minimumCommission = (performanceTrigger * percentage) + performanceFixed
              if (maximumPrice >= performanceTrigger) maximumCommission = (performanceTrigger * percentage) + performanceFixed
            }
          }
          else {
            if (this.terms.controls.commision.get('type').value == 'fixed') triggerCommission = vendorCommission + ((performanceExample - performanceTrigger) * performancePercentage)
            else triggerCommission = (performanceTrigger * percentage) + ((performanceExample - performanceTrigger) * performancePercentage)
            if (this.terms.controls.commision.get('type').value == 'fixed') triggerBaseCommission = vendorCommission + ((performanceTrigger - performanceTrigger) * performancePercentage)
            else triggerBaseCommission = (performanceTrigger * percentage) + ((performanceTrigger - performanceTrigger) * performancePercentage)

            // triggerCommission = (performanceTrigger * percentage) + ((performanceExample - performanceTrigger) * performancePercentage)
            // triggerBaseCommission = (performanceTrigger * percentage) + ((performanceTrigger - performanceTrigger) * performancePercentage)
            if (vendorPrice >= performanceTrigger) {
              if (this.terms.controls.commision.get('type').value == 'fixed') vendorCommission = maximumCommission + ((vendorPrice - performanceTrigger) * performancePercentage)
              else vendorCommission = (performanceTrigger * percentage) + ((vendorPrice - performanceTrigger) * performancePercentage)
            }
            else {
              if (this.terms.controls.commision.get('type').value == 'fixed') vendorCommission = maximumCommission
              else vendorCommission = ((vendorPrice < performanceTrigger ? vendorPrice : performanceTrigger) * percentage)
            }
            if (minimumPrice >= performanceTrigger) {
              if (this.terms.controls.commision.get('type').value == 'fixed') minimumCommission = minimumCommission + ((minimumPrice - performanceTrigger) * performancePercentage)
              else minimumCommission = (performanceTrigger * percentage) + ((minimumPrice - performanceTrigger) * performancePercentage)
            }
            else {
              if (this.terms.controls.commision.get('type').value == 'fixed') minimumCommission = minimumCommission
              else minimumCommission = ((minimumPrice < performanceTrigger ? minimumPrice : performanceTrigger) * percentage)
            }
            if (maximumPrice >= performanceTrigger) {
              if (this.terms.controls.commision.get('type').value == 'fixed') maximumCommission = maximumCommission + ((maximumPrice - performanceTrigger) * performancePercentage)
              else maximumCommission = (performanceTrigger * percentage) + ((maximumPrice - performanceTrigger) * performancePercentage)
            }
            else {
              if (this.terms.controls.commision.get('type').value == 'fixed') maximumCommission = maximumCommission
              else maximumCommission = ((maximumPrice < performanceTrigger ? maximumPrice : performanceTrigger) * percentage)
            }
          }
          break;
        case 'full':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = minimumCommission + performanceFixed
              triggerBaseCommission = minimumCommission + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission += performanceFixed
              if (minimumPrice >= performanceTrigger) minimumCommission += performanceFixed
              if (maximumPrice >= performanceTrigger) maximumCommission += performanceFixed
            }
            else {
              triggerCommission = (performanceExample * percentage) + performanceFixed
              triggerBaseCommission = (performanceTrigger * percentage) + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission = vendorCommission + performanceFixed
              if (minimumPrice >= performanceTrigger) minimumCommission = minimumCommission + performanceFixed
              if (maximumPrice >= performanceTrigger) maximumCommission = maximumCommission + performanceFixed
            }
          }
          else {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = maximumCommission + (performanceExample * performancePercentage)
              triggerBaseCommission = maximumCommission + (performanceTrigger * performancePercentage)
              if (vendorPrice >= performanceTrigger) vendorCommission = maximumCommission + (vendorPrice * performancePercentage)
              if (minimumPrice >= performanceTrigger) minimumCommission = minimumCommission + (minimumPrice * performancePercentage)
              if (maximumPrice >= performanceTrigger) maximumCommission = maximumCommission + (maximumPrice * performancePercentage)
            }
            else {
              triggerCommission = (performanceExample * percentage) + (performanceExample * performancePercentage)
              triggerBaseCommission = (performanceTrigger * percentage) + (performanceTrigger * performancePercentage)
              if (vendorPrice >= performanceTrigger) vendorCommission = (vendorPrice * percentage) + (vendorPrice * performancePercentage)
              if (minimumPrice >= performanceTrigger) minimumCommission = (minimumPrice * percentage) + (minimumPrice * performancePercentage)
              if (maximumPrice >= performanceTrigger) maximumCommission = (maximumPrice * percentage) + (maximumPrice * performancePercentage)
            }
          }
          break;
        case 'doubledip':
          if (this.terms.controls.commision.get('performanceType').value == 'fixed') {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = minimumCommission + performanceFixed
              triggerBaseCommission = minimumCommission + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission += performanceFixed
              if (minimumPrice >= performanceTrigger) minimumCommission += performanceFixed
              if (maximumPrice >= performanceTrigger) maximumCommission += performanceFixed
            }
            else {
              triggerCommission = (performanceExample * percentage) + performanceFixed
              triggerBaseCommission = (performanceTrigger * percentage) + performanceFixed
              if (vendorPrice >= performanceTrigger) vendorCommission = vendorCommission + performanceFixed
              if (minimumPrice >= performanceTrigger) minimumCommission = minimumCommission + performanceFixed
              if (maximumPrice >= performanceTrigger) maximumCommission = maximumCommission + performanceFixed
            }
          }
          else {
            if (this.terms.controls.commision.get('type').value == 'fixed') {
              triggerCommission = ((performanceExample - performanceTrigger) * performancePercentage) + maximumCommission
              triggerBaseCommission = ((performanceTrigger - performanceTrigger) * performancePercentage) + maximumCommission
              if (vendorPrice >= performanceTrigger) vendorCommission = ((vendorPrice - performanceTrigger) * performancePercentage) + vendorCommission
              if (minimumPrice >= performanceTrigger) minimumCommission = ((minimumPrice - performanceTrigger) * performancePercentage) + minimumCommission
              if (maximumPrice >= performanceTrigger) maximumCommission = ((maximumPrice - performanceTrigger) * performancePercentage) + maximumCommission
            }
            else {
              triggerCommission = ((performanceExample - performanceTrigger) * performancePercentage) + maximumCommission
              triggerBaseCommission = ((performanceTrigger - performanceTrigger) * performancePercentage) + maximumCommission
              triggerCommission = (performanceTrigger * percentage) + (((performanceExample - performanceTrigger) * (percentage + performancePercentage)))
              triggerBaseCommission = (performanceTrigger * percentage) + (((performanceTrigger - performanceTrigger) * (percentage + performancePercentage)))
              if (vendorPrice >= performanceTrigger) vendorCommission = (vendorPrice * percentage) + ((vendorPrice - performanceTrigger) * performancePercentage)
              if (minimumPrice >= performanceTrigger) minimumCommission = (minimumPrice * percentage) + ((minimumPrice - performanceTrigger) * performancePercentage)
              if (maximumPrice >= performanceTrigger) maximumCommission = (maximumPrice * percentage) + ((maximumPrice - performanceTrigger) * performancePercentage)
            }
          }
          break;
      }
      if (gstType == 'GSTExclusive') {
        minimumCommission *= 1.1
        maximumCommission *= 1.1
        triggerCommission *= 1.1
        triggerBaseCommission *= 1.1
      }
      this.terms.controls.commision.get('estimatedMinimum').patchValue(minimumCommission)
      this.terms.controls.commision.get('estimatedMaximum').patchValue(maximumCommission)
      this.terms.controls.commision.get('estimatedTrigger').patchValue(triggerCommission)
      this.terms.controls.commision.get('estimatedBaseTrigger').patchValue(triggerBaseCommission)
      this.terms.controls.commision.get('estimatedVendor').patchValue(vendorCommission)
      this.terms.controls.commision.get('performanceExample').patchValue(performanceExample)
      let text = this.commissionText.build(this.terms.controls.commision.value)
      this.terms.controls.commision.get('commisionText').patchValue(text)
    }
  }

}
