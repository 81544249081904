import 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service'
import { AppSessionService } from './app-session.service'
import { AmplifyService } from 'aws-amplify-angular'
import { UserService } from './user/user.service';
import { PermissionService } from './permission.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs/Rx';

@Injectable()
export class AuthService {
  newPasswordUser: any

  amplifyStateSubject = new BehaviorSubject<any>(null);

  constructor(
    public amplify: AmplifyService,
    public api: ApiService,
    public app: AppSessionService,
    public user: UserService,
    public router: Router,
    public builder: FormBuilder,
    public permission: PermissionService
  ) { }

  refresh = {
    signin: async (options) => {
      return new Promise(async resolve => {
        await this.user.load()
        let users: any = await this.user.find({ email: options.email.toLowerCase() })

        // If there is only 1 user returned from the User Find.
        if (users._id) {
          if (users.permissions && !users.permissions.god) {
            this.app.user = users
            this.app.signedin = true
            this.permission.initialise.user(this.app.user)
          }
          if (!this.app.user.cognitoSignupComplete) { await this.api.post('user/signup/complete', { email: options.email }) }
          return resolve({ ok: true, user: users, error: {} })
        }

        // If there are multiple Users connected to the email, user must select which account to use.
        return resolve({ ok: true, users, error: {} })
      })
    }
  }

  forgot = (options: any) => {
    return new Promise((resolve, reject) => {
      let auth = this.amplify.auth()
      auth.forgotPassword(options.email.toLowerCase())
        .then((result) => {
          resolve({ ok: true, result: result, error: {} })
        }).catch((error) => {
          console.log(error)
          resolve({ ok: false, error: error })
        })
    })
  }

  signin = async (options: any) => {
    return new Promise(async resolve => {
      let auth = this.amplify.auth()
      let result
      try {
        result = await auth.signIn(options.email.toLowerCase(), options.password)
      }
      catch (error) {
        result = { error: error }
      }
      this.newPasswordUser = result
      if (result.challengeName == 'NEW_PASSWORD_REQUIRED') {
        return resolve({ ok: false, user: this.app.user, error: { code: 'NEW_PASSWORD_REQUIRED' } })
      }
      if (!result.error) {
        await this.user.load()
        let users: any = await this.user.find({ email: options.email.toLowerCase() })

        // If there is only 1 user returned from the User Find.
        if (users._id) {
          if (users.permissions && !users.permissions.god) {
            this.app.user = users
            this.app.signedin = true
            this.permission.initialise.user(this.app.user)
          }
          if (!this.app.user.cognitoSignupComplete) { await this.api.post('user/signup/complete', { email: options.email }) }
          return resolve({ ok: true, user: users, error: {} })
        }

        // If there are multiple Users connected to the email, user must select which account to use.
        return resolve({ ok: true, users, error: {} })

      }
      else {
        return resolve({ ok: false, user: this.app.user, error: result.error })
      }
    })
  }

  confirm = {

    signin: (options: any) => {
      return new Promise((resolve, reject) => {
        let auth = this.amplify.auth()
        auth.confirmSignIn(options.email.toLowerCase(), options.code).then((result) => {
          resolve({ ok: true, result: result, error: {} })
        }).catch((error) => {
          console.log(error)
          resolve({ ok: false, error: error })
        })
      })
    },

    forgot: (options: any) => {
      return new Promise((resolve, reject) => {
        let auth = this.amplify.auth()
        auth.forgotPasswordSubmit(options.email.toLowerCase(), options.code, options.password)
          .then(async (result) => {
            console.log('Forgot password complete!')
            resolve({ ok: true, result: result, error: {} })
          }).catch((error) => {
            console.log(error)
            resolve({ ok: false, error: error })
          })
      })
    },

    newPassword: (options: any) => {
      return new Promise((resolve, reject) => {
        let auth = this.amplify.auth()
        auth.completeNewPassword(this.newPasswordUser, options.password, [])
          .then(async (result) => {
            resolve({ ok: true, result: result, error: {} })
          }).catch((error) => {
            console.log(error)
            resolve({ ok: false, error: error })
          })
      })
    }
  }

  form = {
    build: {
      signIn: (): FormGroup => {
        return this.builder.group({
          email: ['', Validators.compose([Validators.required, Validators.email])],
          password: ['', Validators.compose([Validators.required])],
        })
      },
      confirmForm: (): FormGroup => {
        return this.builder.group({
          code: ['', Validators.compose([Validators.required])]
        })
      },
      forgotConfirmForm: (): FormGroup => {
        return this.builder.group({
          code: ['', Validators.compose([Validators.required])],
          password: ['', Validators.compose([Validators.required])],
          confirmPassword: ['', Validators.compose([Validators.required])]
        })
      },
      forgotForm: (): FormGroup => {
        return this.builder.group({
          email: ['', Validators.compose([Validators.required, Validators.email])]
        })
      },
      newPasswordForm: () => {
        return this.builder.group({
          password: ['', Validators.compose([Validators.required])],
          confirmPassword: ['', Validators.compose([Validators.required])]
        })
      }
    }
  }
}
