import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormBuilder, Form, FormArray } from '@angular/forms';

import { HelperService } from '../helper.service'
import { AuthorityService } from '../authority/authority.service';
import { switchPages } from 'src/app/pages/pdftron-test/functions/switch-documents';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  public detail: FormGroup
  public notifications: FormGroup
  public permissions: FormGroup
  public listingPerms: FormGroup
  public dashboardPerms: FormGroup
  public agencyAdminPerms: FormGroup
  public delegates: FormGroup

  signin: FormGroup
  signup: FormGroup
  confirm: FormGroup
  forgot: FormGroup
  forgotConfirm: FormGroup
  newPassword: FormGroup


  constructor(private serivce: AuthorityService, private builder: FormBuilder, private helper: HelperService) {

    this.notifications = this.builder.group({
      propertyListed: [true],
      incomingOffers: [true],
      propertySold: [true],
      exclAuthEnding: [true],
    })
    this.listingPerms = this.builder.group({
      create: [false],
      signingAuth: [false],
      viewAllPreListing: [false],
      viewAllPreListingType: ['View Property Only'],
      viewAllListing: [false],
      viewAllListingType: ['View Property Only'],
      viewAllInactiveListing: [false],
      viewAllInactiveListingType: ['View Property Only'],
    })
    this.dashboardPerms = this.builder.group({
      reportA: [false],
      reportB: [false],
      reportC: [false],
      reportD: [false],
      reportE: [false],
    })
    this.agencyAdminPerms = this.builder.group({
      admin: [false],
      updateDetail: [false],
      updateCommShare: [false],
      manageUsers: [false],
      manageReports: [false],
    })

    this.permissions = this.builder.group({
      access: [false],
      copyOf: [''],
      // God Permission is set manual in DB.
      god: [false],
      listing: this.listingPerms,
      dashboard: this.dashboardPerms,
      agency: this.agencyAdminPerms,
    })

    this.delegates = this.builder.group({
      allowAccessTo: [''],
      emailAccess: ['']
    })


    this.detail = this.builder.group({
      _id: [''],
      active: [true, Validators.required],
      address: [''],
      admin: [''],
      agency: [''],
      agencyId: [''],
      cognito: [''],
      cognitoSignupComplete: [''],
      contract: [''],
      devices: [''],
      email: ['', Validators.required],
      fax: [''],
      mobile: ['', Validators.required],
      name: [''],
      firstName: ['', Validators.required],
      middleName: [''],
      surname: ['', Validators.required],
      licenseNo: [''],
      role: ['', Validators.required],
      pending: [''],
      phone: [''],
      postcode: [''],
      state: [''],
      suburb: [''],
      image: [''],
      imageUrl: [''],
      defaults: this.serivce.form.build.terms(),
      notifications: this.notifications,
      permissions: this.permissions,
      delegates: this.delegates
    })


    this.signin = this.builder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required])],
    })

    this.signup = this.builder.group({
      name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      mobile: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
      confirmPassword: ['', Validators.compose([Validators.required])],
      agentAlready: [false],
      agent: [false],
      agencyName: [''],
      agencyAddress: [''],
    })

    // if (this.app.env.signin.enabled) {
    //   this.signin.controls.email.patchValue(this.app.env.signin.user)
    //   this.signin.controls.password.patchValue(this.app.env.signin.password)
    // }

    this.confirm = this.builder.group({
      code: ['', Validators.compose([Validators.required])]
    })

    this.forgotConfirm = this.builder.group({
      email: ['', Validators.compose([Validators.required])],
      code: ['', Validators.compose([Validators.required])],
      password: ['', Validators.compose([Validators.required])],
    })

    this.forgot = this.builder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])]
    })

    this.newPassword = this.builder.group({
      password: ['', Validators.compose([Validators.required])],
      confirmPassword: ['', Validators.compose([Validators.required])]
    })

    this.subscriptions.permissions(this.permissions)
  }

  cleanup = async () => {
    // Reset Forms
    this.confirm.reset()
    this.forgotConfirm.reset()
    this.forgot.reset()
    this.newPassword.reset()
    this.signin.reset()
    this.signup.reset()
    this.detail.reset()
  }

  agencyPage = {
    subscriptions: async () => {
      this.detail.get('firstName').valueChanges.subscribe((firstName) => {
        this.detail.get('name').patchValue(`${firstName} ${this.detail.controls.surname.value}`)
      })
      this.detail.get('surname').valueChanges.subscribe((surname) => {
        this.detail.controls.name.patchValue(`${this.detail.controls.firstName.value} ${surname}`)
      })
    }
  }
  // notificationsList = {
  //   import: (options) => {
  //     if (options) {
  //       let formArray = this.detail.get('notifications') as FormArray
  //       while (formArray.length !== 0) formArray.removeAt(0)
  //       options.forEach(noti => {
  //         formArray.push(
  //           this.builder.group({
  //             name: [noti.name],
  //           }))
  //       });
  //     }
  //   },
  //   add: (option) => {
  //     let formArray = this.detail.get('notifications') as FormArray
  //     formArray.push(
  //       this.builder.group({
  //         name: [option.name],
  //       }))
  //   },
  //   remove: (name) => {
  //     let formArray = this.detail.get('notifications') as FormArray
  //     let i = 0
  //     for(let itm of formArray.value) {
  //       if (itm.name == name) {
  //         break;
  //       }
  //       i++
  //     }
  //     formArray.removeAt(i)
  //   }
  // }

  subscriptions = {
    permissions: (permissionsForm: FormGroup): void => {
      // console.log(permissionsForm.value)
      permissionsForm.valueChanges.subscribe(_ => {
        console.log(permissionsForm.value)
      })

      permissionsForm.get('access').valueChanges.subscribe(value => {
        console.log(`Access Value : ${value}`)
        switch (value) {
          case 'all':
            this.modifyPermissions.listing(permissionsForm.get('listing') as FormGroup, true)
            this.modifyPermissions.agencyAdmin(permissionsForm.get('agency') as FormGroup, true)
            permissionsForm.get('copyOf').patchValue(null, { emitEvent: false })
            break;
          case 'no':
            this.modifyPermissions.listing(permissionsForm.get('listing') as FormGroup, false)
            this.modifyPermissions.agencyAdmin(permissionsForm.get('agency') as FormGroup, false)
            permissionsForm.get('copyOf').patchValue(null, { emitEvent: false })
            break;
          case 'copy':
          case 'custom':
          default:
            break;
        }
      })

      // permissionsForm.get('copyOf').valueChanges.subscribe( value => {
      //   if (value) { permissionsForm.get('access').patchValue('copy') }

      //   let user = this.
      //   console.log('CopyOf')
      //   console.log(value)
      //   // permissionsForm.get('access').patchValue('copy', {})
      // })
    }
  }

  modifyPermissions = {
    agencyAdmin: (adminForm: FormGroup, newValue: boolean): void => {
      adminForm.get('admin').patchValue(newValue, { emitEvent: false })
      adminForm.get('updateDetail').patchValue(newValue, { emitEvent: false })
      adminForm.get('updateCommShare').patchValue(newValue, { emitEvent: false })
      adminForm.get('manageUsers').patchValue(newValue, { emitEvent: false })
      adminForm.get('manageReports').patchValue(newValue, { emitEvent: false })
    },
    listing: (listingForm: FormGroup, newValue: boolean): void => {
      let viewWording = newValue ? 'View & Edit' : 'View Property Only'
      listingForm.get('create').patchValue(newValue, { emitEvent: false })
      listingForm.get('signingAuth').patchValue(newValue, { emitEvent: false })
      listingForm.get('viewAllPreListing').patchValue(newValue, { emitEvent: false })
      listingForm.get('viewAllPreListingType').patchValue(viewWording, { emitEvent: false })
      listingForm.get('viewAllListing').patchValue(newValue, { emitEvent: false })
      listingForm.get('viewAllListingType').patchValue(viewWording, { emitEvent: false })
      listingForm.get('viewAllInactiveListing').patchValue(newValue, { emitEvent: false })
      listingForm.get('viewAllInactiveListingType').patchValue(viewWording, { emitEvent: false })
    },
    dashboard: (dashboardForm: FormGroup): void => {

    }
  }
}
