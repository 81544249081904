import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-withdraw-amend',
  templateUrl: './withdraw-amend.component.html',
  styleUrls: ['./withdraw-amend.component.scss'],
})
export class WithdrawAmendComponent implements OnInit {

  state: string
  header: string
  message: string
  submessage: string

  constructor(public modal: ModalController, public navParams: NavParams) { }

  ngOnInit() {
    this.state = this.navParams.data.state
    this.header = this.navParams.data.header
    this.message = this.navParams.data.message
    this.submessage = this.navParams.data.submessage
  }

  dismiss = (result: string) => {
    this.modal.dismiss({ result: result });
  }
}
