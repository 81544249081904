import 'rxjs/Rx';
import { Injectable } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular'
import { EnvironmentService } from './environment.service'
import { PermissionService } from './permission.service'

@Injectable()
export class AppSessionService {
  device: any
  account: any
  user: any
  trueAccountEmail : string = null
  userImage: string
  signedin: boolean = true
  cdn: string
  notificationCount: any

  constructor(
    public amplify: AmplifyService, 
    public env: EnvironmentService, 
    public permission: PermissionService
  ) {
    this.account = { id: '5af0dfd892d8b513432f335b' }
    this.user = {}
    this.device = ''
  }

  async url(name: string): Promise<any> {
    let storage = this.amplify.storage()
    return await storage.get(name, { level: 'public' })
  }

}
