import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {

  header: string;
  message: string;
  submessage: string;
  button: string;

  constructor(public modal: ModalController, public navParams: NavParams) { }

  ngOnInit() {
    this.header = this.navParams.data.header;
    this.message = this.navParams.data.message;
    this.submessage = this.navParams.data.submessage;
    this.button = this.navParams.data.button;
  }

  dismiss = (result: boolean) => {
    this.modal.dismiss({ result });
  }

}
