/// <reference path="../../../node_modules/@types/googlemaps/index.d.ts" />
// npm i @types/googlemaps

import 'rxjs/Rx';
import { Injectable, NgZone } from '@angular/core';


interface options {
	filter: any,
	bounds: any,
}
interface AutoComplete {
	input: string;
	predictedItems: any[];
	location: any;
	placeid: string;

	initialise(): void;
	updateSearchResults(options?): Promise<void>;
	selectSearchResult(item): Promise<any>;
	clearPredictions(event): void
}

@Injectable()
export class GoogleService {
	// Google Maps Address autocomplete
	private GoogleAutocomplete: google.maps.places.AutocompleteService;
	private GoogleGeocoder: google.maps.Geocoder;

	constructor(private zone: NgZone) {
		this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
		this.GoogleGeocoder = new google.maps.Geocoder();

		// Init Autocomplete
		this.AutoComplete.input = ''
		this.AutoComplete.predictedItems = []
		this.AutoComplete.location = ''
		this.AutoComplete.placeid = ''
	}


	/*
		Basic Implementation : AgentPower - listing.ts
		
		<div class="searchbar-wrapper">
			<ion-searchbar [(ngModel)]="google.AutoComplete.input" [ngModelOptions]="{standalone:true}"
				(ionInput)="ui.autocomplete.updateSearchResults(true)" placeholder="Search for a place"></ion-searchbar>
			<div class="searchbar-list-wrapper">
				<ion-list class="searchbar-list"  [hidden]="google.AutoComplete.predictedItems.length == 0">
					<ion-item *ngFor="let item of google.AutoComplete.predictedItems" tappable
						(click)="ui.autocomplete.click(item)">
						{{ item.description }}
					</ion-item>
				</ion-list>
			</div>
		</div>
	*/

	AutoComplete: AutoComplete = {
		input: '',
		predictedItems: [],
		location: '',
		placeid: '',

		initialise: () => {
			this.AutoComplete.input = ''
			this.AutoComplete.predictedItems = []
		},

		/*
			Clears predicted items when searchbar is no longer in focus
		*/
		clearPredictions: (event) => {
			console.log('Clear Predictions')
			this.zone.run(() => {
				this.AutoComplete.predictedItems = []
			})
		},

		/*
			Parameter is just Filter, this is a true of false Method that takes 
				in a possible prediction and returns whether it is a valid prediction.
		*/
		updateSearchResults: (options?) => {
			return new Promise(resolve => {
				if (this.AutoComplete.input == '') {
					this.AutoComplete.predictedItems = [];
					return resolve();
				}
				var defaultBounds = new google.maps.LatLngBounds(
					new google.maps.LatLng(-33.978827, 140.934387),
					new google.maps.LatLng(-39.105713, 150.267807));
				this.GoogleAutocomplete.getPlacePredictions({ input: this.AutoComplete.input, componentRestrictions: { country: "au" }, bounds: defaultBounds },
					(predictions, status) => {
						this.AutoComplete.predictedItems = [];
						this.zone.run(() => {
							if (predictions) {
								predictions.forEach((prediction) => {
									if (options && options.filter) {
										if (options.filter(prediction)) {
											this.AutoComplete.predictedItems.push(prediction);
										}
									} else {
										this.AutoComplete.predictedItems.push(prediction);
									}
								});

								if (this.AutoComplete.predictedItems.length == 0) {
									this.AutoComplete.predictedItems.push({ description: "Please specify an exact address in Victoria." });
								}
							}
						});

						resolve()
					});
			})
		},

		selectSearchResult: async (item) => {
			return new Promise((resolve, reject) => {
				if (item.place_id) {
					this.AutoComplete.location = item
					this.AutoComplete.placeid = this.AutoComplete.location.place_id
					this.zone.run(() => {
						this.GoogleGeocoder.geocode({ placeId: item.place_id }, (results, status) => {
							if (results) {
								if (results[0].address_components.length == 6) {
									this.AutoComplete.input = item.description
								} else if (results[0].address_components.length == 7) {
									this.AutoComplete.input = item.description
								} else if (results[0].address_components.length == 8) {
									this.AutoComplete.input = results[0].formatted_address
								}

								this.AutoComplete.predictedItems = []
								return resolve(results)
							}
						})
					});
				}
			})
		}
	}
}