import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { AppSessionService } from 'src/app/services/app-session.service';
import * as moment from 'moment'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  moment = moment
  
  constructor(public app: AppSessionService, public user: UserService, public modal: ModalController) {
    this.ui.load()
  }

  ngOnInit() { }

  ui = {
    clear: () => {
      this.ui.notifications = []
      this.user.notification.clear(this.app.user._id)
    },
    load: async () => {
      this.ui.notifications = await this.user.notification.list(this.app.user.notificationDate, this.app.user._id)
      this.app.notificationCount = this.ui.notifications.length
    },
    notifications: [],
    notification: {
      listed: { icon: ['fas', 'clipboard'], class: '', type: n => 'Sale authority signed' },
      sold: { icon: ['fas', 'handshake'], class: 'green', type: n => 'Property has been sold' },
      exclusive: { icon: ['fas', 'home'], class: 'orange', type: n => 'Exclusive authority expiring' },
      offer: { icon: ['fas', 'sack-dollar'], class: 'red', type: n => 'New offer' },
    },

  }


}
