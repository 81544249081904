import { Injectable } from '@angular/core';
import { FormService } from './form.service'
import { ApiService } from '../api.service'
import { AppSessionService } from '../app-session.service';
import { HelperService } from '../helper.service';
import { PdfService } from '../pdf.service';
// import { ListingService } from '../listing/listing.service'
import { AmendmentChecksService } from './amendment-checks.service';
import * as numeral from 'numeral'
import { DataService } from '../offer/data.service';
import { FormArray } from '@angular/forms';
import { PageService } from '../listing/page.service';
import { AmendmentService } from './amendment.service';


@Injectable({
  providedIn: 'root'
})
export class AuthorityService {

  constructor(
    public data: DataService,
    private app: AppSessionService,
    private helper: HelperService,
    private api: ApiService,
    public form: FormService,
    public pdf: PdfService,
    public page: PageService,
    public amendment: AmendmentService,
  ) {

  }

  isPrepared = () => {
    return this.form.detail.controls.prepared.value && this.page.authority.state != 'amending'
  }
  isLocked = () => {
    return this.form.detail.controls.locked.value && this.page.authority.state != 'amending'
  }

  isCreated = () => {
    // console.log('isCreated >'+this.form.detail.controls.status.value + '<')
    // console.log(['', 'blank'].indexOf(this.form.detail.controls.status.value) != -1)
    return ['', 'blank'].indexOf(this.form.detail.controls.status.value) == -1
  }

  save = async (): Promise<{ error, response }> => {
    return new Promise(async resolve => {
      let l: any = {}
      l = Object.assign(l, this.form.terms.value)
      l = Object.assign(l, this.form.rebates.value)

      return resolve({ error: null, response: l })
    })

  }

  load = (listing) => {
    this.form.subscriptions()
    this.data.massage.authority.load(listing)
    // Setup terms
    this.form.terms.patchValue(listing)
    console.log(listing)
    if (!listing.paymentPrice) { this.form.terms.get('paymentPriceTBA').patchValue(true) }
    if (!listing.paymentDays) { this.form.terms.get('paymentDaysTBA').patchValue(true) }
    this.form.marketing.get('payable').patchValue((listing.marketing && listing.marketing.payable && listing.marketing.payable.toLowerCase()) || 'signing');

    for (let feeType of ['advertising', 'other']) {
      for (let fee of (listing.marketing[`${feeType}Fees`] || [])) {
        const feeForm = this.form.build.fee();
        feeForm.patchValue(fee);
        (this.form.marketing.get(`${feeType}Fees`) as FormArray).push(feeForm);
      }
    }

    // Setup Rebates and Commission Sharing Variables
    this.form.rebates.patchValue(listing)
    this.form.commissionsList.import(listing.commisions)

    if (listing.commisionSharing || this.app.user.agency.commisionSharing) {
      this.form.rebates.get('commisionSharing').patchValue(true)
    } else {
      this.form.rebates.get('commisionSharing').patchValue(false)
    }

  }

  loadReadOnly = (authority) => {
    // Setup Details
    this.form.detail.patchValue(authority)
  }

  // Gets Property price based on the currently loaded property
  getPropertyPrice = (estimate) => {
    // let estimate = this.form.agentEstimate.value
    if (!estimate) return ''
    let result = ""
    switch (estimate.type) {
      case "single":
        result = estimate.price ? `${numeral(estimate.price).format('$0,0')}     ` : ""
        break;
      case "range":
        result = estimate.priceMinimum ? `${numeral(estimate.priceMinimum).format('$0,0')} - ${numeral(estimate.priceMaximum).format('$0,0')}     ` : ""
        break;
    }
    // this.form.agentEstimate.controls.priceText.patchValue(result)
    return result
  }

  document = {

    email: {
      resend: async (listingId, emailAddresses) => {
        return new Promise(async resolve => {
          await this.api.post('listing/authority/document/resend', { id: listingId, emails: emailAddresses })
          resolve()
        })
      }
    },

    /*
      PARAMS:
        listingId: string,
        user: this.app.user.name,
        commissionSharing: bool,
        agentList: []
    */
    prepare: async (options) => {
      return new Promise(async resolve => {
        console.log('Preparing...')
        console.log(options)
        console.log({
          id: options.listingId,
          user: options.user,
          commissionSharing: options.commissionSharing,
        })

        let { error, fileId }: any = await this.api.get('listing/authority/combine', {
          id: options.listingId,
          user: options.user,
          commissionSharing: options.commissionSharing,
          // commisionSharingAuthority: options.listingCommissionSharing ? true : false,
          // commisionSharingAgency: options.agencyCommissionSharing ? true : false,
          // commSharingLength: options.listingCommissionsLength
        })
        //let pdfUrl = await this.app.url(`${this.listing.selected._id}-authority.pdf`)
        // if (!listing) listing.selected.authority = { prepared: null, preparedBy: null, vendors: [], sends: [], draftSent: false, locked: null, pdfId: null, agent: { signed: null } } 

        // Reset form
        this.form.detail.reset()

        // Update Details
        this.form.detail.get('status').patchValue('uploaded')
        this.form.detail.get('initialPrepare').patchValue(true)
        this.form.detail.get('PDFNames').patchValue([fileId])

        // Set init Values for authority
        // this.form.detail.get('prepared').patchValue(new Date())
        // this.form.detail.get('preparedBy').patchValue(options.user)

        // if (listing.currentSignature) { listing.currentSignature = null }

        // TODO : Move to UI
        // Lock forms
        // this.form.toLock(listing, true, false)

        return resolve({ error: null, response: `${fileId}-authority.pdf` })
      })
    },

    /*
      Once initial prepare is complete, Switch the initial prepare in Mongo to false,
    */
    completeInitPrepare: async (listingId) => {
      return new Promise(async resolve => {
        await this.api.post('listing/authority/prepare/complete', { _id: listingId })
        return resolve({ error: null, response: true })
      })
    },

    /*
      Saves document to Mongo under its correct name

      PARAMS:
        instance: PDFTron WebViewer Instance
        PDFNames: [<pdf names>]
        flatten: bool 
    */
    save: async (instance, PDFNames, flatten) => {
      await this.pdf.saveDocument(instance, `${PDFNames[PDFNames.length - 1]}-authority.pdf`, flatten)
    },

    /**
      Completes Signing (OLD API but is still needed at the moment)
      Sends emails to the clients
      @param: listingId {string}
     */
    complete: async (listingId, agentId, vendorsList) => {
      return new Promise(async resolve => {
        // Add signatures until complete == true
        let complete = false
        for (let vendor of vendorsList) {
          let vendorComplete = await this.document.signature.save(vendor.id, listingId, 'authority-vendor')
          if (vendorComplete) complete = true
        }
        let agentComplete = await this.document.signature.save(agentId, listingId, 'authority-agent')
        if (agentComplete) complete = true
        if (complete) {
          // this.ui.authority.document.locked = true
          console.log('Completely Signed.')
        }

        // Set status
        this.form.detail.get('status').patchValue('signed')

        return resolve({ error: null, response: true })
      })
    },

    /*
      Resets authority params in Mongo.
      NOTE - be sure to run a listing find after unlocking.

      PARAMS
        listingId : string
    */
    cancel: async (listingId) => {
      return new Promise(async resolve => {
        // Unlock API Call
        await this.api.get('listing/authority/unlock', { id: listingId })
        return resolve({ error: null, response: true })
      })
    },

    signature: {
      save: async (signeeId, listingId, type) => {
        return new Promise(async resolve => {
          let currentSignature = {
            signeeId: signeeId,
            originalType: type
          }
          let signed: any = await this.api.post('listing/signature/sign', {
            id: listingId,
            signature: currentSignature,
            type: currentSignature.originalType,
            user: this.app.user.name
          })
  
          if (signed && signed.signed == true) {
            return resolve(true)
          } else {
            return resolve()
          }
        })
      },
      email: async (listingId, vendorId) => {
        await this.api.get('listing/signature/remoteSign', { listingId: listingId, type: 'vendor', document: 'authority', id: vendorId })
        return Promise.resolve({ error: null, response: true })
      },
      next: async (instance, commissionSharing, authorityAmend) => {
        return new Promise(async resolve => {
          await this.pdf.gotoNextSignature(instance, commissionSharing, !authorityAmend)
          return resolve({ error: null, response: true })
        })
      },
      count: async (listingId, PDFNames) : Promise<{ error: any, response: any }> => {
        return new Promise(async resolve => {
          let annots: any = await this.api.get('listing/authority/annots', { id: listingId })
          let count = 0

          if (annots) {
            let annotList = annots[PDFNames[PDFNames.length - 1]]
            if (annotList) { count = annotList.length }
          }
          return resolve({ error: null, response: count })
        })
      },
      total: async (vendorList, commissionSharing, amendment) : Promise<{ error: any, response: any }> => {
        return new Promise(async resolve => {
          let count = vendorList.length + 1 // 1 is for agent

          // Double number of signatures if commissionSharing 
          if (commissionSharing && !amendment) {
            count = count * 2
          }
  
          return resolve({ error: null, response: count })
        })
      },
      userCount: async (listingId, vendorId, PDFNames) : Promise<{ error: any, response: any }> => {
        return new Promise(async resolve => {
          let count = 0
          let annots: any = await this.api.get('listing/authority/annots', { id: listingId })
          // console.log(annots)
          // console.log(listing.selected.authority.PDFNames.length)
          if (annots) {
            let versionAnnots = annots[PDFNames[PDFNames.length - 1]]
            if (versionAnnots) {
              for (let a of versionAnnots) {
                if (a.userId == vendorId) {
                  count++
                }
              }
            }
          }
          return resolve({ error: null, response: count })
        })
      },
      userTotal: async (commissionSharing, amendment) : Promise<{ error: any, response: any }> => {
        return new Promise(async resolve => {
          let count = 0
          count += 1 // 1 is for that vendor
          if (commissionSharing) {
            if (!amendment) {
              count = count * 2
            }
          }
          return resolve({ error: null, response: count })
        })
      }
    },
  }

  pdftron = {
    /*
      PARAMS:
        listingId: string
        agentList: []
    */
    addSigningWidgets: async (instance, document, PDFNames, options) : Promise<{ error: any, response: any }> => {
      return new Promise( async resolve => {
        console.log('Adding Signing Widgets!')
        if (!options.amendment) {
          // Get PDF url and create document to PDFTron
          for (let vendor of options.vendors) {
            await this.pdf.createSignatures(instance, document, vendor.id, 'Vendor')
          }
          // Create Signatures
          await this.pdf.createSignatures(instance, document, options.listingAgentId, 'Agent')
        } else if (options.amendment) {
          for (let vendor of options.vendors) {
            await this.pdf.createAmendSignatures(instance, document, PDFNames, vendor.id, 'Vendor')
          }
          await this.pdf.createAmendSignatures(instance, document, PDFNames, options.listingAgentId, 'Agent')
  
          await this.pdftron.variationWarning(instance, document)
        }
        await this.pdf.document.refreshFieldAppearances()
        this.pdf.initialiseSignature()
  
        return resolve({ error: null, response: true })
      })
    },

    variationWarning: async (instance, document) => {
      return new Promise(async resolve => {
        console.log('Begin variation warning')
        let PDFNet = instance.PDFNet
  
        let pageCount = await document.getPageCount()
        let tempPage = await document.getPage(1)
        let pageWidth = await tempPage.getPageWidth(0)
  
        let annotXStart = 100
        let annotXEnd = pageWidth - 100
        let annotYStart = 20
        let annotYEnd = 35
  
  
        const txtannot = await PDFNet.FreeTextAnnot.create(document, await PDFNet.Rect.init(annotXStart, annotYStart, annotXEnd, annotYEnd));
        const redColorPt = await PDFNet.ColorPt.init(1, 0, 0, 0);
        await txtannot.setLineColor(redColorPt, 3)
        await txtannot.setTextColor(redColorPt, 3)
        await txtannot.setContents('This Authority has been updated, please see attached "Deed of Variation"')
        const solidLine = await PDFNet.AnnotBorderStyle.create(PDFNet.AnnotBorderStyle.Style.e_solid, 1, 0, 0);
        await txtannot.setBorderStyle(solidLine, true);
        await txtannot.setQuaddingFormat(1);
        await txtannot.setFontSize(9)
  
        // Properties
        txtannot.Locked = true
        txtannot.Hidden = false
        for (let i = 1; i <= pageCount - 2; i++) {
          let page = await document.getPage(i)
          await page.annotPushBack(txtannot);
          await txtannot.refreshAppearance();
        }
        return resolve()
      })
    },

    annotations: {
      save: async (instance, listingId, PDFNames, annotation) : Promise<{ error: any, response: any }> => {
        return new Promise(async resolve => {
          console.log(listingId)
          console.log(PDFNames)
          console.log(annotation)
          let version = PDFNames[PDFNames.length - 1]
          let res = await this.pdf.saveAnnotations(instance, listingId, annotation, version)
          return resolve({ error: null, response: res })
        })
      },
    },
  }
}

