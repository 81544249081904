import { Component, HostListener, ViewChild } from '@angular/core';
import { LoadingService } from 'src/app/services/loading.service';
import { Platform, IonMenu, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { NavigationService } from './services/navigation.service';
import { NotificationsComponent } from './modals/notifications/notifications.component';
import { AppSessionService } from './services/app-session.service';
import { ComingSoonService } from './services/coming-soon.service';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthService } from './services/auth.service';
import { Auth } from 'aws-amplify';
import { ListingService } from './services/listing/listing.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  @HostListener('window:beforeunload', ['$event']) eventHandler($event) {
    if (window.location.href.indexOf('sign/') == -1) {
      if (this.app.env.leavingAlert) {
        $event.returnValue = "Your data will be lost"
        $event.preventDefault()
      }
    }
  }
  // @HostListener('document:mousemove', ['$event']) 
  // onMouseMove(e) {
  //   console.log(e);
  //   if (this.nav.getCurrentPage() !== 'auth') {
  //     if (!this.app.user._id) {
  //       this.nav.setRoot('auth')
  //     }
  //   }
  // }

  private initState: string = null

  constructor(
    public app: AppSessionService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public nav: NavigationService,
    public modal: ModalController,
    public comingSoon: ComingSoonService,
    public amplify: AmplifyService,
    public auth: AuthService,
    public router: Router,
    public loading: LoadingService,
    public listing: ListingService
  ) {
    this.initializeApp();
    
    // This is required for Auto Signin.
    this.amplify.authStateChange$.subscribe(async (state) => {
      this.auth.amplifyStateSubject.next(state)
    })
  }

  navigateHome = async () => {
    let currentPage = this.nav.getCurrentPage()

    if (currentPage === 'property-list') {
      await this.loading.show("Loading listings...")
      this.listing.reset()
      await this.listing.load()
      await this.loading.hide()
    }

    else {
      this.nav.setRoot('property-list')
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      if (this.platform.is('ios')) {
        console.log('is ios');
        this.statusBar.overlaysWebView(false);
      }
      this.splashScreen.hide();
    });
  }

  signOut = async () => {
    await Auth.signOut()
    this.nav.setRoot('auth')
    this.app.user = {}
    this.app.signedin = false
  }

  switchAccount = async () => {
    let email = this.app.trueAccountEmail ? this.app.trueAccountEmail : this.app.user.email
    this.nav.setRoot('auth', { state: 'selectAccount', email })
    this.app.user = {}
    this.app.signedin = false
  }

  @ViewChild('menu', { static: false }) menu;
  navigate = (page) => {
    this.nav.setRoot([page]);
    // close menu
    this.menu.close();
  }

  openNotificationPanel = async () => {
    const modal = await this.modal.create({
      component: NotificationsComponent,
      cssClass: 'ap-notification-modal'
    });
    await modal.present();
  }
}
