import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { LoadingService } from 'src/app/services/loading.service';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { ListingService } from 'src/app/services/listing/listing.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ContractService } from 'src/app/services/contract/contract.service';
import { AppSessionService } from 'src/app/services/app-session.service';
import { OfferService } from 'src/app/services/offer/offer.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {

  displayOther = true;
  topic: string;
  state: 'list' | 'buttons' | 'custom' | 'sent' = 'list';
  form: FormGroup
  emailList: any[]

  constructor(public app: AppSessionService, public builder: FormBuilder, public loading: LoadingService, public modal: ModalController, public navParams: NavParams, public listing: ListingService, public vendor: VendorService, public authority: AuthorityService, public contract: ContractService, public offer: OfferService) {
    this.emailList = []
    this.form = this.builder.group({
      name:[''],
      entityEmail: [false],
    })
  }

  ngOnInit() {
    this.displayOther = this.navParams.data.displayOther;
    this.topic = this.navParams.data.topic;
  }

  dismiss = () => {
    this.modal.dismiss();
  }

  email = {

    change: (event, id, type) => {
      let find = this.emailList.find(e => e.id == id)
      if (!find) {
        this.emailList.push({ id: id, type: type })
      }
      if (!event.detail.checked) {
        this.emailList = this.emailList.filter(e => e.id != id)
      }
    },

    send: async () => {
      await this.loading.show('Sending Email')
      console.log(this.listing.form.detail.get('vendors').value)
      console.log(this.state)
      console.log(this.listing.form._id.value)
      console.log(this.form.get('entityEmail').value)
      let listingId = this.listing.form._id.controls._id.value


      if (this.state != 'custom') {
        switch(this.topic) {
          case 'S32':
            for (let email of this.emailList) {
              await this.contract.document.email(listingId, email.id)
              this.contract.form.detail.get('emailSent').patchValue(new Date())
            }
            break;
          case 'Authority':
            if (this.authority.form.detail.get('authorityAmend').value) {
              for (let email of this.emailList) { await this.authority.amendment.remoteSign(listingId, email.id) }
            } else if (this.authority.form.detail.get('locked').value) {
              await this.authority.document.email.resend(listingId, this.emailList)
            } else {
              for (let email of this.emailList) {
                await this.authority.document.signature.email(listingId, email.id)
              }
            }
            break;
          case 'OFFER':
            for (let email of this.emailList) {
              let offerId = this.offer.form.detail.controls._id.value
              await this.offer.document.email.remoteSign(listingId, offerId, email.id, email.type)
            }
            break;
        }
      } else {
        if (this.topic == 'S32') {
          await this.contract.document.emailAny(listingId, this.app.user, this.form.get('name').value, this.form.get('entityEmail').value)
        } else if (this.topic == 'Sold') {
          await this.contract.document.emailSoldContract(listingId, this.offer.form.acceptedOffer._id, this.app.user, this.form.get('name').value, this.form.get('entityEmail').value)
        }
      }

      await this.loading.hide()
      if (this.state != 'custom') {
        this.dismiss()
      } else {
        this.state = 'sent'
      }
    },
  }
}
